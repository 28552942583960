import React, { useEffect, useMemo, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import {
  useEvent,
  AttendeeFormValues,
  PaymentMethod,
  InsuranceFormValues,
  useLazyEventAttendees,
  useStateWithDebounce,
  useLazyEventAttendee,
  useListInsuranceCompanies,
} from '@modmd/data'
import { COLOR, fontSizes, pxToRem } from 'theme'
import Card from 'components/Card'
import { Spinner } from 'components/Spinner'
import { Text } from 'components/Typography'
import Button from 'components/Button'
import Icon from 'components/Icon'
import { Tabber } from 'components/Tabber'
import TextInput from 'components/TextInput'
import { Box } from 'components/Layout'
import { ROUTES } from 'event-portal/constants/routes'
import { useSearchState } from 'internal-portal/utils/useSearchState'
import { EventPageLayout } from 'event-portal/components/PageLayout/EventPageLayout'
import { EventSampleFields } from 'sharedComponents/EventSampleFields'
import Pagination from 'components/Pagination'
import { ConfirmAttendeeInsurance } from 'sharedComponents/ConfirmAttendeeInsurance'
import { Dialog } from 'components/Dialog'
import { isNull } from 'lodash'
import { TestingDatalist } from './TestingDatalist'
import { UserInfo } from '../RequestEventAttendee/UserInfo'
import { EventInfo } from '../RequestEventAttendee/EventInfo'
import { InsuranceInfo } from '../RequestEventAttendee/InsuranceInfo'

interface Params {
  id: string
}

enum TabberType {
  USER_CODE = 'userCode',
  NAME = 'name',
}

export enum UrlSearchQuery {
  EMAIL_OR_NAME_LIKE = 'emailOrNameLike',
  PAGE = 'page',
}

const PERPAGE = 10

const TestingPatient: React.VFC = () => {
  const params = useParams<Params>()
  const { data: eventData, loading, refetch } = useEvent({
    id: params.id || '',
    countAll: true,
  })
  const history = useHistory()
  const [selectedTab, setSelectedTab] = React.useState(TabberType.USER_CODE)
  const tabberTitle = () => (selectedTab === TabberType.USER_CODE ? 'User code' : 'Name')
  const [isDialogOpen, setIsDialogOpen] = React.useState(false)
  const [confirmInsuranceModalOpen, setConfirmInsuranceModalOpen] = useState(false)
  const [select, setOnSelect] = useState<string | undefined>('')
  const [
    getEventAttendees,
    { data: eventAttendeeData, loading: isLoadingEventAttendee, refetch: refetchAttendeesData },
  ] = useLazyEventAttendees({
    onCompleted: () => {},
  })

  const { data: insuranceCompanies } = useListInsuranceCompanies()

  const activeInsuranceCompanies = React.useMemo(
    () =>
      insuranceCompanies
        ? insuranceCompanies.listInsuranceCompanies
            .filter((insComp) => insComp.isApproved)
            .map((insComp) => insComp.name) ?? []
        : [],
    [insuranceCompanies]
  )

  const [
    getEventAttendee,
    {
      data: selectedEventAttendee,
      loading: isSelectedEventAttendeeLoading,
      refetch: refetchSelectedAttendeeData,
    },
  ] = useLazyEventAttendee()

  useEffect(() => {
    if (select) {
      getEventAttendee({
        variables: {
          id: select,
        },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [select])

  const searchState = useSearchState<{
    [UrlSearchQuery.EMAIL_OR_NAME_LIKE]?: string
    [UrlSearchQuery.PAGE]?: string
  }>()

  const [emailOrNameLike, setEmailOrNameLike, debouncedSearchQuery] = useStateWithDebounce(
    searchState.data.emailOrNameLike,
    (emailOrNameLike) => {
      searchState.setSearchState(
        { emailOrNameLike, page: emailOrNameLike ? 1 : searchState.data.page },
        false,
        true
      )
    },
    1000
  )

  const pagination = useMemo(() => eventAttendeeData?.eventAttendees?.pagination || null, [
    eventAttendeeData?.eventAttendees?.pagination,
  ])

  useEffect(() => {
    if (emailOrNameLike && emailOrNameLike?.length >= 3 && eventData?.event?.id) {
      getEventAttendees({
        variables: {
          id: eventData.event.id,
          filterData: {
            ...(debouncedSearchQuery && {
              emailOrNameLike: debouncedSearchQuery,
            }),
          },
          pagination: {
            from: searchState?.data?.page ? (Number(searchState?.data?.page) - 1) * PERPAGE : 0,
            length: PERPAGE,
          },
        },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchQuery, searchState?.data?.page])

  const handleAddSample = () => {
    if (
      eventData?.event?.paymentMethod === PaymentMethod.Insurance &&
      ((!selectedEventAttendee?.eventAttendee?.Payment?.[0]?.isApproved &&
        selectedEventAttendee?.eventAttendee?.Payment?.[0]?.eligibilityStatus !== 'Active') ||
        selectedEventAttendee?.eventAttendee?.Payment?.[0]?.eligibilityStatus === 'Inactive' ||
        isNull(selectedEventAttendee?.eventAttendee?.Payment?.[0]?.isApproved) ||
        (!selectedEventAttendee?.eventAttendee?.Payment?.[0]?.isApproved &&
          selectedEventAttendee?.eventAttendee?.Payment?.[0]?.govIdChecked &&
          selectedEventAttendee?.eventAttendee?.Payment?.[0]?.insuranceCardFrontChecked &&
          selectedEventAttendee?.eventAttendee?.Payment?.[0]?.insuranceCardBackChecked))
    ) {
      setConfirmInsuranceModalOpen(true)
    } else setIsDialogOpen(true)
  }

  const isInsuranceActive = useMemo(() => {
    if (eventData?.event?.paymentMethod !== PaymentMethod.Insurance) {
      return true
    }

    return activeInsuranceCompanies.includes(
      selectedEventAttendee?.eventAttendee?.Payment?.[0]?.insuranceCompany ?? ''
    )
  }, [selectedEventAttendee, activeInsuranceCompanies])

  return (
    <EventPageLayout>
      {loading ? (
        <Spinner color={COLOR.brand} />
      ) : (
        <Card>
          <Card.Title display="flex" justifyContent="center">
            <Box display="flex" flexDirection="column" alignItems="center">
              <Text fontSize={pxToRem(26)} fontWeight={700}>
                {eventData?.event?.name}
              </Text>
              <Text color={COLOR.grayDark}>Fast Test Tool</Text>
              <Text pt="2rem" fontSize="s" textAlign="left">
                {eventData?.event?.description}
              </Text>
            </Box>
          </Card.Title>
          <Card.Title
            leftContent={
              <>
                <Text>
                  {eventData?.event?.countSamples}/{eventData?.event?.countAttendee}{' '}
                </Text>
                <Text fontWeight={700}>Attendees Tested</Text>
              </>
            }
            rightContent={
              <Button
                appearance="link"
                leftIcon={<Icon.AddPerson size="L" />}
                onClick={() =>
                  history.push(`/${ROUTES.EVENT}/${params.id}/event-days`, {
                    from: ROUTES.TESTING,
                  })
                }
              >
                Add new patient
              </Button>
            }
          />
          <Card.Content display="flex" flexDirection="column" alignContent="center">
            {select ? (
              <Box width="100%">
                <Button
                  appearance="link"
                  leftIcon={<Icon.CornerUpLeft size="L" />}
                  onClick={() => setOnSelect(undefined)}
                >
                  Back
                </Button>
                <Box marginTop="1rem">
                  <Text fontSize={fontSizes.xxl}>{selectedEventAttendee?.eventAttendee?.id}</Text>
                </Box>
                <Box
                  display="flex"
                  marginTop="1rem"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Text fontSize={fontSizes.xl}>{eventData?.event?.testType}</Text>
                  {!selectedEventAttendee?.eventAttendee?.EventSamples?.length &&
                    isInsuranceActive && (
                      <Button size="small" onClick={handleAddSample}>
                        Add sample
                      </Button>
                    )}
                </Box>
                {selectedEventAttendee?.eventAttendee?.EventSamples?.length ? (
                  <Box background={COLOR.lightGreen} p="0.6rem">
                    <Icon.CheckmarkCircle size="XL" color={COLOR.green} mr="1rem" />
                    <Text>This user has been already tested, please enter the next user code</Text>
                  </Box>
                ) : null}
                {selectedEventAttendee?.eventAttendee ? (
                  <UserInfo
                    attendee={(selectedEventAttendee?.eventAttendee as AttendeeFormValues) || []}
                  />
                ) : null}
                {eventData?.event?.paymentMethod === PaymentMethod.Insurance && (
                  <InsuranceInfo
                    insurance={
                      ((selectedEventAttendee?.eventAttendee
                        ?.Payment?.[0] as unknown) as InsuranceFormValues) || undefined
                    }
                    showEditUserButton={false}
                  />
                )}
                <EventInfo
                  event={eventData?.event}
                  selectedEventDay={selectedEventAttendee?.eventAttendee?.timeSlot}
                />
              </Box>
            ) : (
              <Box
                display="flex"
                justifyContent="center"
                flexDirection="column"
                alignItems="center"
              >
                <Box display="flex" justifyContent="center">
                  <Tabber
                    activeTabName={selectedTab}
                    onChange={({ activeTabName }) => {
                      setSelectedTab(activeTabName as TabberType)
                    }}
                  >
                    <Tabber.Item tabName={TabberType.USER_CODE} component={<></>}>
                      User Code
                    </Tabber.Item>
                    <Tabber.Item tabName={TabberType.NAME} component={<></>}>
                      Name
                    </Tabber.Item>
                  </Tabber>
                </Box>
                <Box display="flex" justifyContent="center" alignItems="center" mt="1rem">
                  <TextInput
                    mr="1rem"
                    label={tabberTitle()}
                    leftIcon={<Icon.Search />}
                    placeholder="Search"
                    value={emailOrNameLike}
                    onChange={(event) => setEmailOrNameLike(event.target.value)}
                    errorMessage={
                      emailOrNameLike?.length && emailOrNameLike?.length < 3
                        ? 'Please enter at least 3 characters'
                        : undefined
                    }
                  />
                  <Button>Search {tabberTitle()}</Button>
                </Box>
                {(isLoadingEventAttendee || isSelectedEventAttendeeLoading) && (
                  <Spinner color={COLOR.brand} />
                )}
                {eventAttendeeData?.eventAttendees?.attendees?.length ? (
                  <TestingDatalist
                    isLoading={false}
                    attendees={eventAttendeeData?.eventAttendees?.attendees || []}
                    setOnSelect={setOnSelect}
                    columnLabels={{
                      insurance:
                        eventData?.event?.paymentMethod === PaymentMethod.Insurance
                          ? 'insurance'
                          : null,
                    }}
                  />
                ) : null}
                {pagination && pagination?.total > pagination?.length && (
                  <Pagination.Wrapper>
                    <Pagination
                      firstPage={1}
                      currentPage={Number(searchState.data.page ?? 1)}
                      lastPage={Math.ceil(pagination.total / pagination.length)}
                      onPageChange={(page) => {
                        searchState.setSearchState({ page }, false)
                      }}
                    />
                  </Pagination.Wrapper>
                )}
              </Box>
            )}
          </Card.Content>
          <Box display="flex" justifyContent="center" alignItems="center">
            {eventData?.event?.testType && selectedEventAttendee?.eventAttendee && (
              <EventSampleFields
                testType={eventData?.event?.testType}
                isOpen={isDialogOpen}
                onDismiss={() => setIsDialogOpen(false)}
                onFinish={async () => {
                  setIsDialogOpen(false)
                  if (refetchAttendeesData) await refetchAttendeesData()
                  await refetch()
                  setOnSelect(undefined)
                }}
                testValues={eventData?.event?.TestValues}
                attendeeId={selectedEventAttendee?.eventAttendee.id}
              />
            )}
          </Box>
          <Dialog
            maxWidth={pxToRem(1000)}
            isOpen={confirmInsuranceModalOpen}
            onDismiss={() => {
              setConfirmInsuranceModalOpen(false)
            }}
          >
            {selectedEventAttendee?.eventAttendee && selectedEventAttendee?.eventAttendee?.id && (
              <ConfirmAttendeeInsurance
                setConfirmInsuranceModal={setConfirmInsuranceModalOpen}
                attendee={selectedEventAttendee?.eventAttendee}
                runEligibilibyEnable
                refetch={refetchSelectedAttendeeData}
              />
            )}
          </Dialog>
        </Card>
      )}
    </EventPageLayout>
  )
}

export { TestingPatient }
