import { gql } from '@apollo/client'

export const GET_LOCATION_PRODUCTS = gql`
  query GetLocationProducts(
    $filterData: ProductsFilterInput
    $pagination: PaginationInput!
    $pricefilterData: PricesFilterInput
  ) {
    products(filterData: $filterData, pagination: $pagination) {
      products {
        id
        name
        type
        Prices(filterData: $pricefilterData) {
          id
          price
          minQuantity
          sublocation
        }
      }
    }
  }
`
