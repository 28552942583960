import { gql } from '@apollo/client'

export const EVENTS = gql`
  query Events($filterData: EventFilterInput!, $pagination: PaginationInput!) {
    events(filterData: $filterData, pagination: $pagination) {
      events {
        id
        name
        description
        slug
        testType
        TestValues {
          specimenType
          rapidLotNumber
          rapidCartridgeExpirationDate
          rapidDockId
          rapidSpecimenNumber
          rapidExpirationDate
        }
        Address {
          street
          city
          state
        }
        EventDays {
          id
          startTime
          timeRange
          amountOfTests
        }
        frequency
        daysArray
        finishedAt
        paymentMethod
        isActive
        price
        countAttendee
        companyId
        groupId
        Company {
          id
          name
        }
        Group {
          id
          name
        }
        Invoice {
          invoiceId
          invoiceDocNumber
          invoiceDate
          autoInvoice
        }
        lab
        countSamples
        sublocation
      }
      pagination {
        from
        length
        total
      }
    }
  }
`

export const EVENT = gql`
  query Event($id: ID!, $countAll: Boolean) {
    event(id: $id, countAll: $countAll) {
      id
      countAttendee
      name
      description
      slug
      testType
      TestValues {
        specimenType
        rapidLotNumber
        rapidCartridgeExpirationDate
        rapidDockId
        rapidSpecimenNumber
        rapidExpirationDate
      }
      addressId
      Address {
        street
        city
        state
        zip
        county
        complement
        country
      }
      EventDays {
        id
        startTime
        timeRange
        amountOfTests
        remainingTests
      }
      frequency
      daysArray
      finishedAt
      paymentMethod
      isActive
      price
      countSamples
      isAddressNotificated
      isDescriptionNotificated
      companyId
      groupId
      Company {
        id
        name
      }
      Group {
        id
        name
      }
      Invoice {
        invoiceId
        invoiceDocNumber
        invoiceDate
        autoInvoice
      }
      lab
      sublocation
    }
  }
`

export const EVENT_FOR_ATTENDEE = gql`
  query EventForAttendee($id: ID!, $countAll: Boolean) {
    event(id: $id, countAll: $countAll) {
      id
      name
      description
      testType
      countSamples
      countAttendee
      TestValues {
        specimenType
        rapidDockId
        rapidLotNumber
        rapidSpecimenNumber
        rapidExpirationDate
        rapidCartridgeExpirationDate
      }
      addressId
      Address {
        street
        city
        state
        zip
        county
        complement
        country
      }
      EventDays {
        id
        startTime
        timeRange
        amountOfTests
        remainingTests
      }
      frequency
      daysArray
      finishedAt
      paymentMethod
      isActive
      price
      isDescriptionNotificated
      isAddressNotificated
    }
  }
`

export const EVENT_BY_SLUG = gql`
  query EventBySlug($slug: String!) {
    eventBySlug(slug: $slug)
  }
`

export const EDIT_EVENT = gql`
  mutation EditEvent($inputData: EditEventInput!) {
    editEvent(inputData: $inputData) {
      id
      lab
      name
      description
      slug
      testType
      TestValues {
        specimenType
        rapidLotNumber
        rapidCartridgeExpirationDate
        rapidDockId
        rapidSpecimenNumber
        rapidExpirationDate
      }
      EventDays {
        id
        startTime
        timeRange
        amountOfTests
      }
      addressId
      Address {
        city
        country
        county
        complement
        state
        lat
        lon
        street
        zip
      }
      frequency
      daysArray
      finishedAt
      paymentMethod
      isActive
      price
      lab
    }
  }
`

export const ADD_EVENT = gql`
  mutation AddEvent($inputData: EventInput!) {
    addEvent(inputData: $inputData) {
      id
      lab
      name
      description
      slug
      testType
      TestValues {
        specimenType
        rapidLotNumber
        rapidCartridgeExpirationDate
        rapidDockId
        rapidSpecimenNumber
        rapidExpirationDate
      }
      EventDays {
        id
        startTime
        timeRange
        amountOfTests
      }
      addressId
      Address {
        city
        country
        county
        complement
        state
        lat
        lon
        street
        zip
      }
      frequency
      daysArray
      finishedAt
      paymentMethod
      isActive
      price
      lab
    }
  }
`

export const CLOSE_EVENT = gql`
  mutation CloseEvent($id: ID!) {
    closeEvent(id: $id)
  }
`

export const EDIT_EVENT_DAYS = gql`
  mutation EditEventDay($inputData: EventDayInput!) {
    editEventDay(inputData: $inputData)
  }
`

export const EDIT_EVENT_PAYMENT = gql`
  mutation EditEventPayment($inputData: EditEventPaymentInput!) {
    editEventPayment(inputData: $inputData)
  }
`

export const DELETE_EVENT = gql`
  mutation DeleteEvent($id: ID!) {
    deleteEvent(id: $id)
  }
`

export const DELETE_EVENT_DAY = gql`
  mutation DeleteEventDay($id: ID!) {
    deleteEventDay(id: $id)
  }
`

export const CREATE_EVENT_PAYMENT = gql`
  mutation EventPayment($inputData: EventPaymentInput!) {
    eventPayment(inputData: $inputData) {
      clientSecret
      eventPaymentId
    }
  }
`

export const CREATE_EVENT_INSURANCE_PAYMENT = gql`
  mutation PaymentInsurance($inputData: PaymentInput!) {
    addPaymentInsurance(inputData: $inputData) {
      id
    }
  }
`

export const EVENT_ATTENDEE_BY_TOKEN = gql`
  query EventAttendeeByToken($token: String!, $dateOfBirth: DateTime!) {
    eventAttendeeByToken(token: $token, dateOfBirth: $dateOfBirth) {
      id
      firstName
      lastName
      eventId
      timeSlot
      dateOfBirth
      gender
      ethnicity
      race
      email
      phone
      Address {
        street
        city
        state
        zip
        country
      }
      EventSamples {
        id
        barcode
        testedAt
        result
        secondResult
        specimenType
        resultProvisionedAt
        eventAttendeeId
        sampleStatus
        rapidLotNumber
        rapidCartridgeExpirationDate
        rapidDockId
        rapidSpecimenNumber
        rapidExpirationDate
        testedById
      }
      Event {
        name
        testType
        TestValues {
          specimenType
          rapidDockId
          rapidLotNumber
          rapidSpecimenNumber
          rapidExpirationDate
          rapidCartridgeExpirationDate
        }
        Address {
          street
          city
          state
          zip
          county
          complement
          country
        }
        EventDays {
          id
          startTime
          timeRange
        }
      }
    }
  }
`

export const EDIT_INSURANCE_PAYMENT = gql`
  mutation EditAttendeeInsurancePayment($inputData: InsuranceInput!) {
    editPaymentInsurance(inputData: $inputData)
  }
`

export const APPROVE_ATTENDEE_INSURANCE = gql`
  mutation ApproveAttendeeInsurance($inputData: ApprovePaymentInput!) {
    approveInsurancePayment(inputData: $inputData)
  }
`

export const DENY_ATTENDEE_INSURANCE = gql`
  mutation DenyAttendeeInsurance($inputData: DenyPaymentInput!) {
    denyInsurancePayment(inputData: $inputData)
  }
`
