import React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import styled from 'styled-components/macro'
import { useQuery } from '@apollo/client'
import {
  GetTest as GetTestType,
  TestResult,
  getFormattedDateAndTime,
  useAuth,
  SpecimenTypes,
  UserPermissions,
  TestType,
} from '@modmd/data'
import { DEVICE } from 'theme'
import { useIsMinDevice } from 'utils/hooks/useMedia'
import {
  dockLotLabel,
  tubeLotLabel,
  tubeDateLabel,
  cartridgeLabel,
  cartridgeDateLabel,
  isInfluenza,
} from 'utils/helpers'
import Label from 'components/Label'
import { DetailsList } from 'components/DetailsList'
import Card from 'components/Card'
import { Box } from 'components/Layout'
import { Text } from 'components/Typography'
import Button from 'components/Button'
import Datalist from 'components/Datalist'
import { ROUTES } from 'internal-portal/constants/routes'
import DashboardPage from 'internal-portal/sharedComponents/DashboardPage'
import { GET_TEST } from './operations'
import { ChangeTestResultDialog } from './ChangeTestResultDialog'
import { ProvisionTestResultDialog } from './ProvisionTestResultDialog'
import { ChangeDateDialog } from './ChangeDateDialog'
import { SpecimenType, SpecimenList } from './SpecimenType'

interface TestResultLineProps {
  result?: TestResult
  onChangeClick: () => void
  isChangeAllowed: boolean
}

interface TestDateLineProps {
  date?: string
  onChangeClick: () => void
  isChangeAllowed: boolean
}

interface TestSpecimenLineProps {
  specimenType?: SpecimenTypes | null
  onChangeClick: () => void
  isChangeAllowed: boolean
}

interface RouterProps {
  testId: string
}

type TestSessionDetailsProps = RouteComponentProps<RouterProps>

const HeaderTitle = styled.h2`
  margin: 0;
  line-height: 1;
`

const TestResultLine: React.VFC<TestResultLineProps> = ({
  result,
  onChangeClick,
  isChangeAllowed,
}) => (
  <Box display="flex" justifyContent="space-between" alignItems="flex-start">
    <Text>{result ?? 'No result yet.'}</Text>
    {isChangeAllowed && (
      <Button appearance="link" size="small" onClick={onChangeClick}>
        Change
      </Button>
    )}
  </Box>
)

const TestDateLine: React.VFC<TestDateLineProps> = ({ date, onChangeClick, isChangeAllowed }) => (
  <Box display="flex" justifyContent="space-between" alignItems="flex-start">
    <Text>{date ?? 'No date yet.'}</Text>
    {isChangeAllowed && (
      <Button appearance="link" size="small" onClick={onChangeClick}>
        Change
      </Button>
    )}
  </Box>
)

const TestSpecimenLine: React.VFC<TestSpecimenLineProps> = ({
  specimenType,
  onChangeClick,
  isChangeAllowed,
}) => (
  <Box display="flex" justifyContent="space-between" alignItems="flex-start">
    <Text>{SpecimenList[specimenType!] ?? 'No specimenType yet.'}</Text>
    {isChangeAllowed && (
      <Button appearance="link" size="small" onClick={onChangeClick}>
        Change
      </Button>
    )}
  </Box>
)

enum DialogType {
  DATE = 'date',
  SPECIMEN = 'specimen',
  RESULT = 'result',
  SECOND_RESULT = 'secondResult',
  NONE = '',
}

export const TestDetails: React.VFC<TestSessionDetailsProps> = ({ match, history }) => {
  const { data, hasPermission } = useAuth()

  const [dialogType, setDialogType] = React.useState(DialogType.NONE)
  const [isProvisionTestResultDialogOpen, setIsProvisionTestResultDialogOpen] = React.useState(
    false
  )
  const isDesktop = useIsMinDevice(DEVICE.DESKTOP)
  const { testId } = match.params
  const { data: testData, loading: isTestLoading } = useQuery<GetTestType>(GET_TEST, {
    variables: {
      id: testId,
    },
  })

  const {
    testedAt,
    barcode,
    result,
    secondResult,
    rapidDockId,
    rapidLotNumber,
    rapidSpecimenNumber,
    rapidExpirationDate,
    rapidCartridgeExpirationDate,
    resultReceivedAt,
    resultProvisionedAt,
    Members,
    TestSession,
    specimenType,
  } = testData?.test || {}

  const canAddTestInfo =
    !TestSession?.finishedAt &&
    (hasPermission([UserPermissions.ADD_TEST_RESULT_ANY_TEST_SESSION]) ||
      (hasPermission([UserPermissions.ADD_TEST_INFO_TEST_SESSION_STAFFED]) &&
        Members?.map(({ User, isStaff }) => {
          if (isStaff) return User.id
          return undefined
        }).includes(data.User.id)))

  const canChangeTestDate =
    canAddTestInfo ||
    (hasPermission([UserPermissions.EDIT_FINISHED_TEST_SESSION]) &&
      TestSession?.type === TestType.Covid19)

  const canAddOrChangeResult =
    !TestSession?.finishedAt &&
    hasPermission([UserPermissions.ADD_TEST_RESULT_ANY_TEST_SESSION]) &&
    Members?.map(({ User, isStaff }) => {
      if (isStaff) return User.id
      return undefined
    }).includes(data.User.id)

  const canReleaseTestResults = hasPermission([UserPermissions.RELEASE_TEST_RESULTS])

  return (
    <DashboardPage
      Header={
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          height="100%"
          p="1rem"
        >
          <HeaderTitle>Test details</HeaderTitle>
        </Box>
      }
    >
      <Box
        display="grid"
        gridTemplateColumns="repeat(auto-fit, minmax(300px, 1fr))"
        gridGap="1rem"
        p="1rem"
      >
        <Card display="grid" gridGap="1rem" alignContent="start">
          <Card.Title>Test details</Card.Title>
          <Card.Content>
            <DetailsList
              list={
                testData?.test
                  ? [
                      { label: 'Barcode', value: <Text>{barcode}</Text> },
                      {
                        label: 'Tested at',
                        value: (
                          <TestDateLine
                            isChangeAllowed={canChangeTestDate}
                            date={getFormattedDateAndTime(testedAt, true)}
                            onChangeClick={() => setDialogType(DialogType.DATE)}
                          />
                        ),
                      },
                      ...(result && resultReceivedAt
                        ? [
                            {
                              label: isInfluenza(TestSession?.type)
                                ? 'Influenza A Result'
                                : 'Result',
                              value: (
                                <TestResultLine
                                  isChangeAllowed={canAddOrChangeResult}
                                  result={result}
                                  onChangeClick={() => setDialogType(DialogType.RESULT)}
                                />
                              ),
                            },
                            isInfluenza(TestSession?.type) && secondResult
                              ? {
                                  label: 'Influenza B Result',
                                  value: (
                                    <TestResultLine
                                      isChangeAllowed={canAddOrChangeResult}
                                      result={secondResult}
                                      onChangeClick={() => setDialogType(DialogType.SECOND_RESULT)}
                                    />
                                  ),
                                }
                              : {
                                  label: undefined,
                                  value: undefined,
                                },
                            {
                              label: 'Received at',
                              value: <Text>{getFormattedDateAndTime(resultReceivedAt, true)}</Text>,
                            },
                          ]
                        : [
                            {
                              label: 'Result',
                              value: (
                                <TestResultLine
                                  isChangeAllowed={canAddOrChangeResult}
                                  onChangeClick={() => setDialogType(DialogType.RESULT)}
                                />
                              ),
                            },
                          ]),
                      ...(rapidDockId
                        ? [
                            {
                              label: dockLotLabel(TestSession?.type),
                              value: <Text>{rapidDockId}</Text>,
                            },
                          ]
                        : []),
                      ...(rapidLotNumber
                        ? [
                            {
                              label: tubeLotLabel(TestSession?.type),
                              value: <Text>{rapidLotNumber}</Text>,
                            },
                          ]
                        : []),
                      ...(rapidSpecimenNumber
                        ? [
                            {
                              label: cartridgeLabel(TestSession?.type),
                              value: <Text>{rapidSpecimenNumber}</Text>,
                            },
                          ]
                        : []),
                      ...(rapidExpirationDate
                        ? [
                            {
                              label: tubeDateLabel(TestSession?.type),
                              value: <Text>{getFormattedDateAndTime(rapidExpirationDate)}</Text>,
                            },
                          ]
                        : []),
                      ...(rapidCartridgeExpirationDate
                        ? [
                            {
                              label: cartridgeDateLabel(TestSession?.type),
                              value: (
                                <Text>{getFormattedDateAndTime(rapidCartridgeExpirationDate)}</Text>
                              ),
                            },
                          ]
                        : []),
                      ...(resultProvisionedAt
                        ? [
                            {
                              label: 'Provisioned at',
                              value: (
                                <Text>{getFormattedDateAndTime(resultProvisionedAt, true)}</Text>
                              ),
                            },
                          ]
                        : []),
                      {
                        label: 'Specimen Type',
                        value: (
                          <TestSpecimenLine
                            isChangeAllowed={canAddTestInfo}
                            specimenType={specimenType}
                            onChangeClick={() => setDialogType(DialogType.SPECIMEN)}
                          />
                        ),
                      },
                    ]
                  : []
              }
            />
          </Card.Content>
          {canReleaseTestResults && !resultProvisionedAt && result && (
            <Card.Actions>
              <Button
                mt="auto"
                width="100%"
                onClick={() => {
                  setIsProvisionTestResultDialogOpen(true)
                }}
              >
                Release test result
              </Button>
            </Card.Actions>
          )}
        </Card>
        <Card display="grid" gridGap="1rem" alignContent="start">
          <Card.Title
            rightContent={
              TestSession && (
                <Button
                  mt="auto"
                  appearance="link"
                  onClick={() => history.push(`/${ROUTES.SESSIONS}/${TestSession.id}`)}
                >
                  Open
                </Button>
              )
            }
          >
            Test session details
          </Card.Title>
          <Card.Content>
            <DetailsList
              list={
                TestSession
                  ? [
                      {
                        label: 'Location',
                        value: `${TestSession.Location.street}, ${TestSession.Location.city} ${TestSession.Location.zip}`,
                      },
                      ...(TestSession?.Groups
                        ? [
                            {
                              label: 'Group',
                              value: TestSession.Groups.map(({ name }) => name).join(', '),
                            },
                          ]
                        : []),
                      ...(TestSession?.Company
                        ? [
                            {
                              label: 'Company',
                              value: (
                                <Button
                                  appearance="link"
                                  onClick={() =>
                                    history.push(`/${ROUTES.COMPANIES}/${TestSession.Company!.id}`)
                                  }
                                >
                                  {TestSession.Company.name}
                                </Button>
                              ),
                            },
                          ]
                        : []),
                    ]
                  : []
              }
            />
          </Card.Content>
        </Card>
        <Box flex="3" minWidth="300px" display="grid" gridGap="1rem" alignContent="start">
          <Card>
            <Datalist
              isLoading={isTestLoading}
              skeletonProps={{
                columns: 3,
              }}
              data={Members || []}
              header={<Datalist.Title>Test members</Datalist.Title>}
              shouldRenderTableHeader={isDesktop}
              showActionIndicators
              renderTableHeader={() => (
                <Datalist.Row gridTemplateColumns="repeat(3, 1fr)">
                  <Datalist.HeaderCell label="Name" />
                  <Datalist.HeaderCell label="Birth date" />
                  <Datalist.HeaderCell label="Role" />
                </Datalist.Row>
              )}
              renderTableRow={({ User, isParticipant, isStaff }) => (
                <Datalist.Row
                  gridTemplateColumns="repeat(3, 1fr)"
                  onClick={() => history.push(`/${ROUTES.USERS}/${User.id}`)}
                >
                  <Datalist.Cell label="Name">
                    {User.firstName} {User.lastName}
                  </Datalist.Cell>
                  <Datalist.Cell label="Name">
                    {getFormattedDateAndTime(User.birthDate)}
                  </Datalist.Cell>
                  <Datalist.Cell label="Role">
                    {isParticipant && <Label indicator="green">Patient</Label>}
                    {isStaff && <Label indicator="yellow">Staff</Label>}
                  </Datalist.Cell>
                </Datalist.Row>
              )}
            />
          </Card>
        </Box>
      </Box>
      {testData?.test && (
        <>
          <ChangeTestResultDialog
            isOpen={dialogType === DialogType.RESULT}
            testId={testData.test.id}
            result={testData.test.result}
            onClose={() => setDialogType(DialogType.NONE)}
          />
          <ChangeTestResultDialog
            isOpen={dialogType === DialogType.SECOND_RESULT}
            testId={testData.test.id}
            result={testData.test.secondResult}
            onClose={() => setDialogType(DialogType.NONE)}
            secondResult={dialogType === DialogType.SECOND_RESULT}
          />
          <ProvisionTestResultDialog
            isOpen={isProvisionTestResultDialogOpen}
            testId={testData.test.id}
            result={testData.test.result}
            onClose={() => setIsProvisionTestResultDialogOpen(false)}
          />
          <ChangeDateDialog
            isOpen={dialogType === DialogType.DATE}
            testId={testData.test.id}
            result={testData.test.result}
            date={testData.test.testedAt}
            onClose={() => setDialogType(DialogType.NONE)}
          />
          <SpecimenType
            isOpen={dialogType === DialogType.SPECIMEN}
            testId={testData.test.id}
            specimenType={testData.test.specimenType}
            onClose={() => setDialogType(DialogType.NONE)}
          />
        </>
      )}
    </DashboardPage>
  )
}
