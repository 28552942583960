import { gql } from '@apollo/client'

export const GET_PRODUCTS = gql`
  query GetProducts($filterData: ProductsFilterInput, $pagination: PaginationInput!) {
    products(filterData: $filterData, pagination: $pagination) {
      products {
        id
        name
        type
        category
      }
    }
  }
`
