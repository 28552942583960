/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ActivatePasswordReset
// ====================================================

export interface ActivatePasswordReset {
  activatePasswordReset: boolean;
}

export interface ActivatePasswordResetVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AddClearanceStatus
// ====================================================

export interface AddClearanceStatus_addClearanceStatus {
  __typename: "UsersClearanceStatus";
  id: string;
}

export interface AddClearanceStatus {
  addClearanceStatus: AddClearanceStatus_addClearanceStatus | null;
}

export interface AddClearanceStatusVariables {
  inputData: ClearanceStatusInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AddPrice
// ====================================================

export interface AddPrice_addCompanyPrice {
  __typename: "CompanyPrice";
  id: string;
  productId: string;
  companyId: string;
  price: number;
  minQuantity: number;
}

export interface AddPrice {
  addCompanyPrice: AddPrice_addCompanyPrice | null;
}

export interface AddPriceVariables {
  inputData: CompanyPriceInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AddEventAttendee
// ====================================================

export interface AddEventAttendee_addEventAttendee_Address {
  __typename: "Address";
  street: string;
  city: string;
  state: string | null;
  zip: string;
  county: string | null;
  complement: string | null;
  country: string;
}

export interface AddEventAttendee_addEventAttendee {
  __typename: "EventAttendee";
  id: string;
  eventId: string;
  date: any | null;
  timeSlot: string;
  firstName: string;
  lastName: string;
  dateOfBirth: any;
  gender: Gender;
  ethnicity: Ethnicity;
  race: Race;
  email: string | null;
  phone: string | null;
  Address: AddEventAttendee_addEventAttendee_Address | null;
  userId: string | null;
  paymentId: string | null;
}

export interface AddEventAttendee {
  addEventAttendee: AddEventAttendee_addEventAttendee | null;
}

export interface AddEventAttendeeVariables {
  inputData: AddEventAttendeeInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AddPretestSurveyResponses
// ====================================================

export interface AddPretestSurveyResponses_addPretestSessionSurvey_Members_User {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
}

export interface AddPretestSurveyResponses_addPretestSessionSurvey_Members {
  __typename: "TestSessionMember";
  User: AddPretestSurveyResponses_addPretestSessionSurvey_Members_User;
}

export interface AddPretestSurveyResponses_addPretestSessionSurvey {
  __typename: "TestSession";
  id: string;
  Members: AddPretestSurveyResponses_addPretestSessionSurvey_Members[];
}

export interface AddPretestSurveyResponses {
  addPretestSessionSurvey: AddPretestSurveyResponses_addPretestSessionSurvey | null;
}

export interface AddPretestSurveyResponsesVariables {
  inputData: PretestSessionSurveyInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AddSubperson
// ====================================================

export interface AddSubperson_addSubperson {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
}

export interface AddSubperson {
  addSubperson: AddSubperson_addSubperson | null;
}

export interface AddSubpersonVariables {
  inputData: SubpersonInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AddTest
// ====================================================

export interface AddTest_addTest {
  __typename: "Test";
  id: string;
}

export interface AddTest {
  addTest: AddTest_addTest | null;
}

export interface AddTestVariables {
  inputData: TestInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AddTestSessionMembers
// ====================================================

export interface AddTestSessionMembers_addTestSessionMembers_Members_User {
  __typename: "User";
  id: string;
  email: string | null;
}

export interface AddTestSessionMembers_addTestSessionMembers_Members {
  __typename: "TestSessionMember";
  id: string;
  isParticipant: boolean;
  User: AddTestSessionMembers_addTestSessionMembers_Members_User;
}

export interface AddTestSessionMembers_addTestSessionMembers {
  __typename: "TestSession";
  Members: AddTestSessionMembers_addTestSessionMembers_Members[];
}

export interface AddTestSessionMembers {
  addTestSessionMembers: AddTestSessionMembers_addTestSessionMembers | null;
}

export interface AddTestSessionMembersVariables {
  id: string;
  inputData: TestSessionMembersInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AddTestSessionMembersFromGroup
// ====================================================

export interface AddTestSessionMembersFromGroup_addTestSessionMembersFromGroup_Members_User {
  __typename: "User";
  id: string;
  email: string | null;
}

export interface AddTestSessionMembersFromGroup_addTestSessionMembersFromGroup_Members {
  __typename: "TestSessionMember";
  id: string;
  isParticipant: boolean;
  User: AddTestSessionMembersFromGroup_addTestSessionMembersFromGroup_Members_User;
}

export interface AddTestSessionMembersFromGroup_addTestSessionMembersFromGroup {
  __typename: "TestSession";
  Members: AddTestSessionMembersFromGroup_addTestSessionMembersFromGroup_Members[];
}

export interface AddTestSessionMembersFromGroup {
  addTestSessionMembersFromGroup: AddTestSessionMembersFromGroup_addTestSessionMembersFromGroup | null;
}

export interface AddTestSessionMembersFromGroupVariables {
  id: string;
  inputData: TestSessionMembersFromGroupInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AddVaccination
// ====================================================

export interface AddVaccination_addVaccination {
  __typename: "Vaccination";
  id: string;
}

export interface AddVaccination {
  addVaccination: AddVaccination_addVaccination;
}

export interface AddVaccinationVariables {
  inputData: AddVaccinationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AddVaccinationRecord
// ====================================================

export interface AddVaccinationRecord_addVaccinationRecord {
  __typename: "VaccinationRecord";
  id: string;
}

export interface AddVaccinationRecord {
  addVaccinationRecord: AddVaccinationRecord_addVaccinationRecord;
}

export interface AddVaccinationRecordVariables {
  inputData: AddVaccinationRecordInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: TestCountAnalytics
// ====================================================

export interface TestCountAnalytics_testsCountAnalytics_races {
  __typename: "TestsRaces";
  AmericanIndian: number | null;
  Asian: number | null;
  Black: number | null;
  Hawaiian: number | null;
  White: number | null;
  Other: number | null;
  DeclineToState: number | null;
}

export interface TestCountAnalytics_testsCountAnalytics_genders {
  __typename: "TestsGenders";
  Male: number | null;
  Female: number | null;
  Other: number | null;
  DeclineToState: number | null;
}

export interface TestCountAnalytics_testsCountAnalytics_ethnicities {
  __typename: "TestsEthnicities";
  Hispanic: number | null;
  NotHispanic: number | null;
  DeclineToState: number | null;
}

export interface TestCountAnalytics_testsCountAnalytics_ageRange {
  __typename: "TestsAgeRange";
  under18: string | null;
  between18and27: string | null;
  between28and37: string | null;
  between38and57: string | null;
  over58: string | null;
}

export interface TestCountAnalytics_testsCountAnalytics {
  __typename: "TestsCountAnalytics";
  total: number | null;
  negatives: number | null;
  positives: number | null;
  inconclusive: number | null;
  unsatisfactory: number | null;
  invalid: number | null;
  pendings: number | null;
  rapids: number | null;
  antigen: number | null;
  rtpcr: number | null;
  strep: number | null;
  influenza: number | null;
  lucira: number | null;
  sofiaRsv: number | null;
  races: TestCountAnalytics_testsCountAnalytics_races | null;
  cities: any | null;
  genders: TestCountAnalytics_testsCountAnalytics_genders | null;
  ethnicities: TestCountAnalytics_testsCountAnalytics_ethnicities | null;
  ageRange: TestCountAnalytics_testsCountAnalytics_ageRange | null;
  positiveResultsChartLabel: (string | null)[] | null;
  positiveResultsChartValues: (number | null)[] | null;
  negativeResultsChartLabel: (string | null)[] | null;
  negativeResultsChartValues: (number | null)[] | null;
  selfSwabTotal: number | null;
}

export interface TestCountAnalytics {
  testsCountAnalytics: TestCountAnalytics_testsCountAnalytics;
}

export interface TestCountAnalyticsVariables {
  filterData: TestsCountAnalyticsInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: TestsResultsAnalytics
// ====================================================

export interface TestsResultsAnalytics_testsResultsAnalytics_tests_User {
  __typename: "AnalyticsUser";
  id: string;
  firstName: string | null;
  lastName: string | null;
  birthDate: any | null;
  email: string | null;
}

export interface TestsResultsAnalytics_testsResultsAnalytics_tests_TestSession_Company {
  __typename: "AnalyticsCompany";
  id: string | null;
  name: string | null;
}

export interface TestsResultsAnalytics_testsResultsAnalytics_tests_TestSession {
  __typename: "AnalyticsSession";
  id: string;
  date: any | null;
  name: string | null;
  lab: Lab | null;
  type: TestType | null;
  paymentStatus: TestSessionPaymentStatus | null;
  finishedAt: any | null;
  invoiceId: string | null;
  invoiceDocNumber: string | null;
  Company: TestsResultsAnalytics_testsResultsAnalytics_tests_TestSession_Company | null;
  zip: string | null;
}

export interface TestsResultsAnalytics_testsResultsAnalytics_tests_Group {
  __typename: "Group";
  name: string;
}

export interface TestsResultsAnalytics_testsResultsAnalytics_tests_Company {
  __typename: "Company";
  name: string;
}

export interface TestsResultsAnalytics_testsResultsAnalytics_tests_Insurance {
  __typename: "Insurance";
  id: string;
  insuranceCompany: string | null;
}

export interface TestsResultsAnalytics_testsResultsAnalytics_tests {
  __typename: "AnalyticsTest";
  id: string;
  barcode: string | null;
  testedAt: any;
  result: TestResult | null;
  secondResult: TestResult | null;
  resultProvisionedAt: any | null;
  specimenType: SpecimenTypes | null;
  User: TestsResultsAnalytics_testsResultsAnalytics_tests_User;
  TestSession: TestsResultsAnalytics_testsResultsAnalytics_tests_TestSession;
  Group: TestsResultsAnalytics_testsResultsAnalytics_tests_Group | null;
  Company: TestsResultsAnalytics_testsResultsAnalytics_tests_Company | null;
  eventId: string | null;
  eventpaymentMethod: EventPaymentMethod | null;
  Insurance: TestsResultsAnalytics_testsResultsAnalytics_tests_Insurance | null;
}

export interface TestsResultsAnalytics_testsResultsAnalytics_pagination {
  __typename: "Pagination";
  from: number;
  length: number;
  total: number;
}

export interface TestsResultsAnalytics_testsResultsAnalytics {
  __typename: "TestsResultsAnalytics";
  tests: TestsResultsAnalytics_testsResultsAnalytics_tests[];
  pagination: TestsResultsAnalytics_testsResultsAnalytics_pagination;
}

export interface TestsResultsAnalytics {
  testsResultsAnalytics: TestsResultsAnalytics_testsResultsAnalytics;
}

export interface TestsResultsAnalyticsVariables {
  filterData?: TestsResultsAnalyticsInput | null;
  pagination: PaginationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: InsuranceTestsResultsAnalytics
// ====================================================

export interface InsuranceTestsResultsAnalytics_insuranceTestsResultsAnalytics_tests_User {
  __typename: "AnalyticsUser";
  id: string;
  firstName: string | null;
  lastName: string | null;
  birthDate: any | null;
  email: string | null;
}

export interface InsuranceTestsResultsAnalytics_insuranceTestsResultsAnalytics_tests_TestSession_Company {
  __typename: "AnalyticsCompany";
  id: string | null;
  name: string | null;
}

export interface InsuranceTestsResultsAnalytics_insuranceTestsResultsAnalytics_tests_TestSession {
  __typename: "AnalyticsSession";
  id: string;
  date: any | null;
  name: string | null;
  lab: Lab | null;
  type: TestType | null;
  paymentStatus: TestSessionPaymentStatus | null;
  finishedAt: any | null;
  invoiceId: string | null;
  invoiceDocNumber: string | null;
  Company: InsuranceTestsResultsAnalytics_insuranceTestsResultsAnalytics_tests_TestSession_Company | null;
  zip: string | null;
}

export interface InsuranceTestsResultsAnalytics_insuranceTestsResultsAnalytics_tests_Group {
  __typename: "Group";
  name: string;
}

export interface InsuranceTestsResultsAnalytics_insuranceTestsResultsAnalytics_tests_Company {
  __typename: "Company";
  name: string;
}

export interface InsuranceTestsResultsAnalytics_insuranceTestsResultsAnalytics_tests_Insurance {
  __typename: "Insurance";
  id: string;
  insuranceCompany: string | null;
  eligibilityErrorMessage: string | null;
  eligibilityStatus: string | null;
}

export interface InsuranceTestsResultsAnalytics_insuranceTestsResultsAnalytics_tests {
  __typename: "InsuranceAnalyticsTest";
  id: string;
  barcode: string | null;
  testedAt: any;
  result: TestResult | null;
  secondResult: TestResult | null;
  resultProvisionedAt: any | null;
  specimenType: SpecimenTypes | null;
  insuranceStatus: string | null;
  insuranceStatusWhenAdded: string | null;
  insuranceUpdatedAt: any | null;
  User: InsuranceTestsResultsAnalytics_insuranceTestsResultsAnalytics_tests_User;
  TestSession: InsuranceTestsResultsAnalytics_insuranceTestsResultsAnalytics_tests_TestSession;
  Group: InsuranceTestsResultsAnalytics_insuranceTestsResultsAnalytics_tests_Group | null;
  Company: InsuranceTestsResultsAnalytics_insuranceTestsResultsAnalytics_tests_Company | null;
  eventId: string | null;
  eventpaymentMethod: EventPaymentMethod | null;
  Insurance: InsuranceTestsResultsAnalytics_insuranceTestsResultsAnalytics_tests_Insurance | null;
}

export interface InsuranceTestsResultsAnalytics_insuranceTestsResultsAnalytics_pagination {
  __typename: "Pagination";
  from: number;
  length: number;
  total: number;
}

export interface InsuranceTestsResultsAnalytics_insuranceTestsResultsAnalytics {
  __typename: "InsuranceTestsResultsAnalytics";
  tests: InsuranceTestsResultsAnalytics_insuranceTestsResultsAnalytics_tests[];
  pagination: InsuranceTestsResultsAnalytics_insuranceTestsResultsAnalytics_pagination;
}

export interface InsuranceTestsResultsAnalytics {
  insuranceTestsResultsAnalytics: InsuranceTestsResultsAnalytics_insuranceTestsResultsAnalytics;
}

export interface InsuranceTestsResultsAnalyticsVariables {
  filterData?: InsuranceTestsResultsAnalyticsInput | null;
  pagination: PaginationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AppUsageAnalytics
// ====================================================

export interface AppUsageAnalytics_appUsageAnalytics_surveys_SurveyResponses {
  __typename: "SurveyResponse";
  id: string;
  key: string;
  value: string;
}

export interface AppUsageAnalytics_appUsageAnalytics_surveys_User_Address {
  __typename: "Address";
  city: string;
  country: string;
  county: string | null;
  complement: string | null;
  state: string | null;
  lat: number | null;
  lon: number | null;
  street: string;
  zip: string;
}

export interface AppUsageAnalytics_appUsageAnalytics_surveys_User_Groups {
  __typename: "Group";
  id: string;
  name: string;
}

export interface AppUsageAnalytics_appUsageAnalytics_surveys_User_Companies {
  __typename: "Company";
  id: string;
  name: string;
}

export interface AppUsageAnalytics_appUsageAnalytics_surveys_User {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  birthDate: any | null;
  email: string | null;
  phoneNumber: string | null;
  gender: Gender | null;
  ethnicity: Ethnicity | null;
  race: Race | null;
  Address: AppUsageAnalytics_appUsageAnalytics_surveys_User_Address | null;
  Groups: AppUsageAnalytics_appUsageAnalytics_surveys_User_Groups[];
  Companies: AppUsageAnalytics_appUsageAnalytics_surveys_User_Companies[];
}

export interface AppUsageAnalytics_appUsageAnalytics_surveys {
  __typename: "Survey";
  id: string;
  createdAt: any;
  status: SurveyStatus | null;
  statusReason: string[] | null;
  SurveyResponses: AppUsageAnalytics_appUsageAnalytics_surveys_SurveyResponses[];
  User: AppUsageAnalytics_appUsageAnalytics_surveys_User;
}

export interface AppUsageAnalytics_appUsageAnalytics_pagination {
  __typename: "Pagination";
  from: number;
  length: number;
  total: number;
}

export interface AppUsageAnalytics_appUsageAnalytics {
  __typename: "AppUsageAnalytics";
  surveys: AppUsageAnalytics_appUsageAnalytics_surveys[];
  pagination: AppUsageAnalytics_appUsageAnalytics_pagination;
}

export interface AppUsageAnalytics {
  appUsageAnalytics: AppUsageAnalytics_appUsageAnalytics;
}

export interface AppUsageAnalyticsVariables {
  filterData?: AppUsageAnalyticsInput | null;
  pagination: PaginationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: TestsResultsAnalyticsClient
// ====================================================

export interface TestsResultsAnalyticsClient_testsResultsAnalyticsClient_tests_User {
  __typename: "AnalyticsUser";
  id: string;
  firstName: string | null;
  lastName: string | null;
  birthDate: any | null;
  email: string | null;
}

export interface TestsResultsAnalyticsClient_testsResultsAnalyticsClient_tests_TestSession_Company {
  __typename: "AnalyticsCompany";
  id: string | null;
}

export interface TestsResultsAnalyticsClient_testsResultsAnalyticsClient_tests_TestSession {
  __typename: "AnalyticsSession";
  date: any | null;
  name: string | null;
  lab: Lab | null;
  type: TestType | null;
  paymentStatus: TestSessionPaymentStatus | null;
  Company: TestsResultsAnalyticsClient_testsResultsAnalyticsClient_tests_TestSession_Company | null;
}

export interface TestsResultsAnalyticsClient_testsResultsAnalyticsClient_tests_Insurance {
  __typename: "Insurance";
  insuranceCompany: string | null;
}

export interface TestsResultsAnalyticsClient_testsResultsAnalyticsClient_tests {
  __typename: "AnalyticsTest";
  id: string;
  barcode: string | null;
  testedAt: any;
  result: TestResult | null;
  secondResult: TestResult | null;
  resultProvisionedAt: any | null;
  specimenType: SpecimenTypes | null;
  User: TestsResultsAnalyticsClient_testsResultsAnalyticsClient_tests_User;
  TestSession: TestsResultsAnalyticsClient_testsResultsAnalyticsClient_tests_TestSession;
  Insurance: TestsResultsAnalyticsClient_testsResultsAnalyticsClient_tests_Insurance | null;
  eventId: string | null;
  eventpaymentMethod: EventPaymentMethod | null;
}

export interface TestsResultsAnalyticsClient_testsResultsAnalyticsClient_pagination {
  __typename: "Pagination";
  from: number;
  length: number;
  total: number;
}

export interface TestsResultsAnalyticsClient_testsResultsAnalyticsClient {
  __typename: "TestsResultsAnalyticsClient";
  tests: TestsResultsAnalyticsClient_testsResultsAnalyticsClient_tests[];
  pagination: TestsResultsAnalyticsClient_testsResultsAnalyticsClient_pagination;
}

export interface TestsResultsAnalyticsClient {
  testsResultsAnalyticsClient: TestsResultsAnalyticsClient_testsResultsAnalyticsClient;
}

export interface TestsResultsAnalyticsClientVariables {
  filterData?: TestsResultsAnalyticsClientInput | null;
  pagination: PaginationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: MarketingAnalytics
// ====================================================

export interface MarketingAnalytics_marketingAnalytics_users_Address {
  __typename: "Address";
  city: string;
  state: string | null;
}

export interface MarketingAnalytics_marketingAnalytics_users {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  birthDate: any | null;
  email: string | null;
  gender: Gender | null;
  ethnicity: Ethnicity | null;
  race: Race | null;
  Address: MarketingAnalytics_marketingAnalytics_users_Address | null;
}

export interface MarketingAnalytics_marketingAnalytics_pagination {
  __typename: "Pagination";
  from: number;
  length: number;
  total: number;
}

export interface MarketingAnalytics_marketingAnalytics {
  __typename: "MarketingAnalytics";
  users: MarketingAnalytics_marketingAnalytics_users[];
  pagination: MarketingAnalytics_marketingAnalytics_pagination;
}

export interface MarketingAnalytics {
  marketingAnalytics: MarketingAnalytics_marketingAnalytics;
}

export interface MarketingAnalyticsVariables {
  filterData?: MarketingAnalyticsInput | null;
  pagination: PaginationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ApproveClearanceStatus
// ====================================================

export interface ApproveClearanceStatus_approveClearanceStatus {
  __typename: "UsersClearanceStatus";
  id: string;
}

export interface ApproveClearanceStatus {
  approveClearanceStatus: ApproveClearanceStatus_approveClearanceStatus | null;
}

export interface ApproveClearanceStatusVariables {
  inputData: ApproveClearanceStatusInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ApproveGroupClearanceStatus
// ====================================================

export interface ApproveGroupClearanceStatus {
  approveGroupClearanceStatus: boolean;
}

export interface ApproveGroupClearanceStatusVariables {
  inputData: ApproveGroupClearanceStatusInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ApproveVaccination
// ====================================================

export interface ApproveVaccination_approveVaccination {
  __typename: "Vaccination";
  id: string;
}

export interface ApproveVaccination {
  approveVaccination: ApproveVaccination_approveVaccination;
}

export interface ApproveVaccinationVariables {
  inputData: ApproveVaccinationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ApproveTestSession
// ====================================================

export interface ApproveTestSession_approveTestSession {
  __typename: "TestSession";
  id: string;
}

export interface ApproveTestSession {
  approveTestSession: ApproveTestSession_approveTestSession | null;
}

export interface ApproveTestSessionVariables {
  inputData: ApproveTestSessionInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetImage
// ====================================================

export interface GetImage {
  getImage: string;
}

export interface GetImageVariables {
  key: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AddInvoice
// ====================================================

export interface AddInvoice {
  addInvoice: string | null;
}

export interface AddInvoiceVariables {
  inputData: (string | null)[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AddEventInvoice
// ====================================================

export interface AddEventInvoice {
  addEventInvoice: string | null;
}

export interface AddEventInvoiceVariables {
  inputData: (string | null)[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: GenerateNewInvoice
// ====================================================

export interface GenerateNewInvoice {
  generateNewInvoice: string | null;
}

export interface GenerateNewInvoiceVariables {
  inputData: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: GenerateNewEventInvoice
// ====================================================

export interface GenerateNewEventInvoice {
  generateNewEventInvoice: string | null;
}

export interface GenerateNewEventInvoiceVariables {
  inputData: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ChangePassword
// ====================================================

export interface ChangePassword {
  changePassword: boolean;
}

export interface ChangePasswordVariables {
  inputData: ChangePasswordInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CheckOldPassword
// ====================================================

export interface CheckOldPassword {
  checkOldPassword: boolean;
}

export interface CheckOldPasswordVariables {
  inputData: CheckOldPasswordInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CheckPassword
// ====================================================

export interface CheckPassword {
  checkPassword: boolean;
}

export interface CheckPasswordVariables {
  inputData: ChangePasswordInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AddClearanceSetup
// ====================================================

export interface AddClearanceSetup_addClearanceSetup {
  __typename: "ClearanceSetup";
  id: string;
}

export interface AddClearanceSetup {
  addClearanceSetup: AddClearanceSetup_addClearanceSetup;
}

export interface AddClearanceSetupVariables {
  inputData: AddClearanceSetupInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ClearanceSetupByCompany
// ====================================================

export interface ClearanceSetupByCompany_getClearanceSetup {
  __typename: "ClearanceSetup";
  id: string;
  companyId: string;
  isActive: boolean;
  isQuestionnaireOn: boolean | null;
  isTravelRelevant: boolean | null;
  isExposureOn: boolean | null;
  isSymptomOn: boolean | null;
  isTemperatureOn: boolean | null;
  isVaccinationOn: boolean | null;
  isTestOn: boolean | null;
  amountOfDaysPositive: number | null;
  amountOfDaysNegative: number | null;
  amountOfDaysSinceLastPositive: number | null;
}

export interface ClearanceSetupByCompany {
  getClearanceSetup: ClearanceSetupByCompany_getClearanceSetup | null;
}

export interface ClearanceSetupByCompanyVariables {
  companyId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Companies
// ====================================================

export interface Companies_companies_companies_Groups {
  __typename: "Group";
  id: string;
  name: string;
}

export interface Companies_companies_companies_Members_Members_User {
  __typename: "User";
  id: string;
}

export interface Companies_companies_companies_Members_Members {
  __typename: "CompanyMember";
  User: Companies_companies_companies_Members_Members_User;
  role: CompanyMemberRole | null;
}

export interface Companies_companies_companies_Members {
  __typename: "CompanyMembersWithPagination";
  Members: Companies_companies_companies_Members_Members[];
}

export interface Companies_companies_companies {
  __typename: "Company";
  id: string;
  name: string;
  Groups: Companies_companies_companies_Groups[];
  Members: Companies_companies_companies_Members;
  eventClearance: boolean;
  pcrOnly: boolean;
  insuranceOnly: boolean;
  automaticallyApproved: boolean;
}

export interface Companies_companies {
  __typename: "CompaniesWithPagination";
  companies: Companies_companies_companies[];
}

export interface Companies {
  companies: Companies_companies;
}

export interface CompaniesVariables {
  filterData?: CompaniesFilterInput | null;
  pagination: PaginationInput;
  membersFilterData?: UsersFilterInput | null;
  membersPagination: PaginationInput;
  skipMembers: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CompanyMember
// ====================================================

export interface CompanyMember {
  companyMember: boolean;
}

export interface CompanyMemberVariables {
  userId: string;
  companyId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CompanyInvitations
// ====================================================

export interface CompanyInvitations_pendingCompanyInvites {
  __typename: "Company";
  id: string;
  name: string;
}

export interface CompanyInvitations {
  pendingCompanyInvites: CompanyInvitations_pendingCompanyInvites[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AcceptCompanyInvite
// ====================================================

export interface AcceptCompanyInvite {
  acceptCompanyInvite: boolean;
}

export interface AcceptCompanyInviteVariables {
  companyId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DenyCompanyInvite
// ====================================================

export interface DenyCompanyInvite {
  denyCompanyInvite: boolean;
}

export interface DenyCompanyInviteVariables {
  companyId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AddCompanyGroupMembers
// ====================================================

export interface AddCompanyGroupMembers_addCompanyGroupMembers {
  __typename: "User";
  id: string;
  email: string | null;
}

export interface AddCompanyGroupMembers {
  addCompanyGroupMembers: AddCompanyGroupMembers_addCompanyGroupMembers[];
}

export interface AddCompanyGroupMembersVariables {
  inputData: AddCompanyGroupMembersInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CompanyDetails
// ====================================================

export interface CompanyDetails_company_Address {
  __typename: "Address";
  id: string;
  street: string;
  city: string;
  country: string;
  county: string | null;
  complement: string | null;
  state: string | null;
  zip: string;
}

export interface CompanyDetails_company_Groups {
  __typename: "Group";
  id: string;
  name: string;
  japaneseGroup: boolean;
}

export interface CompanyDetails_company_Members_pagination {
  __typename: "Pagination";
  total: number;
}

export interface CompanyDetails_company_Members {
  __typename: "CompanyMembersWithPagination";
  pagination: CompanyDetails_company_Members_pagination;
}

export interface CompanyDetails_company {
  __typename: "Company";
  id: string;
  name: string;
  Address: CompanyDetails_company_Address;
  Groups: CompanyDetails_company_Groups[];
  Members: CompanyDetails_company_Members;
  eventClearance: boolean;
  insuranceOnly: boolean;
  pcrOnly: boolean;
  automaticallyApproved: boolean;
}

export interface CompanyDetails {
  company: CompanyDetails_company | null;
}

export interface CompanyDetailsVariables {
  id: string;
  skipGroups: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CompanyDetailsTestSessionsPagination
// ====================================================

export interface CompanyDetailsTestSessionsPagination_testSessions_pagination {
  __typename: "Pagination";
  total: number;
}

export interface CompanyDetailsTestSessionsPagination_testSessions {
  __typename: "TestSessionsWithPagination";
  pagination: CompanyDetailsTestSessionsPagination_testSessions_pagination;
}

export interface CompanyDetailsTestSessionsPagination {
  testSessions: CompanyDetailsTestSessionsPagination_testSessions;
}

export interface CompanyDetailsTestSessionsPaginationVariables {
  filterData?: TestSessionFilterInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CompanyMembers
// ====================================================

export interface CompanyMembers_users_users {
  __typename: "User";
  id: string;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  isCompliant: boolean;
}

export interface CompanyMembers_users_pagination {
  __typename: "Pagination";
  from: number;
  length: number;
  total: number;
}

export interface CompanyMembers_users {
  __typename: "UsersWithPagination";
  users: CompanyMembers_users_users[];
  pagination: CompanyMembers_users_pagination;
}

export interface CompanyMembers {
  users: CompanyMembers_users;
}

export interface CompanyMembersVariables {
  filterData?: UsersFilterInput | null;
  pagination: PaginationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: InviteCompanyMember
// ====================================================

export interface InviteCompanyMember_addCompanyMember {
  __typename: "Company";
  id: string;
  name: string;
}

export interface InviteCompanyMember {
  addCompanyMember: InviteCompanyMember_addCompanyMember | null;
}

export interface InviteCompanyMemberVariables {
  inputData: AddCompanyMemberInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RemoveCompanyMember
// ====================================================

export interface RemoveCompanyMember_removeCompanyMember {
  __typename: "Company";
  id: string;
  name: string;
}

export interface RemoveCompanyMember {
  removeCompanyMember: RemoveCompanyMember_removeCompanyMember | null;
}

export interface RemoveCompanyMemberVariables {
  inputData: RemoveCompanyMemberInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetCompanyMembers
// ====================================================

export interface GetCompanyMembers_company_Members_Members_User_ParentUser {
  __typename: "User";
  email: string | null;
}

export interface GetCompanyMembers_company_Members_Members_User {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  parentId: string | null;
  ParentUser: GetCompanyMembers_company_Members_Members_User_ParentUser | null;
}

export interface GetCompanyMembers_company_Members_Members {
  __typename: "CompanyMember";
  User: GetCompanyMembers_company_Members_Members_User;
  role: CompanyMemberRole | null;
}

export interface GetCompanyMembers_company_Members_pagination {
  __typename: "Pagination";
  from: number;
  length: number;
  total: number;
}

export interface GetCompanyMembers_company_Members {
  __typename: "CompanyMembersWithPagination";
  Members: GetCompanyMembers_company_Members_Members[];
  pagination: GetCompanyMembers_company_Members_pagination;
}

export interface GetCompanyMembers_company {
  __typename: "Company";
  Members: GetCompanyMembers_company_Members;
}

export interface GetCompanyMembers {
  company: GetCompanyMembers_company | null;
}

export interface GetCompanyMembersVariables {
  id: string;
  filterData?: UsersFilterInput | null;
  pagination: PaginationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetPrice
// ====================================================

export interface GetPrice_getCompanyPrice_Product {
  __typename: "Product";
  name: string;
  type: ProductTypes | null;
}

export interface GetPrice_getCompanyPrice_Group {
  __typename: "Group";
  name: string;
}

export interface GetPrice_getCompanyPrice {
  __typename: "CompanyPrice";
  id: string;
  productId: string;
  companyId: string;
  price: number;
  minQuantity: number;
  sublocation: string;
  Product: GetPrice_getCompanyPrice_Product | null;
  Group: GetPrice_getCompanyPrice_Group | null;
}

export interface GetPrice {
  getCompanyPrice: GetPrice_getCompanyPrice[];
}

export interface GetPriceVariables {
  inputData: GetCompanyPriceInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CustomerRequestTestSession
// ====================================================

export interface CustomerRequestTestSession {
  customerRequestTestSession: string;
}

export interface CustomerRequestTestSessionVariables {
  inputData: CustomerRequestTestSessionInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetDefaultPrice
// ====================================================

export interface GetDefaultPrice_getDefaultPrice_defaultPrices_Product {
  __typename: "Product";
  name: string;
}

export interface GetDefaultPrice_getDefaultPrice_defaultPrices_TestLocation {
  __typename: "TestLocation";
  id: string;
  name: string;
}

export interface GetDefaultPrice_getDefaultPrice_defaultPrices {
  __typename: "DefaultPrice";
  id: string;
  productId: string;
  locationId: string;
  price: number;
  minQuantity: number;
  sublocation: string;
  Product: GetDefaultPrice_getDefaultPrice_defaultPrices_Product | null;
  TestLocation: GetDefaultPrice_getDefaultPrice_defaultPrices_TestLocation | null;
}

export interface GetDefaultPrice_getDefaultPrice_pagination {
  __typename: "Pagination";
  from: number;
  length: number;
  total: number;
}

export interface GetDefaultPrice_getDefaultPrice {
  __typename: "DefaultPrices";
  defaultPrices: GetDefaultPrice_getDefaultPrice_defaultPrices[];
  pagination: GetDefaultPrice_getDefaultPrice_pagination;
}

export interface GetDefaultPrice {
  getDefaultPrice: GetDefaultPrice_getDefaultPrice | null;
}

export interface GetDefaultPriceVariables {
  inputData: GetDefaultPriceInput;
  pagination: PaginationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteDefaultPrice
// ====================================================

export interface DeleteDefaultPrice {
  deleteDefaultPrice: boolean | null;
}

export interface DeleteDefaultPriceVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AddDefaultPrice
// ====================================================

export interface AddDefaultPrice_addDefaultPrice {
  __typename: "DefaultPrice";
  id: string;
  productId: string;
  locationId: string;
  price: number;
  minQuantity: number;
}

export interface AddDefaultPrice {
  addDefaultPrice: AddDefaultPrice_addDefaultPrice | null;
}

export interface AddDefaultPriceVariables {
  inputData: DefaultPriceInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: EditDefaultPrice
// ====================================================

export interface EditDefaultPrice_editDefaultPrice {
  __typename: "DefaultPrice";
  id: string;
  productId: string;
  locationId: string;
  price: number;
  minQuantity: number;
}

export interface EditDefaultPrice {
  editDefaultPrice: EditDefaultPrice_editDefaultPrice | null;
}

export interface EditDefaultPriceVariables {
  inputData: EditDefaultPriceInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeletePrice
// ====================================================

export interface DeletePrice {
  deleteCompanyPrice: boolean | null;
}

export interface DeletePriceVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteUserPaymentMethod
// ====================================================

export interface DeleteUserPaymentMethod {
  deleteUserPaymentMethod: boolean;
}

export interface DeleteUserPaymentMethodVariables {
  stripeId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteTestSession
// ====================================================

export interface DeleteTestSession {
  deleteTestSession: boolean | null;
}

export interface DeleteTestSessionVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteUser
// ====================================================

export interface DeleteUser {
  deleteUser: boolean;
}

export interface DeleteUserVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteVaccinationRecord
// ====================================================

export interface DeleteVaccinationRecord {
  deleteVaccinationRecord: boolean;
}

export interface DeleteVaccinationRecordVariables {
  inputData: DeleteVaccinationRecordInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DenyClearanceStatus
// ====================================================

export interface DenyClearanceStatus_denyClearanceStatus {
  __typename: "UsersClearanceStatus";
  id: string;
}

export interface DenyClearanceStatus {
  denyClearanceStatus: DenyClearanceStatus_denyClearanceStatus | null;
}

export interface DenyClearanceStatusVariables {
  inputData: DenyClearanceStatusInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DenyGroupClearanceStatus
// ====================================================

export interface DenyGroupClearanceStatus {
  denyGroupClearanceStatus: boolean;
}

export interface DenyGroupClearanceStatusVariables {
  inputData: DenyGroupClearanceStatusInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DenyVaccination
// ====================================================

export interface DenyVaccination_denyVaccination {
  __typename: "Vaccination";
  id: string;
}

export interface DenyVaccination {
  denyVaccination: DenyVaccination_denyVaccination;
}

export interface DenyVaccinationVariables {
  inputData: DenyVaccinationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: EditCompanyMember
// ====================================================

export interface EditCompanyMember_editCompanyMember {
  __typename: "Company";
  id: string;
}

export interface EditCompanyMember {
  editCompanyMember: EditCompanyMember_editCompanyMember | null;
}

export interface EditCompanyMemberVariables {
  inputData: EditCompanyMemberInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: EditPrice
// ====================================================

export interface EditPrice_editCompanyPrice {
  __typename: "CompanyPrice";
  id: string;
  productId: string;
  price: number;
  companyId: string;
  minQuantity: number;
}

export interface EditPrice {
  editCompanyPrice: EditPrice_editCompanyPrice | null;
}

export interface EditPriceVariables {
  inputData: EditCompanyPriceInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: EditEventAttendee
// ====================================================

export interface EditEventAttendee_editEventAttendee {
  __typename: "EventAttendee";
  id: string;
}

export interface EditEventAttendee {
  editEventAttendee: EditEventAttendee_editEventAttendee | null;
}

export interface EditEventAttendeeVariables {
  inputData: EditEventAttendeeInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: EditEventAttendeeInsurance
// ====================================================

export interface EditEventAttendeeInsurance {
  editPaymentInsurance: boolean | null;
}

export interface EditEventAttendeeInsuranceVariables {
  inputData: InsuranceInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: EditGroup
// ====================================================

export interface EditGroup_editGroup {
  __typename: "Group";
  id: string;
}

export interface EditGroup {
  editGroup: EditGroup_editGroup | null;
}

export interface EditGroupVariables {
  inputData: EditGroupInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: EditGroupMember
// ====================================================

export interface EditGroupMember_editGroupMember {
  __typename: "Group";
  id: string;
}

export interface EditGroupMember {
  editGroupMember: EditGroupMember_editGroupMember;
}

export interface EditGroupMemberVariables {
  inputData: EditGroupMemberInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: EditQuestionnaireSurveys
// ====================================================

export interface EditQuestionnaireSurveys_editSurveyResponses {
  __typename: "Survey";
  id: string;
}

export interface EditQuestionnaireSurveys {
  editSurveyResponses: EditQuestionnaireSurveys_editSurveyResponses;
}

export interface EditQuestionnaireSurveysVariables {
  id: string;
  inputData: EditSurveyResponsesInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: EditResultEventSample
// ====================================================

export interface EditResultEventSample_editResultEventSample {
  __typename: "EventSample";
  id: string;
}

export interface EditResultEventSample {
  editResultEventSample: EditResultEventSample_editResultEventSample;
}

export interface EditResultEventSampleVariables {
  inputData: EditResultEventSampleInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: EditSurvey
// ====================================================

export interface EditSurvey_editSurvey {
  __typename: "Survey";
  id: string;
}

export interface EditSurvey {
  editSurvey: EditSurvey_editSurvey;
}

export interface EditSurveyVariables {
  id: string;
  inputData: EditSurveyInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: EditTestSession
// ====================================================

export interface EditTestSession_editTestSession {
  __typename: "TestSession";
  id: string;
}

export interface EditTestSession {
  editTestSession: EditTestSession_editTestSession | null;
}

export interface EditTestSessionVariables {
  inputData: TestSessionDetailsInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: EditVaccination
// ====================================================

export interface EditVaccination_editVaccination {
  __typename: "Vaccination";
  id: string;
}

export interface EditVaccination {
  editVaccination: EditVaccination_editVaccination;
}

export interface EditVaccinationVariables {
  inputData: EditVaccinationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: EditVaccinationRecord
// ====================================================

export interface EditVaccinationRecord_editVaccinationRecord {
  __typename: "VaccinationRecord";
  id: string;
}

export interface EditVaccinationRecord {
  editVaccinationRecord: EditVaccinationRecord_editVaccinationRecord;
}

export interface EditVaccinationRecordVariables {
  inputData: EditVaccinationRecordInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Events
// ====================================================

export interface Events_events_events_TestValues {
  __typename: "EventTestValues";
  specimenType: string | null;
  rapidLotNumber: string | null;
  rapidCartridgeExpirationDate: any | null;
  rapidDockId: string | null;
  rapidSpecimenNumber: string | null;
  rapidExpirationDate: any | null;
}

export interface Events_events_events_Address {
  __typename: "Address";
  street: string;
  city: string;
  state: string | null;
}

export interface Events_events_events_EventDays {
  __typename: "EventDay";
  id: string;
  startTime: any;
  timeRange: number;
  amountOfTests: number | null;
}

export interface Events_events_events_Company {
  __typename: "Company";
  id: string;
  name: string;
}

export interface Events_events_events_Group {
  __typename: "Group";
  id: string;
  name: string;
}

export interface Events_events_events_Invoice {
  __typename: "Invoice";
  invoiceId: string | null;
  invoiceDocNumber: string | null;
  invoiceDate: any | null;
  autoInvoice: boolean | null;
}

export interface Events_events_events {
  __typename: "Event";
  id: string;
  name: string;
  description: string | null;
  slug: string | null;
  testType: TestType;
  TestValues: Events_events_events_TestValues | null;
  Address: Events_events_events_Address | null;
  EventDays: Events_events_events_EventDays[] | null;
  frequency: EventFrequency | null;
  daysArray: WeekDays[] | null;
  finishedAt: any | null;
  paymentMethod: PaymentMethod | null;
  isActive: boolean;
  price: number | null;
  countAttendee: number | null;
  companyId: string | null;
  groupId: string | null;
  Company: Events_events_events_Company | null;
  Group: Events_events_events_Group | null;
  Invoice: Events_events_events_Invoice | null;
  lab: Lab | null;
  countSamples: number | null;
  sublocation: SubLocation;
}

export interface Events_events_pagination {
  __typename: "Pagination";
  from: number;
  length: number;
  total: number;
}

export interface Events_events {
  __typename: "Events";
  events: Events_events_events[] | null;
  pagination: Events_events_pagination;
}

export interface Events {
  events: Events_events | null;
}

export interface EventsVariables {
  filterData: EventFilterInput;
  pagination: PaginationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Event
// ====================================================

export interface Event_event_TestValues {
  __typename: "EventTestValues";
  specimenType: string | null;
  rapidLotNumber: string | null;
  rapidCartridgeExpirationDate: any | null;
  rapidDockId: string | null;
  rapidSpecimenNumber: string | null;
  rapidExpirationDate: any | null;
}

export interface Event_event_Address {
  __typename: "Address";
  street: string;
  city: string;
  state: string | null;
  zip: string;
  county: string | null;
  complement: string | null;
  country: string;
}

export interface Event_event_EventDays {
  __typename: "EventDay";
  id: string;
  startTime: any;
  timeRange: number;
  amountOfTests: number | null;
  remainingTests: number | null;
}

export interface Event_event_Company {
  __typename: "Company";
  id: string;
  name: string;
}

export interface Event_event_Group {
  __typename: "Group";
  id: string;
  name: string;
}

export interface Event_event_Invoice {
  __typename: "Invoice";
  invoiceId: string | null;
  invoiceDocNumber: string | null;
  invoiceDate: any | null;
  autoInvoice: boolean | null;
}

export interface Event_event {
  __typename: "Event";
  id: string;
  countAttendee: number | null;
  name: string;
  description: string | null;
  slug: string | null;
  testType: TestType;
  TestValues: Event_event_TestValues | null;
  addressId: string | null;
  Address: Event_event_Address | null;
  EventDays: Event_event_EventDays[] | null;
  frequency: EventFrequency | null;
  daysArray: WeekDays[] | null;
  finishedAt: any | null;
  paymentMethod: PaymentMethod | null;
  isActive: boolean;
  price: number | null;
  countSamples: number | null;
  isAddressNotificated: boolean;
  isDescriptionNotificated: boolean;
  companyId: string | null;
  groupId: string | null;
  Company: Event_event_Company | null;
  Group: Event_event_Group | null;
  Invoice: Event_event_Invoice | null;
  lab: Lab | null;
  sublocation: SubLocation;
}

export interface Event {
  event: Event_event | null;
}

export interface EventVariables {
  id: string;
  countAll?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: EventForAttendee
// ====================================================

export interface EventForAttendee_event_TestValues {
  __typename: "EventTestValues";
  specimenType: string | null;
  rapidDockId: string | null;
  rapidLotNumber: string | null;
  rapidSpecimenNumber: string | null;
  rapidExpirationDate: any | null;
  rapidCartridgeExpirationDate: any | null;
}

export interface EventForAttendee_event_Address {
  __typename: "Address";
  street: string;
  city: string;
  state: string | null;
  zip: string;
  county: string | null;
  complement: string | null;
  country: string;
}

export interface EventForAttendee_event_EventDays {
  __typename: "EventDay";
  id: string;
  startTime: any;
  timeRange: number;
  amountOfTests: number | null;
  remainingTests: number | null;
}

export interface EventForAttendee_event {
  __typename: "Event";
  id: string;
  name: string;
  description: string | null;
  testType: TestType;
  countSamples: number | null;
  countAttendee: number | null;
  TestValues: EventForAttendee_event_TestValues | null;
  addressId: string | null;
  Address: EventForAttendee_event_Address | null;
  EventDays: EventForAttendee_event_EventDays[] | null;
  frequency: EventFrequency | null;
  daysArray: WeekDays[] | null;
  finishedAt: any | null;
  paymentMethod: PaymentMethod | null;
  isActive: boolean;
  price: number | null;
  isDescriptionNotificated: boolean;
  isAddressNotificated: boolean;
}

export interface EventForAttendee {
  event: EventForAttendee_event | null;
}

export interface EventForAttendeeVariables {
  id: string;
  countAll?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: EventBySlug
// ====================================================

export interface EventBySlug {
  eventBySlug: string | null;
}

export interface EventBySlugVariables {
  slug: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: EditEvent
// ====================================================

export interface EditEvent_editEvent_TestValues {
  __typename: "EventTestValues";
  specimenType: string | null;
  rapidLotNumber: string | null;
  rapidCartridgeExpirationDate: any | null;
  rapidDockId: string | null;
  rapidSpecimenNumber: string | null;
  rapidExpirationDate: any | null;
}

export interface EditEvent_editEvent_EventDays {
  __typename: "EventDay";
  id: string;
  startTime: any;
  timeRange: number;
  amountOfTests: number | null;
}

export interface EditEvent_editEvent_Address {
  __typename: "Address";
  city: string;
  country: string;
  county: string | null;
  complement: string | null;
  state: string | null;
  lat: number | null;
  lon: number | null;
  street: string;
  zip: string;
}

export interface EditEvent_editEvent {
  __typename: "Event";
  id: string;
  lab: Lab | null;
  name: string;
  description: string | null;
  slug: string | null;
  testType: TestType;
  TestValues: EditEvent_editEvent_TestValues | null;
  EventDays: EditEvent_editEvent_EventDays[] | null;
  addressId: string | null;
  Address: EditEvent_editEvent_Address | null;
  frequency: EventFrequency | null;
  daysArray: WeekDays[] | null;
  finishedAt: any | null;
  paymentMethod: PaymentMethod | null;
  isActive: boolean;
  price: number | null;
}

export interface EditEvent {
  editEvent: EditEvent_editEvent | null;
}

export interface EditEventVariables {
  inputData: EditEventInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AddEvent
// ====================================================

export interface AddEvent_addEvent_TestValues {
  __typename: "EventTestValues";
  specimenType: string | null;
  rapidLotNumber: string | null;
  rapidCartridgeExpirationDate: any | null;
  rapidDockId: string | null;
  rapidSpecimenNumber: string | null;
  rapidExpirationDate: any | null;
}

export interface AddEvent_addEvent_EventDays {
  __typename: "EventDay";
  id: string;
  startTime: any;
  timeRange: number;
  amountOfTests: number | null;
}

export interface AddEvent_addEvent_Address {
  __typename: "Address";
  city: string;
  country: string;
  county: string | null;
  complement: string | null;
  state: string | null;
  lat: number | null;
  lon: number | null;
  street: string;
  zip: string;
}

export interface AddEvent_addEvent {
  __typename: "Event";
  id: string;
  lab: Lab | null;
  name: string;
  description: string | null;
  slug: string | null;
  testType: TestType;
  TestValues: AddEvent_addEvent_TestValues | null;
  EventDays: AddEvent_addEvent_EventDays[] | null;
  addressId: string | null;
  Address: AddEvent_addEvent_Address | null;
  frequency: EventFrequency | null;
  daysArray: WeekDays[] | null;
  finishedAt: any | null;
  paymentMethod: PaymentMethod | null;
  isActive: boolean;
  price: number | null;
}

export interface AddEvent {
  addEvent: AddEvent_addEvent | null;
}

export interface AddEventVariables {
  inputData: EventInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CloseEvent
// ====================================================

export interface CloseEvent {
  closeEvent: boolean | null;
}

export interface CloseEventVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: EditEventDay
// ====================================================

export interface EditEventDay {
  editEventDay: boolean;
}

export interface EditEventDayVariables {
  inputData: EventDayInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: EditEventPayment
// ====================================================

export interface EditEventPayment {
  editEventPayment: boolean;
}

export interface EditEventPaymentVariables {
  inputData: EditEventPaymentInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteEvent
// ====================================================

export interface DeleteEvent {
  deleteEvent: boolean;
}

export interface DeleteEventVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteEventDay
// ====================================================

export interface DeleteEventDay {
  deleteEventDay: boolean;
}

export interface DeleteEventDayVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: EventPayment
// ====================================================

export interface EventPayment_eventPayment {
  __typename: "EventPayment";
  clientSecret: string;
  eventPaymentId: string;
}

export interface EventPayment {
  eventPayment: EventPayment_eventPayment;
}

export interface EventPaymentVariables {
  inputData: EventPaymentInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: PaymentInsurance
// ====================================================

export interface PaymentInsurance_addPaymentInsurance {
  __typename: "Payment";
  id: string;
}

export interface PaymentInsurance {
  addPaymentInsurance: PaymentInsurance_addPaymentInsurance | null;
}

export interface PaymentInsuranceVariables {
  inputData: PaymentInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: EventAttendeeByToken
// ====================================================

export interface EventAttendeeByToken_eventAttendeeByToken_Address {
  __typename: "Address";
  street: string;
  city: string;
  state: string | null;
  zip: string;
  country: string;
}

export interface EventAttendeeByToken_eventAttendeeByToken_EventSamples {
  __typename: "EventSample";
  id: string;
  barcode: string | null;
  testedAt: any | null;
  result: TestResult | null;
  secondResult: TestResult | null;
  specimenType: string | null;
  resultProvisionedAt: any | null;
  eventAttendeeId: string;
  sampleStatus: SampleStatus | null;
  rapidLotNumber: string | null;
  rapidCartridgeExpirationDate: any | null;
  rapidDockId: string | null;
  rapidSpecimenNumber: string | null;
  rapidExpirationDate: any | null;
  testedById: string;
}

export interface EventAttendeeByToken_eventAttendeeByToken_Event_TestValues {
  __typename: "EventTestValues";
  specimenType: string | null;
  rapidDockId: string | null;
  rapidLotNumber: string | null;
  rapidSpecimenNumber: string | null;
  rapidExpirationDate: any | null;
  rapidCartridgeExpirationDate: any | null;
}

export interface EventAttendeeByToken_eventAttendeeByToken_Event_Address {
  __typename: "Address";
  street: string;
  city: string;
  state: string | null;
  zip: string;
  county: string | null;
  complement: string | null;
  country: string;
}

export interface EventAttendeeByToken_eventAttendeeByToken_Event_EventDays {
  __typename: "EventDay";
  id: string;
  startTime: any;
  timeRange: number;
}

export interface EventAttendeeByToken_eventAttendeeByToken_Event {
  __typename: "Event";
  name: string;
  testType: TestType;
  TestValues: EventAttendeeByToken_eventAttendeeByToken_Event_TestValues | null;
  Address: EventAttendeeByToken_eventAttendeeByToken_Event_Address | null;
  EventDays: EventAttendeeByToken_eventAttendeeByToken_Event_EventDays[] | null;
}

export interface EventAttendeeByToken_eventAttendeeByToken {
  __typename: "EventAttendee";
  id: string;
  firstName: string;
  lastName: string;
  eventId: string;
  timeSlot: string;
  dateOfBirth: any;
  gender: Gender;
  ethnicity: Ethnicity;
  race: Race;
  email: string | null;
  phone: string | null;
  Address: EventAttendeeByToken_eventAttendeeByToken_Address | null;
  EventSamples: (EventAttendeeByToken_eventAttendeeByToken_EventSamples | null)[] | null;
  Event: EventAttendeeByToken_eventAttendeeByToken_Event;
}

export interface EventAttendeeByToken {
  eventAttendeeByToken: EventAttendeeByToken_eventAttendeeByToken | null;
}

export interface EventAttendeeByTokenVariables {
  token: string;
  dateOfBirth: any;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: EditAttendeeInsurancePayment
// ====================================================

export interface EditAttendeeInsurancePayment {
  editPaymentInsurance: boolean | null;
}

export interface EditAttendeeInsurancePaymentVariables {
  inputData: InsuranceInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ApproveAttendeeInsurance
// ====================================================

export interface ApproveAttendeeInsurance {
  approveInsurancePayment: boolean | null;
}

export interface ApproveAttendeeInsuranceVariables {
  inputData: ApprovePaymentInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DenyAttendeeInsurance
// ====================================================

export interface DenyAttendeeInsurance {
  denyInsurancePayment: boolean | null;
}

export interface DenyAttendeeInsuranceVariables {
  inputData: DenyPaymentInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: EventAttendees
// ====================================================

export interface EventAttendees_eventAttendees_attendees_Address {
  __typename: "Address";
  id: string;
  street: string;
  city: string;
  zip: string;
  county: string | null;
  complement: string | null;
  state: string | null;
  country: string;
  lat: number | null;
  lon: number | null;
}

export interface EventAttendees_eventAttendees_attendees_Payment {
  __typename: "Payment";
  id: string;
  stripePaymentId: string | null;
  lastPaymentAttemptedAt: string | null;
  price: number | null;
  paymentError: string | null;
  eventId: string;
  govId: string | null;
  govIdChecked: boolean | null;
  govIdMessageDenied: string | null;
  insuranceCardBack: string | null;
  insuranceCardBackChecked: boolean | null;
  insuranceCardBackMessageDenied: string | null;
  insuranceCardFront: string | null;
  insuranceCardFrontChecked: boolean | null;
  insuranceCardFrontMessageDenied: string | null;
  insuranceCompany: string | null;
  plan: string | null;
  cardID: string | null;
  groupNumber: string | null;
  subscriberFirstName: string | null;
  subscriberMiddleName: string | null;
  subscriberLastName: string | null;
  subscriberDOB: any | null;
  subscriberGender: Gender | null;
  relationToSubscriber: string | null;
  SSN: string | null;
  driversLicense: string | null;
  eligibilityStatus: string | null;
  eligibilityErrorMessage: string | null;
  manualEligibilityCheckUserId: string | null;
  lastEligibilityCheck: any | null;
  isApproved: boolean | null;
}

export interface EventAttendees_eventAttendees_attendees_EventSamples {
  __typename: "EventSample";
  id: string;
  eventAttendeeId: string;
  barcode: string | null;
  result: TestResult | null;
  secondResult: TestResult | null;
  testedAt: any | null;
  resultProvisionedAt: any | null;
  sampleStatus: SampleStatus | null;
  rapidLotNumber: string | null;
  rapidCartridgeExpirationDate: any | null;
  rapidDockId: string | null;
  rapidSpecimenNumber: string | null;
  rapidExpirationDate: any | null;
  specimenType: string | null;
  testedById: string;
  createdAt: any;
}

export interface EventAttendees_eventAttendees_attendees {
  __typename: "EventAttendee";
  id: string;
  eventId: string;
  timeSlot: string;
  date: any | null;
  firstName: string;
  lastName: string;
  dateOfBirth: any;
  gender: Gender;
  ethnicity: Ethnicity;
  race: Race;
  email: string | null;
  phone: string | null;
  userId: string | null;
  paymentId: string | null;
  Address: EventAttendees_eventAttendees_attendees_Address | null;
  Payment: (EventAttendees_eventAttendees_attendees_Payment | null)[] | null;
  EventSamples: (EventAttendees_eventAttendees_attendees_EventSamples | null)[] | null;
}

export interface EventAttendees_eventAttendees_pagination {
  __typename: "Pagination";
  from: number;
  length: number;
  total: number;
}

export interface EventAttendees_eventAttendees {
  __typename: "EventAttendees";
  attendees: EventAttendees_eventAttendees_attendees[] | null;
  pagination: EventAttendees_eventAttendees_pagination;
}

export interface EventAttendees {
  eventAttendees: EventAttendees_eventAttendees;
}

export interface EventAttendeesVariables {
  id: string;
  filterData?: EventAttendeesInput | null;
  pagination: PaginationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AllEventAttendees
// ====================================================

export interface AllEventAttendees_allEventAttendees_attendees_Event {
  __typename: "Event";
  id: string;
  name: string;
  paymentMethod: PaymentMethod | null;
  testType: TestType;
  companyId: string | null;
  invoiceId: string | null;
}

export interface AllEventAttendees_allEventAttendees_attendees_Payment {
  __typename: "Payment";
  insuranceCompany: string | null;
  cardID: string | null;
}

export interface AllEventAttendees_allEventAttendees_attendees_EventSamples_TestedBy {
  __typename: "User";
  firstName: string | null;
  lastName: string | null;
}

export interface AllEventAttendees_allEventAttendees_attendees_EventSamples {
  __typename: "EventSample";
  id: string;
  result: TestResult | null;
  secondResult: TestResult | null;
  testedAt: any | null;
  resultProvisionedAt: any | null;
  testedById: string;
  TestedBy: AllEventAttendees_allEventAttendees_attendees_EventSamples_TestedBy | null;
  sampleStatus: SampleStatus | null;
}

export interface AllEventAttendees_allEventAttendees_attendees {
  __typename: "EventAttendee";
  Event: AllEventAttendees_allEventAttendees_attendees_Event;
  firstName: string;
  lastName: string;
  dateOfBirth: any;
  email: string | null;
  date: any | null;
  city: string | null;
  state: string | null;
  Payment: (AllEventAttendees_allEventAttendees_attendees_Payment | null)[] | null;
  EventSamples: (AllEventAttendees_allEventAttendees_attendees_EventSamples | null)[] | null;
}

export interface AllEventAttendees_allEventAttendees_pagination {
  __typename: "Pagination";
  from: number;
  length: number;
  total: number;
}

export interface AllEventAttendees_allEventAttendees {
  __typename: "EventAttendees";
  attendees: AllEventAttendees_allEventAttendees_attendees[] | null;
  pagination: AllEventAttendees_allEventAttendees_pagination;
}

export interface AllEventAttendees {
  allEventAttendees: AllEventAttendees_allEventAttendees;
}

export interface AllEventAttendeesVariables {
  filterData?: AllEventAttendeesInput | null;
  pagination: PaginationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: EventAttendee
// ====================================================

export interface EventAttendee_eventAttendee_Event {
  __typename: "Event";
  paymentMethod: PaymentMethod | null;
  testType: TestType;
}

export interface EventAttendee_eventAttendee_Address {
  __typename: "Address";
  id: string;
  street: string;
  city: string;
  zip: string;
  county: string | null;
  complement: string | null;
  state: string | null;
  country: string;
  lat: number | null;
  lon: number | null;
}

export interface EventAttendee_eventAttendee_Payment {
  __typename: "Payment";
  id: string;
  stripePaymentId: string | null;
  lastPaymentAttemptedAt: string | null;
  price: number | null;
  paymentError: string | null;
  eventId: string;
  govId: string | null;
  govIdChecked: boolean | null;
  govIdMessageDenied: string | null;
  insuranceCardBack: string | null;
  insuranceCardBackChecked: boolean | null;
  insuranceCardBackMessageDenied: string | null;
  insuranceCardFront: string | null;
  insuranceCardFrontChecked: boolean | null;
  insuranceCardFrontMessageDenied: string | null;
  insuranceCompany: string | null;
  plan: string | null;
  cardID: string | null;
  groupNumber: string | null;
  subscriberFirstName: string | null;
  subscriberMiddleName: string | null;
  subscriberLastName: string | null;
  subscriberDOB: any | null;
  subscriberGender: Gender | null;
  relationToSubscriber: string | null;
  SSN: string | null;
  driversLicense: string | null;
  eligibilityStatus: string | null;
  eligibilityErrorMessage: string | null;
  manualEligibilityCheckUserId: string | null;
  lastEligibilityCheck: any | null;
  isApproved: boolean | null;
}

export interface EventAttendee_eventAttendee_EventSamples {
  __typename: "EventSample";
  id: string;
  eventAttendeeId: string;
  barcode: string | null;
  result: TestResult | null;
  secondResult: TestResult | null;
  testedAt: any | null;
  resultProvisionedAt: any | null;
  sampleStatus: SampleStatus | null;
  rapidLotNumber: string | null;
  rapidCartridgeExpirationDate: any | null;
  rapidDockId: string | null;
  rapidSpecimenNumber: string | null;
  rapidExpirationDate: any | null;
  specimenType: string | null;
  testedById: string;
  createdAt: any;
}

export interface EventAttendee_eventAttendee {
  __typename: "EventAttendee";
  id: string;
  eventId: string;
  timeSlot: string;
  date: any | null;
  firstName: string;
  lastName: string;
  dateOfBirth: any;
  gender: Gender;
  ethnicity: Ethnicity;
  race: Race;
  email: string | null;
  phone: string | null;
  userId: string | null;
  paymentId: string | null;
  Event: EventAttendee_eventAttendee_Event;
  Address: EventAttendee_eventAttendee_Address | null;
  Payment: (EventAttendee_eventAttendee_Payment | null)[] | null;
  EventSamples: (EventAttendee_eventAttendee_EventSamples | null)[] | null;
}

export interface EventAttendee {
  eventAttendee: EventAttendee_eventAttendee | null;
}

export interface EventAttendeeVariables {
  id: string;
  companyId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: EventSamples
// ====================================================

export interface EventSamples_eventSamples_samples_EventAttendee_Event {
  __typename: "Event";
  id: string;
  name: string;
  testType: TestType;
  paymentMethod: PaymentMethod | null;
}

export interface EventSamples_eventSamples_samples_EventAttendee_Payment {
  __typename: "Payment";
  insuranceCompany: string | null;
  cardID: string | null;
}

export interface EventSamples_eventSamples_samples_EventAttendee {
  __typename: "EventAttendee";
  firstName: string;
  lastName: string;
  dateOfBirth: any;
  email: string | null;
  date: any | null;
  Event: EventSamples_eventSamples_samples_EventAttendee_Event;
  Payment: (EventSamples_eventSamples_samples_EventAttendee_Payment | null)[] | null;
}

export interface EventSamples_eventSamples_samples_TestedBy {
  __typename: "User";
  firstName: string | null;
  lastName: string | null;
}

export interface EventSamples_eventSamples_samples {
  __typename: "EventSample";
  id: string;
  result: TestResult | null;
  testedAt: any | null;
  resultProvisionedAt: any | null;
  specimenType: string | null;
  sampleStatus: SampleStatus | null;
  EventAttendee: EventSamples_eventSamples_samples_EventAttendee;
  TestedBy: EventSamples_eventSamples_samples_TestedBy | null;
}

export interface EventSamples_eventSamples_pagination {
  __typename: "Pagination";
  from: number;
  length: number;
  total: number;
}

export interface EventSamples_eventSamples {
  __typename: "EventSamples";
  samples: EventSamples_eventSamples_samples[] | null;
  pagination: EventSamples_eventSamples_pagination;
}

export interface EventSamples {
  eventSamples: EventSamples_eventSamples;
}

export interface EventSamplesVariables {
  filterData?: EventSamplesInput | null;
  pagination: PaginationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AddEventSample
// ====================================================

export interface AddEventSample_addEventSample {
  __typename: "EventSample";
  id: string;
  eventAttendeeId: string;
  sampleStatus: SampleStatus | null;
}

export interface AddEventSample {
  addEventSample: AddEventSample_addEventSample | null;
}

export interface AddEventSampleVariables {
  inputData: EventSampleInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ExportTestSession
// ====================================================

export interface ExportTestSession_exportTestSession {
  __typename: "TestSession";
  id: string;
}

export interface ExportTestSession {
  exportTestSession: ExportTestSession_exportTestSession | null;
}

export interface ExportTestSessionVariables {
  inputData: ExportTestSessionInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: FinishTestSession
// ====================================================

export interface FinishTestSession_finishTestSession {
  __typename: "TestSession";
  id: string;
}

export interface FinishTestSession {
  finishTestSession: FinishTestSession_finishTestSession | null;
}

export interface FinishTestSessionVariables {
  inputData: FinishTestSessionInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: FinishTestSessionPayment
// ====================================================

export interface FinishTestSessionPayment {
  finishTestSessionPayment: boolean;
}

export interface FinishTestSessionPaymentVariables {
  stripePaymentId: string;
  paymentError?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GroupName
// ====================================================

export interface GroupName_groupName {
  __typename: "GroupName";
  name: string;
  companyName: string;
}

export interface GroupName {
  groupName: GroupName_groupName | null;
}

export interface GroupNameVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Groups
// ====================================================

export interface Groups_groups_Groups_Company {
  __typename: "Company";
  id: string;
  name: string;
}

export interface Groups_groups_Groups_Members_Members_User {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
}

export interface Groups_groups_Groups_Members_Members {
  __typename: "GroupMember";
  User: Groups_groups_Groups_Members_Members_User;
  role: GroupMemberRole;
}

export interface Groups_groups_Groups_Members_pagination {
  __typename: "Pagination";
  length: number;
  from: number;
  total: number;
}

export interface Groups_groups_Groups_Members {
  __typename: "GroupMembersWithPagination";
  Members: Groups_groups_Groups_Members_Members[];
  pagination: Groups_groups_Groups_Members_pagination;
}

export interface Groups_groups_Groups {
  __typename: "Group";
  id: string;
  name: string;
  Company: Groups_groups_Groups_Company | null;
  Members: Groups_groups_Groups_Members | null;
}

export interface Groups_groups_pagination {
  __typename: "Pagination";
  length: number;
  from: number;
  total: number;
}

export interface Groups_groups {
  __typename: "GroupsWithPagination";
  Groups: Groups_groups_Groups[];
  pagination: Groups_groups_pagination;
}

export interface Groups {
  groups: Groups_groups | null;
}

export interface GroupsVariables {
  filterData?: GroupsFilterInput | null;
  pagination: PaginationInput;
  membersFilterData?: UsersFilterInput | null;
  membersPagination: PaginationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GroupInvitations
// ====================================================

export interface GroupInvitations_pendingGroupInvites_Company {
  __typename: "Company";
  id: string;
  name: string;
}

export interface GroupInvitations_pendingGroupInvites {
  __typename: "Group";
  id: string;
  name: string;
  Company: GroupInvitations_pendingGroupInvites_Company | null;
}

export interface GroupInvitations {
  pendingGroupInvites: GroupInvitations_pendingGroupInvites[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AcceptGroupInvite
// ====================================================

export interface AcceptGroupInvite {
  acceptGroupInvite: boolean;
}

export interface AcceptGroupInviteVariables {
  groupId: string;
  subpersonId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DenyGroupInvite
// ====================================================

export interface DenyGroupInvite {
  denyGroupInvite: boolean;
}

export interface DenyGroupInviteVariables {
  groupId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GroupWithMembers
// ====================================================

export interface GroupWithMembers_group_Company {
  __typename: "Company";
  name: string;
}

export interface GroupWithMembers_group_Members_Members_User_ParentUser {
  __typename: "User";
  email: string | null;
}

export interface GroupWithMembers_group_Members_Members_User {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  isCompliant: boolean;
  parentId: string | null;
  ParentUser: GroupWithMembers_group_Members_Members_User_ParentUser | null;
}

export interface GroupWithMembers_group_Members_Members {
  __typename: "GroupMember";
  User: GroupWithMembers_group_Members_Members_User;
  role: GroupMemberRole;
  acceptedAt: any | null;
}

export interface GroupWithMembers_group_Members_pagination {
  __typename: "Pagination";
  from: number;
  length: number;
  total: number;
}

export interface GroupWithMembers_group_Members {
  __typename: "GroupMembersWithPagination";
  Members: GroupWithMembers_group_Members_Members[];
  pagination: GroupWithMembers_group_Members_pagination;
}

export interface GroupWithMembers_group {
  __typename: "Group";
  id: string;
  name: string;
  isOverwrite: boolean | null;
  japaneseGroup: boolean;
  Company: GroupWithMembers_group_Company | null;
  Members: GroupWithMembers_group_Members | null;
}

export interface GroupWithMembers {
  group: GroupWithMembers_group | null;
}

export interface GroupWithMembersVariables {
  id: string;
  filterData?: UsersFilterInput | null;
  pagination: PaginationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: InsuranceByUser
// ====================================================

export interface InsuranceByUser_getInsuranceByUser_User {
  __typename: "User";
  firstName: string | null;
  lastName: string | null;
}

export interface InsuranceByUser_getInsuranceByUser_CheckUser {
  __typename: "User";
  firstName: string | null;
  lastName: string | null;
}

export interface InsuranceByUser_getInsuranceByUser {
  __typename: "Insurance";
  id: string;
  userId: string;
  User: InsuranceByUser_getInsuranceByUser_User;
  insuranceCompany: string | null;
  plan: string | null;
  SSN: string | null;
  driversLicense: string | null;
  cardID: string | null;
  groupNumber: string | null;
  relationToSubscriber: string | null;
  govId: string | null;
  govIdChecked: boolean | null;
  govIdMessageDenied: string | null;
  insuranceCardFront: string | null;
  insuranceCardFrontChecked: boolean | null;
  insuranceCardFrontMessageDenied: string | null;
  insuranceCardBack: string | null;
  insuranceCardBackChecked: boolean | null;
  insuranceCardBackMessageDenied: string | null;
  isApproved: boolean | null;
  eligibilityStatus: string | null;
  eligibilityErrorMessage: string | null;
  manualEligibilityCheckUserId: string | null;
  lastEligibilityCheck: any | null;
  subscriberFirstName: string | null;
  subscriberMiddleName: string | null;
  subscriberLastName: string | null;
  subscriberDOB: any | null;
  subscriberGender: Gender | null;
  CheckUser: InsuranceByUser_getInsuranceByUser_CheckUser | null;
}

export interface InsuranceByUser {
  getInsuranceByUser: InsuranceByUser_getInsuranceByUser | null;
}

export interface InsuranceByUserVariables {
  inputData: InsuranceByUserInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AddInsurance
// ====================================================

export interface AddInsurance_addInsurance {
  __typename: "Insurance";
  id: string;
  userId: string;
}

export interface AddInsurance {
  addInsurance: AddInsurance_addInsurance;
}

export interface AddInsuranceVariables {
  inputData: AddInsuranceInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: EditInsurance
// ====================================================

export interface EditInsurance_editInsurance {
  __typename: "Insurance";
  id: string;
  userId: string;
}

export interface EditInsurance {
  editInsurance: EditInsurance_editInsurance;
}

export interface EditInsuranceVariables {
  inputData: AddInsuranceInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SendEmailInsurance
// ====================================================

export interface SendEmailInsurance {
  sendEmailInsurance: boolean;
}

export interface SendEmailInsuranceVariables {
  inputData: SendEmailInsuranceInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: InsuranceCompanies
// ====================================================

export interface InsuranceCompanies_getInsuranceCompanies_insuranceCompanies {
  __typename: "InsuranceCompany";
  id: string;
  name: string;
  pVerifyId: string | null;
  isApproved: boolean;
  daysBetweenTests: number;
}

export interface InsuranceCompanies_getInsuranceCompanies_pagination {
  __typename: "Pagination";
  from: number;
  length: number;
  total: number;
}

export interface InsuranceCompanies_getInsuranceCompanies {
  __typename: "InsuranceCompanies";
  insuranceCompanies: InsuranceCompanies_getInsuranceCompanies_insuranceCompanies[];
  pagination: InsuranceCompanies_getInsuranceCompanies_pagination;
}

export interface InsuranceCompanies {
  getInsuranceCompanies: InsuranceCompanies_getInsuranceCompanies;
}

export interface InsuranceCompaniesVariables {
  filterData?: InsuranceCompanyFilterInput | null;
  pagination: PaginationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: InsuranceErrors
// ====================================================

export interface InsuranceErrors_getInsuranceErrors_insurances_User {
  __typename: "User";
  firstName: string | null;
  lastName: string | null;
  birthDate: any | null;
  gender: Gender | null;
}

export interface InsuranceErrors_getInsuranceErrors_insurances_CheckUser {
  __typename: "User";
  firstName: string | null;
  lastName: string | null;
}

export interface InsuranceErrors_getInsuranceErrors_insurances {
  __typename: "Insurance";
  id: string;
  userId: string;
  User: InsuranceErrors_getInsuranceErrors_insurances_User;
  insuranceCompany: string | null;
  plan: string | null;
  SSN: string | null;
  driversLicense: string | null;
  cardID: string | null;
  groupNumber: string | null;
  relationToSubscriber: string | null;
  govId: string | null;
  govIdChecked: boolean | null;
  govIdMessageDenied: string | null;
  insuranceCardFront: string | null;
  insuranceCardFrontChecked: boolean | null;
  insuranceCardFrontMessageDenied: string | null;
  insuranceCardBack: string | null;
  insuranceCardBackChecked: boolean | null;
  insuranceCardBackMessageDenied: string | null;
  isApproved: boolean | null;
  eligibilityStatus: string | null;
  eligibilityErrorMessage: string | null;
  manualEligibilityCheckUserId: string | null;
  lastEligibilityCheck: any | null;
  subscriberFirstName: string | null;
  subscriberMiddleName: string | null;
  subscriberLastName: string | null;
  subscriberDOB: any | null;
  subscriberGender: Gender | null;
  CheckUser: InsuranceErrors_getInsuranceErrors_insurances_CheckUser | null;
}

export interface InsuranceErrors_getInsuranceErrors_pagination {
  __typename: "Pagination";
  from: number;
  length: number;
  total: number;
}

export interface InsuranceErrors_getInsuranceErrors {
  __typename: "InsuranceErrorsWithPagination";
  insurances: InsuranceErrors_getInsuranceErrors_insurances[];
  pagination: InsuranceErrors_getInsuranceErrors_pagination;
}

export interface InsuranceErrors {
  getInsuranceErrors: InsuranceErrors_getInsuranceErrors;
}

export interface InsuranceErrorsVariables {
  filterData?: InsuranceErrorFilterInput | null;
  pagination: PaginationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ListInsuranceCompanies
// ====================================================

export interface ListInsuranceCompanies_listInsuranceCompanies {
  __typename: "InsuranceCompany";
  id: string;
  name: string;
  pVerifyId: string | null;
  isApproved: boolean;
  daysBetweenTests: number;
}

export interface ListInsuranceCompanies {
  listInsuranceCompanies: ListInsuranceCompanies_listInsuranceCompanies[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetPatientNotApprovedInsuranceCompanies
// ====================================================

export interface GetPatientNotApprovedInsuranceCompanies_getPatientsNotApprovedInsurance_Insurance {
  __typename: "Insurance";
  insuranceCompany: string | null;
}

export interface GetPatientNotApprovedInsuranceCompanies_getPatientsNotApprovedInsurance {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  Insurance: GetPatientNotApprovedInsuranceCompanies_getPatientsNotApprovedInsurance_Insurance | null;
}

export interface GetPatientNotApprovedInsuranceCompanies {
  getPatientsNotApprovedInsurance: GetPatientNotApprovedInsuranceCompanies_getPatientsNotApprovedInsurance[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AddInsuranceCompany
// ====================================================

export interface AddInsuranceCompany_addInsuranceCompany {
  __typename: "InsuranceCompany";
  id: string;
  name: string;
  pVerifyId: string | null;
  isApproved: boolean;
  daysBetweenTests: number;
}

export interface AddInsuranceCompany {
  addInsuranceCompany: AddInsuranceCompany_addInsuranceCompany;
}

export interface AddInsuranceCompanyVariables {
  inputData: AddInsuranceCompanyInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: EditInsuranceCompany
// ====================================================

export interface EditInsuranceCompany_editInsuranceCompany {
  __typename: "InsuranceCompany";
  id: string;
  name: string;
  pVerifyId: string | null;
  isApproved: boolean;
  daysBetweenTests: number;
}

export interface EditInsuranceCompany {
  editInsuranceCompany: EditInsuranceCompany_editInsuranceCompany;
}

export interface EditInsuranceCompanyVariables {
  inputData: EditInsuranceCompanyInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ConfirmInsuranceEligibility
// ====================================================

export interface ConfirmInsuranceEligibility_confirmInsuranceEligibility_User {
  __typename: "User";
  firstName: string | null;
  lastName: string | null;
}

export interface ConfirmInsuranceEligibility_confirmInsuranceEligibility_CheckUser {
  __typename: "User";
  firstName: string | null;
  lastName: string | null;
}

export interface ConfirmInsuranceEligibility_confirmInsuranceEligibility {
  __typename: "Insurance";
  id: string;
  userId: string;
  User: ConfirmInsuranceEligibility_confirmInsuranceEligibility_User;
  insuranceCompany: string | null;
  plan: string | null;
  SSN: string | null;
  driversLicense: string | null;
  cardID: string | null;
  groupNumber: string | null;
  relationToSubscriber: string | null;
  govId: string | null;
  govIdChecked: boolean | null;
  govIdMessageDenied: string | null;
  insuranceCardFront: string | null;
  insuranceCardFrontChecked: boolean | null;
  insuranceCardFrontMessageDenied: string | null;
  insuranceCardBack: string | null;
  insuranceCardBackChecked: boolean | null;
  insuranceCardBackMessageDenied: string | null;
  eligibilityStatus: string | null;
  eligibilityErrorMessage: string | null;
  manualEligibilityCheckUserId: string | null;
  lastEligibilityCheck: any | null;
  subscriberFirstName: string | null;
  subscriberMiddleName: string | null;
  subscriberLastName: string | null;
  subscriberDOB: any | null;
  subscriberGender: Gender | null;
  CheckUser: ConfirmInsuranceEligibility_confirmInsuranceEligibility_CheckUser | null;
}

export interface ConfirmInsuranceEligibility {
  confirmInsuranceEligibility: ConfirmInsuranceEligibility_confirmInsuranceEligibility;
}

export interface ConfirmInsuranceEligibilityVariables {
  inputData: ConfirmInsuranceEligibilityInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ConfirmInsuranceEligibilityEvent
// ====================================================

export interface ConfirmInsuranceEligibilityEvent_confirmInsuranceEligibilityEvent {
  __typename: "EventAttendee";
  id: string;
}

export interface ConfirmInsuranceEligibilityEvent {
  confirmInsuranceEligibilityEvent: ConfirmInsuranceEligibilityEvent_confirmInsuranceEligibilityEvent;
}

export interface ConfirmInsuranceEligibilityEventVariables {
  inputData: ConfirmInsuranceEligibilityEventInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CheckInsuranceEligibility
// ====================================================

export interface CheckInsuranceEligibility_checkInsuranceEligibility_User {
  __typename: "User";
  firstName: string | null;
  lastName: string | null;
}

export interface CheckInsuranceEligibility_checkInsuranceEligibility_CheckUser {
  __typename: "User";
  firstName: string | null;
  lastName: string | null;
}

export interface CheckInsuranceEligibility_checkInsuranceEligibility {
  __typename: "Insurance";
  id: string;
  userId: string;
  User: CheckInsuranceEligibility_checkInsuranceEligibility_User;
  insuranceCompany: string | null;
  plan: string | null;
  SSN: string | null;
  driversLicense: string | null;
  cardID: string | null;
  groupNumber: string | null;
  relationToSubscriber: string | null;
  govId: string | null;
  govIdChecked: boolean | null;
  govIdMessageDenied: string | null;
  insuranceCardFront: string | null;
  insuranceCardFrontChecked: boolean | null;
  insuranceCardFrontMessageDenied: string | null;
  insuranceCardBack: string | null;
  insuranceCardBackChecked: boolean | null;
  insuranceCardBackMessageDenied: string | null;
  eligibilityStatus: string | null;
  eligibilityErrorMessage: string | null;
  manualEligibilityCheckUserId: string | null;
  lastEligibilityCheck: any | null;
  subscriberFirstName: string | null;
  subscriberMiddleName: string | null;
  subscriberLastName: string | null;
  subscriberDOB: any | null;
  subscriberGender: Gender | null;
  CheckUser: CheckInsuranceEligibility_checkInsuranceEligibility_CheckUser | null;
}

export interface CheckInsuranceEligibility {
  checkInsuranceEligibility: CheckInsuranceEligibility_checkInsuranceEligibility | null;
}

export interface CheckInsuranceEligibilityVariables {
  inputData: CheckUserEligibilityInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CheckInsuranceEligibilityEvent
// ====================================================

export interface CheckInsuranceEligibilityEvent {
  checkInsuranceEligibilityEvent: boolean | null;
}

export interface CheckInsuranceEligibilityEventVariables {
  inputData: CheckUserEligibilityEventInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: InviteUser
// ====================================================

export interface InviteUser_clientInviteUser {
  __typename: "InvitedUser";
  id: string;
}

export interface InviteUser {
  clientInviteUser: InviteUser_clientInviteUser | null;
}

export interface InviteUserVariables {
  inputData: InviteUserInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: InviteNewUser
// ====================================================

export interface InviteNewUser_inviteUser {
  __typename: "InvitedUser";
  id: string;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
}

export interface InviteNewUser {
  inviteUser: InviteNewUser_inviteUser;
}

export interface InviteNewUserVariables {
  inputData: InviteUserInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: InvoiceDetails
// ====================================================

export interface InvoiceDetails_invoiceDetails {
  __typename: "InvoiceDetails";
  id: string;
  invoiceId: string | null;
  invoiceDocNumber: string | null;
  status: any | null;
  date: string;
  type: TestType;
  invoiceDate: any | null;
  autoInvoice: boolean | null;
  name: string;
  count: number;
  groupId: string | null;
  totalPrice: number;
  sublocation: string;
}

export interface InvoiceDetails {
  invoiceDetails: InvoiceDetails_invoiceDetails[];
}

export interface InvoiceDetailsVariables {
  filter: InvoiceDetailsInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Invoices
// ====================================================

export interface Invoices_getInvoices_invoices_TestSession_Location {
  __typename: "Address";
  city: string;
  street: string;
  state: string | null;
  zip: string;
}

export interface Invoices_getInvoices_invoices_TestSession_Group_Company {
  __typename: "Company";
  name: string;
}

export interface Invoices_getInvoices_invoices_TestSession_Group {
  __typename: "Group";
  name: string;
  Company: Invoices_getInvoices_invoices_TestSession_Group_Company | null;
}

export interface Invoices_getInvoices_invoices_TestSession {
  __typename: "TestSession";
  id: string;
  date: any;
  name: string | null;
  type: TestType;
  Location: Invoices_getInvoices_invoices_TestSession_Location;
  sublocation: string;
  Group: Invoices_getInvoices_invoices_TestSession_Group | null;
}

export interface Invoices_getInvoices_invoices_Event_EventDays {
  __typename: "EventDay";
  startTime: any;
}

export interface Invoices_getInvoices_invoices_Event_Address {
  __typename: "Address";
  street: string;
  city: string;
  state: string | null;
  zip: string;
}

export interface Invoices_getInvoices_invoices_Event_Group_Company {
  __typename: "Company";
  name: string;
}

export interface Invoices_getInvoices_invoices_Event_Group {
  __typename: "Group";
  name: string;
  Company: Invoices_getInvoices_invoices_Event_Group_Company | null;
}

export interface Invoices_getInvoices_invoices_Event {
  __typename: "Event";
  id: string;
  EventDays: Invoices_getInvoices_invoices_Event_EventDays[] | null;
  name: string;
  Address: Invoices_getInvoices_invoices_Event_Address | null;
  sublocation: SubLocation;
  testType: TestType;
  Group: Invoices_getInvoices_invoices_Event_Group | null;
}

export interface Invoices_getInvoices_invoices {
  __typename: "Invoice";
  testSessionId: string | null;
  TestSession: Invoices_getInvoices_invoices_TestSession | null;
  eventId: string | null;
  Event: Invoices_getInvoices_invoices_Event | null;
  invoiceId: string | null;
  invoiceDocNumber: string | null;
  invoiceDate: any | null;
  autoInvoice: boolean | null;
  count: number | null;
  totalPrice: number | null;
  error: string | null;
}

export interface Invoices_getInvoices_pagination {
  __typename: "Pagination";
  from: number;
  length: number;
  total: number;
}

export interface Invoices_getInvoices {
  __typename: "InvoicesWithPagination";
  invoices: Invoices_getInvoices_invoices[];
  pagination: Invoices_getInvoices_pagination;
}

export interface Invoices {
  getInvoices: Invoices_getInvoices;
}

export interface InvoicesVariables {
  filterData?: InvoicesInput | null;
  pagination: PaginationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetLocationProducts
// ====================================================

export interface GetLocationProducts_products_products_Prices {
  __typename: "ProductPrice";
  id: string;
  price: number;
  minQuantity: number;
  sublocation: SubLocation | null;
}

export interface GetLocationProducts_products_products {
  __typename: "Product";
  id: string;
  name: string;
  type: ProductTypes | null;
  Prices: GetLocationProducts_products_products_Prices[];
}

export interface GetLocationProducts_products {
  __typename: "ProductsWithPagination";
  products: GetLocationProducts_products_products[];
}

export interface GetLocationProducts {
  products: GetLocationProducts_products;
}

export interface GetLocationProductsVariables {
  filterData?: ProductsFilterInput | null;
  pagination: PaginationInput;
  pricefilterData?: PricesFilterInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: LogOut
// ====================================================

export interface LogOut {
  logOut: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetLogFiles
// ====================================================

export interface GetLogFiles_getLogFiles {
  __typename: "Log";
  lab: Lab;
  url: string;
  date: string;
}

export interface GetLogFiles {
  getLogFiles: GetLogFiles_getLogFiles[] | null;
}

export interface GetLogFilesVariables {
  filterData: GetLogFilesInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetLogFile
// ====================================================

export interface GetLogFile {
  getLogFile: string;
}

export interface GetLogFileVariables {
  key: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Me
// ====================================================

export interface Me_me_User_Address {
  __typename: "Address";
  street: string;
  city: string;
  state: string | null;
  country: string;
  county: string | null;
  complement: string | null;
  zip: string;
  lat: number | null;
  lon: number | null;
}

export interface Me_me_User_CompaniesWithRole {
  __typename: "UserCompanyWithRole";
  id: string;
  name: string;
  role: CompanyMemberRole;
  eventClearance: boolean;
}

export interface Me_me_User_GroupsWithRole {
  __typename: "UserGroupWithRole";
  id: string;
  companyId: string;
  name: string;
  role: GroupMemberRole;
}

export interface Me_me_User_PendingGroupInvites_Company {
  __typename: "Company";
  id: string;
  name: string;
}

export interface Me_me_User_PendingGroupInvites_Documents {
  __typename: "GroupDocument";
  id: string;
  groupId: string;
  name: string;
  url: string;
}

export interface Me_me_User_PendingGroupInvites {
  __typename: "Group";
  id: string;
  name: string;
  Company: Me_me_User_PendingGroupInvites_Company | null;
  Documents: Me_me_User_PendingGroupInvites_Documents[];
}

export interface Me_me_User_SubpersonsPendingGroupInvites_GroupUsers_User {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  parentId: string | null;
}

export interface Me_me_User_SubpersonsPendingGroupInvites_GroupUsers {
  __typename: "GroupMember";
  User: Me_me_User_SubpersonsPendingGroupInvites_GroupUsers_User;
}

export interface Me_me_User_SubpersonsPendingGroupInvites_Company {
  __typename: "Company";
  id: string;
  name: string;
}

export interface Me_me_User_SubpersonsPendingGroupInvites_Documents {
  __typename: "GroupDocument";
  id: string;
  groupId: string;
  name: string;
  url: string;
}

export interface Me_me_User_SubpersonsPendingGroupInvites {
  __typename: "Group";
  id: string;
  name: string;
  GroupUsers: (Me_me_User_SubpersonsPendingGroupInvites_GroupUsers | null)[] | null;
  Company: Me_me_User_SubpersonsPendingGroupInvites_Company | null;
  Documents: Me_me_User_SubpersonsPendingGroupInvites_Documents[];
}

export interface Me_me_User_UnsubscribedNotifications {
  __typename: "UserUnsubscribedNotification";
  id: string;
  type: UserNotificationType;
}

export interface Me_me_User_UserRoles {
  __typename: "UserRoles";
  id: string | null;
  userId: string | null;
  role: UserRole | null;
}

export interface Me_me_User_Insurance {
  __typename: "Insurance";
  id: string;
  govId: string | null;
  govIdChecked: boolean | null;
  companyId: string | null;
  insuranceCompany: string | null;
  plan: string | null;
  cardID: string | null;
  subscriberFirstName: string | null;
  subscriberMiddleName: string | null;
  subscriberLastName: string | null;
  subscriberDOB: any | null;
  subscriberGender: Gender | null;
  relationToSubscriber: string | null;
  insuranceCardFrontChecked: boolean | null;
  insuranceCardFront: string | null;
  insuranceCardFrontMessageDenied: string | null;
  insuranceCardBackChecked: boolean | null;
  insuranceCardBack: string | null;
  insuranceCardBackMessageDenied: string | null;
  govIdMessageDenied: string | null;
  SSN: string | null;
  driversLicense: string | null;
  groupNumber: string | null;
  eligibilityStatus: string | null;
  eligibilityErrorMessage: string | null;
  manualEligibilityCheckUserId: string | null;
  lastEligibilityCheck: any | null;
  isApproved: boolean | null;
}

export interface Me_me_User {
  __typename: "User";
  id: string;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  gender: Gender | null;
  ethnicity: Ethnicity | null;
  race: Race | null;
  birthDate: any | null;
  phoneNumber: string | null;
  role: UserRole | null;
  updatedPasswordAt: any | null;
  Address: Me_me_User_Address | null;
  CompaniesWithRole: Me_me_User_CompaniesWithRole[];
  GroupsWithRole: Me_me_User_GroupsWithRole[];
  PendingGroupInvites: Me_me_User_PendingGroupInvites[];
  SubpersonsPendingGroupInvites: Me_me_User_SubpersonsPendingGroupInvites[];
  UnsubscribedNotifications: Me_me_User_UnsubscribedNotifications[];
  optInMarketing: boolean;
  UserRoles: Me_me_User_UserRoles[];
  Insurance: Me_me_User_Insurance | null;
}

export interface Me_me {
  __typename: "AuthSession";
  accessToken: string;
  User: Me_me_User;
}

export interface Me {
  me: Me_me | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetMemberDetails
// ====================================================

export interface GetMemberDetails_user_GroupUsers {
  __typename: "GroupUsers";
  groupId: string | null;
  acceptedAt: any | null;
}

export interface GetMemberDetails_user {
  __typename: "User";
  id: string;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  GroupUsers: (GetMemberDetails_user_GroupUsers | null)[] | null;
}

export interface GetMemberDetails {
  user: GetMemberDetails_user | null;
}

export interface GetMemberDetailsVariables {
  inputData: UserInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetMemberTestSessions
// ====================================================

export interface GetMemberTestSessions_testSessions_testSessions_Location {
  __typename: "Address";
  id: string;
  country: string;
  county: string | null;
  complement: string | null;
  state: string | null;
  city: string;
  street: string;
  zip: string;
}

export interface GetMemberTestSessions_testSessions_testSessions_Tests_Members_User {
  __typename: "User";
  id: string;
}

export interface GetMemberTestSessions_testSessions_testSessions_Tests_Members {
  __typename: "TestSessionMember";
  User: GetMemberTestSessions_testSessions_testSessions_Tests_Members_User;
}

export interface GetMemberTestSessions_testSessions_testSessions_Tests {
  __typename: "Test";
  id: string;
  testedAt: any;
  result: TestResult | null;
  secondResult: TestResult | null;
  Members: GetMemberTestSessions_testSessions_testSessions_Tests_Members[];
}

export interface GetMemberTestSessions_testSessions_testSessions {
  __typename: "TestSession";
  id: string;
  date: any;
  Location: GetMemberTestSessions_testSessions_testSessions_Location;
  Tests: GetMemberTestSessions_testSessions_testSessions_Tests[];
}

export interface GetMemberTestSessions_testSessions_pagination {
  __typename: "Pagination";
  from: number;
  length: number;
  total: number;
}

export interface GetMemberTestSessions_testSessions {
  __typename: "TestSessionsWithPagination";
  testSessions: GetMemberTestSessions_testSessions_testSessions[];
  pagination: GetMemberTestSessions_testSessions_pagination;
}

export interface GetMemberTestSessions {
  testSessions: GetMemberTestSessions_testSessions;
}

export interface GetMemberTestSessionsVariables {
  filterData?: TestSessionFilterInput | null;
  pagination: PaginationInput;
  skipResult: boolean;
  testsFilterData?: TestSessionTestsInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PersonalUserData
// ====================================================

export interface PersonalUserData_user_Address {
  __typename: "Address";
  city: string;
  country: string;
  county: string | null;
  complement: string | null;
  state: string | null;
  lat: number | null;
  lon: number | null;
  street: string;
  zip: string;
}

export interface PersonalUserData_user {
  __typename: "User";
  id: string;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  phoneNumber: string | null;
  birthDate: any | null;
  gender: Gender | null;
  ethnicity: Ethnicity | null;
  race: Race | null;
  Address: PersonalUserData_user_Address | null;
  cdcCard: string | null;
  photoID: string | null;
  optInMarketing: boolean;
}

export interface PersonalUserData {
  user: PersonalUserData_user | null;
}

export interface PersonalUserDataVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: EditPersonalUserData
// ====================================================

export interface EditPersonalUserData_editUser_Address {
  __typename: "Address";
  city: string;
  country: string;
  county: string | null;
  complement: string | null;
  state: string | null;
  lat: number | null;
  lon: number | null;
  street: string;
  zip: string;
}

export interface EditPersonalUserData_editUser {
  __typename: "User";
  id: string;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  phoneNumber: string | null;
  birthDate: any | null;
  gender: Gender | null;
  race: Race | null;
  Address: EditPersonalUserData_editUser_Address | null;
  cdcCard: string | null;
  photoID: string | null;
}

export interface EditPersonalUserData {
  editUser: EditPersonalUserData_editUser | null;
}

export interface EditPersonalUserDataVariables {
  inputData: EditUserInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: QuestionnaireSurveys
// ====================================================

export interface QuestionnaireSurveys_surveys_surveys_SurveyResponses {
  __typename: "SurveyResponse";
  id: string;
  key: string;
  value: string;
}

export interface QuestionnaireSurveys_surveys_surveys_User {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  phoneNumber: string | null;
}

export interface QuestionnaireSurveys_surveys_surveys {
  __typename: "Survey";
  id: string;
  createdAt: any;
  updatedAt: any;
  SurveyResponses: QuestionnaireSurveys_surveys_surveys_SurveyResponses[];
  User: QuestionnaireSurveys_surveys_surveys_User;
}

export interface QuestionnaireSurveys_surveys_pagination {
  __typename: "Pagination";
  from: number;
  length: number;
  total: number;
}

export interface QuestionnaireSurveys_surveys {
  __typename: "SurveysWithPagination";
  surveys: QuestionnaireSurveys_surveys_surveys[];
  pagination: QuestionnaireSurveys_surveys_pagination;
}

export interface QuestionnaireSurveys {
  surveys: QuestionnaireSurveys_surveys;
}

export interface QuestionnaireSurveysVariables {
  filterData?: SurveysFilterInput | null;
  pagination: PaginationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RejectTestSession
// ====================================================

export interface RejectTestSession_rejectTestSession {
  __typename: "TestSession";
  id: string;
}

export interface RejectTestSession {
  rejectTestSession: RejectTestSession_rejectTestSession | null;
}

export interface RejectTestSessionVariables {
  inputData: RejectTestSessionInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RemoveCompanyGroup
// ====================================================

export interface RemoveCompanyGroup {
  removeGroup: boolean;
}

export interface RemoveCompanyGroupVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RemoveGroupMember
// ====================================================

export interface RemoveGroupMember_removeGroupMember {
  __typename: "Group";
  id: string;
  name: string;
}

export interface RemoveGroupMember {
  removeGroupMember: RemoveGroupMember_removeGroupMember | null;
}

export interface RemoveGroupMemberVariables {
  inputData: RemoveGroupMemberInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RemoveTest
// ====================================================

export interface RemoveTest {
  removeTest: boolean | null;
}

export interface RemoveTestVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RemoveTestSessionMember
// ====================================================

export interface RemoveTestSessionMember {
  removeTestSessionMember: boolean;
}

export interface RemoveTestSessionMemberVariables {
  inputData: RemoveTestSessionMemberInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RequestGroupTestSession
// ====================================================

export interface RequestGroupTestSession_requestGroupTestSession_Location {
  __typename: "Address";
  id: string;
  street: string;
  city: string;
  country: string;
  county: string | null;
  state: string | null;
  zip: string;
}

export interface RequestGroupTestSession_requestGroupTestSession {
  __typename: "TestSession";
  id: string;
  date: any;
  type: TestType;
  Location: RequestGroupTestSession_requestGroupTestSession_Location;
}

export interface RequestGroupTestSession {
  requestGroupTestSession: RequestGroupTestSession_requestGroupTestSession | null;
}

export interface RequestGroupTestSessionVariables {
  inputData: RequestGroupTestSessionInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RequestPasswordReset
// ====================================================

export interface RequestPasswordReset {
  requestPasswordReset: boolean;
}

export interface RequestPasswordResetVariables {
  inputData: RequestPasswordInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: GetCoupon
// ====================================================

export interface GetCoupon {
  getCoupon: number | null;
}

export interface GetCouponVariables {
  coupon: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ResendEmailQuestionnaire
// ====================================================

export interface ResendEmailQuestionnaire {
  resendEmailQuestionnaire: boolean;
}

export interface ResendEmailQuestionnaireVariables {
  inputData: ResendEmailQuestionnaireInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ResendInvitation
// ====================================================

export interface ResendInvitation {
  resendUserInvitation: boolean;
}

export interface ResendInvitationVariables {
  email: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ResetPassword
// ====================================================

export interface ResetPassword {
  resetPassword: boolean;
}

export interface ResetPasswordVariables {
  inputData: ResetPasswordInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RunGroupClearance
// ====================================================

export interface RunGroupClearance {
  runGroupClearance: boolean;
}

export interface RunGroupClearanceVariables {
  inputData: RunGroupClearanceInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: Questionnaire
// ====================================================

export interface Questionnaire_addSurvey_User {
  __typename: "User";
  id: string;
  email: string | null;
}

export interface Questionnaire_addSurvey {
  __typename: "Survey";
  id: string;
  User: Questionnaire_addSurvey_User;
}

export interface Questionnaire {
  addSurvey: Questionnaire_addSurvey;
}

export interface QuestionnaireVariables {
  inputData: SurveyInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: TestSessionDetailWithResult
// ====================================================

export interface TestSessionDetailWithResult_testSession_RequestedByUser {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
}

export interface TestSessionDetailWithResult_testSession_Location {
  __typename: "Address";
  id: string;
  street: string;
  city: string;
  zip: string;
  country: string;
  county: string | null;
  complement: string | null;
  state: string | null;
  lat: number | null;
  lon: number | null;
}

export interface TestSessionDetailWithResult_testSession_Groups {
  __typename: "Group";
  id: string;
  name: string;
  japaneseGroup: boolean;
}

export interface TestSessionDetailWithResult_testSession_Company {
  __typename: "Company";
  id: string;
  name: string;
}

export interface TestSessionDetailWithResult_testSession_Members_User_Address {
  __typename: "Address";
  id: string;
  street: string;
  city: string;
  zip: string;
  state: string | null;
  country: string;
  county: string | null;
  complement: string | null;
  lat: number | null;
  lon: number | null;
}

export interface TestSessionDetailWithResult_testSession_Members_User_Insurance {
  __typename: "Insurance";
  id: string;
  insuranceCompany: string | null;
  companyId: string | null;
  groupNumber: string | null;
  subscriberFirstName: string | null;
  subscriberMiddleName: string | null;
  subscriberLastName: string | null;
  subscriberGender: Gender | null;
  subscriberDOB: any | null;
  insuranceCardFrontChecked: boolean | null;
  insuranceCardFront: string | null;
  insuranceCardFrontMessageDenied: string | null;
  insuranceCardBackChecked: boolean | null;
  insuranceCardBack: string | null;
  insuranceCardBackMessageDenied: string | null;
  parsedInsuranceStatus: string | null;
  govIdMessageDenied: string | null;
  SSN: string | null;
  driversLicense: string | null;
  govId: string | null;
  govIdChecked: boolean | null;
  relationToSubscriber: string | null;
  cardID: string | null;
}

export interface TestSessionDetailWithResult_testSession_Members_User {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  birthDate: any | null;
  isCompliant: boolean;
  Address: TestSessionDetailWithResult_testSession_Members_User_Address | null;
  phoneNumber: string | null;
  gender: Gender | null;
  ethnicity: Ethnicity | null;
  race: Race | null;
  Insurance: TestSessionDetailWithResult_testSession_Members_User_Insurance | null;
}

export interface TestSessionDetailWithResult_testSession_Members {
  __typename: "TestSessionMember";
  id: string;
  User: TestSessionDetailWithResult_testSession_Members_User;
  isStaff: boolean;
  isParticipant: boolean;
}

export interface TestSessionDetailWithResult_testSession_Tests_Members_User {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
}

export interface TestSessionDetailWithResult_testSession_Tests_Members_Group {
  __typename: "Group";
  id: string;
  name: string;
}

export interface TestSessionDetailWithResult_testSession_Tests_Members {
  __typename: "TestSessionMember";
  User: TestSessionDetailWithResult_testSession_Tests_Members_User;
  Group: TestSessionDetailWithResult_testSession_Tests_Members_Group | null;
  isParticipant: boolean;
  isStaff: boolean;
}

export interface TestSessionDetailWithResult_testSession_Tests_TestSession {
  __typename: "TestSession";
  lab: Lab | null;
}

export interface TestSessionDetailWithResult_testSession_Tests {
  __typename: "Test";
  id: string;
  createdAt: any;
  updatedAt: any;
  barcode: string | null;
  testedAt: any;
  result: TestResult | null;
  secondResult: TestResult | null;
  resultReceivedAt: any | null;
  resultProvisionedAt: any | null;
  specimenType: SpecimenTypes | null;
  Members: TestSessionDetailWithResult_testSession_Tests_Members[];
  TestSession: TestSessionDetailWithResult_testSession_Tests_TestSession;
}

export interface TestSessionDetailWithResult_testSession_Products_Product {
  __typename: "Product";
  name: string;
}

export interface TestSessionDetailWithResult_testSession_Products {
  __typename: "TestSessionProduct";
  price: number;
  quantity: number;
  totalPrice: number;
  Product: TestSessionDetailWithResult_testSession_Products_Product;
}

export interface TestSessionDetailWithResult_testSession_PretestSessionSurveys_User {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
}

export interface TestSessionDetailWithResult_testSession_PretestSessionSurveys_PretestSessionSurveyResponses {
  __typename: "PretestSessionSurveyResponse";
  id: string;
  key: string;
  value: string;
}

export interface TestSessionDetailWithResult_testSession_PretestSessionSurveys {
  __typename: "PretestSessionSurvey";
  id: string;
  User: TestSessionDetailWithResult_testSession_PretestSessionSurveys_User;
  PretestSessionSurveyResponses: TestSessionDetailWithResult_testSession_PretestSessionSurveys_PretestSessionSurveyResponses[];
}

export interface TestSessionDetailWithResult_testSession {
  __typename: "TestSession";
  id: string;
  name: string | null;
  type: TestType;
  date: any;
  endsAt: any | null;
  isScheduled: boolean;
  finishedAt: any | null;
  rejectedAt: any | null;
  RequestedByUser: TestSessionDetailWithResult_testSession_RequestedByUser | null;
  Location: TestSessionDetailWithResult_testSession_Location;
  Groups: TestSessionDetailWithResult_testSession_Groups[];
  Company: TestSessionDetailWithResult_testSession_Company | null;
  Members: TestSessionDetailWithResult_testSession_Members[];
  Tests: TestSessionDetailWithResult_testSession_Tests[];
  Products: TestSessionDetailWithResult_testSession_Products[];
  PretestSessionSurveys: TestSessionDetailWithResult_testSession_PretestSessionSurveys[];
  paymentStatus: TestSessionPaymentStatus | null;
  lab: Lab | null;
  estimatedMembers: number | null;
  totalPrice: number | null;
  paymentError: string | null;
}

export interface TestSessionDetailWithResult {
  testSession: TestSessionDetailWithResult_testSession | null;
}

export interface TestSessionDetailWithResultVariables {
  id: string;
  groupsInputData?: TestSessionGroupsInput | null;
  companyInputData?: TestSessionCompanyInput | null;
  skipResult: boolean;
  skipMemberFields: boolean;
  skipInsurance: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SetAntigenTypeTestSession
// ====================================================

export interface SetAntigenTypeTestSession {
  setAntigenTypeTestSession: boolean | null;
}

export interface SetAntigenTypeTestSessionVariables {
  inputData: SetAntigenTypeTestSessionInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SetJapaneseGroup
// ====================================================

export interface SetJapaneseGroup_setJapaneseGroup {
  __typename: "Group";
  id: string;
}

export interface SetJapaneseGroup {
  setJapaneseGroup: SetJapaneseGroup_setJapaneseGroup;
}

export interface SetJapaneseGroupVariables {
  inputData: SetJapaneseGroupInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SetTestSessionInvoiceDate
// ====================================================

export interface SetTestSessionInvoiceDate {
  setTestSessionInvoiceDate: boolean;
}

export interface SetTestSessionInvoiceDateVariables {
  inputData: TestSessionInvoiceDateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SetTestSessionLab
// ====================================================

export interface SetTestSessionLab {
  setTestSessionLab: boolean | null;
}

export interface SetTestSessionLabVariables {
  inputData: SetTestSessionLabInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SetupUserPaymentMethod
// ====================================================

export interface SetupUserPaymentMethod {
  setupUserPaymentMethod: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SetupTestSessionPayment
// ====================================================

export interface SetupTestSessionPayment {
  setupTestSessionPayment: string;
}

export interface SetupTestSessionPaymentVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: LogIn
// ====================================================

export interface LogIn_logIn_User_Address {
  __typename: "Address";
  street: string;
  city: string;
  state: string | null;
  country: string;
  county: string | null;
  complement: string | null;
  zip: string;
  lat: number | null;
  lon: number | null;
}

export interface LogIn_logIn_User_CompaniesWithRole {
  __typename: "UserCompanyWithRole";
  id: string;
  name: string;
  role: CompanyMemberRole;
  eventClearance: boolean;
}

export interface LogIn_logIn_User_GroupsWithRole {
  __typename: "UserGroupWithRole";
  id: string;
  companyId: string;
  name: string;
  role: GroupMemberRole;
}

export interface LogIn_logIn_User_PendingGroupInvites_Company {
  __typename: "Company";
  id: string;
  name: string;
}

export interface LogIn_logIn_User_PendingGroupInvites_Documents {
  __typename: "GroupDocument";
  id: string;
  groupId: string;
  name: string;
  url: string;
}

export interface LogIn_logIn_User_PendingGroupInvites {
  __typename: "Group";
  id: string;
  name: string;
  Company: LogIn_logIn_User_PendingGroupInvites_Company | null;
  Documents: LogIn_logIn_User_PendingGroupInvites_Documents[];
}

export interface LogIn_logIn_User_SubpersonsPendingGroupInvites_GroupUsers_User {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  parentId: string | null;
}

export interface LogIn_logIn_User_SubpersonsPendingGroupInvites_GroupUsers {
  __typename: "GroupMember";
  User: LogIn_logIn_User_SubpersonsPendingGroupInvites_GroupUsers_User;
}

export interface LogIn_logIn_User_SubpersonsPendingGroupInvites_Company {
  __typename: "Company";
  id: string;
  name: string;
}

export interface LogIn_logIn_User_SubpersonsPendingGroupInvites_Documents {
  __typename: "GroupDocument";
  id: string;
  groupId: string;
  name: string;
  url: string;
}

export interface LogIn_logIn_User_SubpersonsPendingGroupInvites {
  __typename: "Group";
  id: string;
  name: string;
  GroupUsers: (LogIn_logIn_User_SubpersonsPendingGroupInvites_GroupUsers | null)[] | null;
  Company: LogIn_logIn_User_SubpersonsPendingGroupInvites_Company | null;
  Documents: LogIn_logIn_User_SubpersonsPendingGroupInvites_Documents[];
}

export interface LogIn_logIn_User_UnsubscribedNotifications {
  __typename: "UserUnsubscribedNotification";
  id: string;
  type: UserNotificationType;
}

export interface LogIn_logIn_User_UserRoles {
  __typename: "UserRoles";
  id: string | null;
  userId: string | null;
  role: UserRole | null;
}

export interface LogIn_logIn_User_Insurance {
  __typename: "Insurance";
  id: string;
  govId: string | null;
  govIdChecked: boolean | null;
  companyId: string | null;
  insuranceCompany: string | null;
  plan: string | null;
  cardID: string | null;
  subscriberFirstName: string | null;
  subscriberMiddleName: string | null;
  subscriberLastName: string | null;
  subscriberDOB: any | null;
  subscriberGender: Gender | null;
  relationToSubscriber: string | null;
  insuranceCardFrontChecked: boolean | null;
  insuranceCardFront: string | null;
  insuranceCardFrontMessageDenied: string | null;
  insuranceCardBackChecked: boolean | null;
  insuranceCardBack: string | null;
  insuranceCardBackMessageDenied: string | null;
  govIdMessageDenied: string | null;
  SSN: string | null;
  driversLicense: string | null;
  groupNumber: string | null;
  eligibilityStatus: string | null;
  eligibilityErrorMessage: string | null;
  manualEligibilityCheckUserId: string | null;
  lastEligibilityCheck: any | null;
  isApproved: boolean | null;
}

export interface LogIn_logIn_User {
  __typename: "User";
  id: string;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  gender: Gender | null;
  race: Race | null;
  ethnicity: Ethnicity | null;
  birthDate: any | null;
  phoneNumber: string | null;
  updatedPasswordAt: any | null;
  Address: LogIn_logIn_User_Address | null;
  role: UserRole | null;
  CompaniesWithRole: LogIn_logIn_User_CompaniesWithRole[];
  GroupsWithRole: LogIn_logIn_User_GroupsWithRole[];
  PendingGroupInvites: LogIn_logIn_User_PendingGroupInvites[];
  SubpersonsPendingGroupInvites: LogIn_logIn_User_SubpersonsPendingGroupInvites[];
  UnsubscribedNotifications: LogIn_logIn_User_UnsubscribedNotifications[];
  optInMarketing: boolean;
  parentId: string | null;
  UserRoles: LogIn_logIn_User_UserRoles[];
  Insurance: LogIn_logIn_User_Insurance | null;
}

export interface LogIn_logIn {
  __typename: "AuthSession";
  accessToken: string;
  User: LogIn_logIn_User;
}

export interface LogIn {
  logIn: LogIn_logIn | null;
}

export interface LogInVariables {
  inputData: LogInInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SignUp
// ====================================================

export interface SignUp {
  signUp: boolean;
}

export interface SignUpVariables {
  token: string;
  inputData: EditUserInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Sublocations
// ====================================================

export interface Sublocations_sublocations {
  __typename: "Sublocation";
  id: string;
  name: string;
  displayName: string | null;
}

export interface Sublocations {
  sublocations: Sublocations_sublocations[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: EditSublocation
// ====================================================

export interface EditSublocation {
  editSublocation: boolean;
}

export interface EditSublocationVariables {
  id: string;
  sublocation: SubLocation;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Subpersons
// ====================================================

export interface Subpersons_subperson_Address {
  __typename: "Address";
  city: string;
  country: string;
  county: string | null;
  complement: string | null;
  state: string | null;
  lat: number | null;
  lon: number | null;
  street: string;
  zip: string;
}

export interface Subpersons_subperson_Groups {
  __typename: "Group";
  id: string;
  name: string;
}

export interface Subpersons_subperson_ParentUser {
  __typename: "User";
  firstName: string | null;
  lastName: string | null;
}

export interface Subpersons_subperson_UserRoles {
  __typename: "UserRoles";
  id: string | null;
  userId: string | null;
  role: UserRole | null;
}

export interface Subpersons_subperson {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  gender: Gender | null;
  ethnicity: Ethnicity | null;
  race: Race | null;
  email: string | null;
  phoneNumber: string | null;
  birthDate: any | null;
  lastTestedAt: any | null;
  role: UserRole | null;
  isCompliant: boolean;
  Address: Subpersons_subperson_Address | null;
  cdcCard: string | null;
  photoID: string | null;
  Groups: Subpersons_subperson_Groups[];
  parentId: string | null;
  ParentUser: Subpersons_subperson_ParentUser | null;
  UserRoles: Subpersons_subperson_UserRoles[];
}

export interface Subpersons {
  subperson: (Subpersons_subperson | null)[] | null;
}

export interface SubpersonsVariables {
  userId: string;
  companyId?: string | null;
  groupId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AllSubpersons
// ====================================================

export interface AllSubpersons_users_users_ParentUser {
  __typename: "User";
  firstName: string | null;
  lastName: string | null;
}

export interface AllSubpersons_users_users {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  birthDate: any | null;
  parentId: string | null;
  ParentUser: AllSubpersons_users_users_ParentUser | null;
}

export interface AllSubpersons_users_pagination {
  __typename: "Pagination";
  from: number;
  length: number;
  total: number;
}

export interface AllSubpersons_users {
  __typename: "UsersWithPagination";
  users: AllSubpersons_users_users[];
  pagination: AllSubpersons_users_pagination;
}

export interface AllSubpersons {
  users: AllSubpersons_users;
}

export interface AllSubpersonsVariables {
  filterData?: UsersFilterInput | null;
  pagination: PaginationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: TestLocations
// ====================================================

export interface TestLocations_testLocations_coordinatesBounds {
  __typename: "Coordinate";
  lat: number;
  lon: number;
}

export interface TestLocations_testLocations {
  __typename: "TestLocation";
  id: string;
  name: string;
  coordinatesBounds: TestLocations_testLocations_coordinatesBounds[];
  state: string;
  deleted: boolean;
  isDefaultPrice: boolean;
}

export interface TestLocations {
  testLocations: TestLocations_testLocations[];
}

export interface TestLocationsVariables {
  showDeleted: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: EditTestLocations
// ====================================================

export interface EditTestLocations_editTestLocations_coordinatesBounds {
  __typename: "Coordinate";
  lat: number;
  lon: number;
}

export interface EditTestLocations_editTestLocations {
  __typename: "TestLocation";
  id: string;
  name: string;
  coordinatesBounds: EditTestLocations_editTestLocations_coordinatesBounds[];
  state: string;
  deleted: boolean;
  isDefaultPrice: boolean;
}

export interface EditTestLocations {
  editTestLocations: EditTestLocations_editTestLocations;
}

export interface EditTestLocationsVariables {
  inputData: EditTestLocationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AddTestLocation
// ====================================================

export interface AddTestLocation_addTestLocation_coordinatesBounds {
  __typename: "Coordinate";
  lat: number;
  lon: number;
}

export interface AddTestLocation_addTestLocation {
  __typename: "TestLocation";
  id: string;
  name: string;
  coordinatesBounds: AddTestLocation_addTestLocation_coordinatesBounds[];
  state: string;
  deleted: boolean;
  isDefaultPrice: boolean;
}

export interface AddTestLocation {
  addTestLocation: AddTestLocation_addTestLocation;
}

export interface AddTestLocationVariables {
  inputData: AddTestLocationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: TestsFull
// ====================================================

export interface TestsFull_tests_tests_StaffMembers_User {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
}

export interface TestsFull_tests_tests_StaffMembers {
  __typename: "TestSessionMember";
  User: TestsFull_tests_tests_StaffMembers_User;
  isParticipant: boolean;
  isStaff: boolean;
}

export interface TestsFull_tests_tests_ParticipantMembers_User {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  birthDate: any | null;
}

export interface TestsFull_tests_tests_ParticipantMembers {
  __typename: "TestSessionMember";
  User: TestsFull_tests_tests_ParticipantMembers_User;
  isParticipant: boolean;
  isStaff: boolean;
}

export interface TestsFull_tests_tests_TestSession_Location {
  __typename: "Address";
  street: string;
  city: string;
}

export interface TestsFull_tests_tests_TestSession_Groups {
  __typename: "Group";
  id: string;
  name: string;
}

export interface TestsFull_tests_tests_TestSession_Company {
  __typename: "Company";
  id: string;
  name: string;
}

export interface TestsFull_tests_tests_TestSession {
  __typename: "TestSession";
  lab: Lab | null;
  type: TestType;
  Location: TestsFull_tests_tests_TestSession_Location;
  Groups: TestsFull_tests_tests_TestSession_Groups[];
  Company: TestsFull_tests_tests_TestSession_Company | null;
}

export interface TestsFull_tests_tests {
  __typename: "Test";
  id: string;
  testedAt: any;
  result: TestResult | null;
  secondResult: TestResult | null;
  resultProvisionedAt: any | null;
  resultReceivedAt: any | null;
  StaffMembers: TestsFull_tests_tests_StaffMembers[];
  ParticipantMembers: TestsFull_tests_tests_ParticipantMembers[];
  TestSession: TestsFull_tests_tests_TestSession;
}

export interface TestsFull_tests_pagination {
  __typename: "Pagination";
  from: number;
  length: number;
  total: number;
}

export interface TestsFull_tests {
  __typename: "TestsWithPagination";
  tests: TestsFull_tests_tests[];
  pagination: TestsFull_tests_pagination;
}

export interface TestsFull {
  tests: TestsFull_tests;
}

export interface TestsFullVariables {
  filterData?: TestFilterInput | null;
  pagination: PaginationInput;
  groupsInputData?: TestSessionGroupsInput | null;
  companyInputData?: TestSessionCompanyInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: TestsResult
// ====================================================

export interface TestsResult_tests_tests {
  __typename: "Test";
  id: string;
  testedAt: any;
  result: TestResult | null;
  secondResult: TestResult | null;
}

export interface TestsResult_tests {
  __typename: "TestsWithPagination";
  tests: TestsResult_tests_tests[];
}

export interface TestsResult {
  tests: TestsResult_tests;
}

export interface TestsResultVariables {
  filterData?: TestFilterInput | null;
  pagination: PaginationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: TestSessionMembers
// ====================================================

export interface TestSessionMembers_testSessionMembers_testSessionMembers_User_Address {
  __typename: "Address";
  id: string;
  street: string;
  city: string;
  zip: string;
  state: string | null;
  country: string;
  county: string | null;
  complement: string | null;
  lat: number | null;
  lon: number | null;
}

export interface TestSessionMembers_testSessionMembers_testSessionMembers_User_Insurance_CheckUser {
  __typename: "User";
  firstName: string | null;
  lastName: string | null;
}

export interface TestSessionMembers_testSessionMembers_testSessionMembers_User_Insurance {
  __typename: "Insurance";
  id: string;
  isApproved: boolean | null;
  insuranceCompany: string | null;
  companyId: string | null;
  groupNumber: string | null;
  subscriberFirstName: string | null;
  subscriberMiddleName: string | null;
  subscriberLastName: string | null;
  subscriberGender: Gender | null;
  subscriberDOB: any | null;
  insuranceCardFrontChecked: boolean | null;
  insuranceCardFront: string | null;
  insuranceCardFrontMessageDenied: string | null;
  insuranceCardBackChecked: boolean | null;
  insuranceCardBack: string | null;
  insuranceCardBackMessageDenied: string | null;
  govIdMessageDenied: string | null;
  SSN: string | null;
  driversLicense: string | null;
  govId: string | null;
  govIdChecked: boolean | null;
  relationToSubscriber: string | null;
  cardID: string | null;
  eligibilityStatus: string | null;
  eligibilityErrorMessage: string | null;
  manualEligibilityCheckUserId: string | null;
  lastEligibilityCheck: any | null;
  rejectReason: string | null;
  CheckUser: TestSessionMembers_testSessionMembers_testSessionMembers_User_Insurance_CheckUser | null;
}

export interface TestSessionMembers_testSessionMembers_testSessionMembers_User_GroupUsers {
  __typename: "GroupUsers";
  userId: string | null;
  groupId: string | null;
  acceptedAt: any | null;
}

export interface TestSessionMembers_testSessionMembers_testSessionMembers_User {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  isCompliant: boolean;
  birthDate: any | null;
  phoneNumber: string | null;
  gender: Gender | null;
  ethnicity: Ethnicity | null;
  race: Race | null;
  Address: TestSessionMembers_testSessionMembers_testSessionMembers_User_Address | null;
  Insurance: TestSessionMembers_testSessionMembers_testSessionMembers_User_Insurance | null;
  GroupUsers: (TestSessionMembers_testSessionMembers_testSessionMembers_User_GroupUsers | null)[] | null;
}

export interface TestSessionMembers_testSessionMembers_testSessionMembers_Group {
  __typename: "Group";
  id: string;
}

export interface TestSessionMembers_testSessionMembers_testSessionMembers_Test_TestSession {
  __typename: "TestSession";
  lab: Lab | null;
}

export interface TestSessionMembers_testSessionMembers_testSessionMembers_Test {
  __typename: "Test";
  id: string;
  result: TestResult | null;
  secondResult: TestResult | null;
  testedAt: any;
  TestSession: TestSessionMembers_testSessionMembers_testSessionMembers_Test_TestSession;
}

export interface TestSessionMembers_testSessionMembers_testSessionMembers {
  __typename: "TestSessionMember";
  id: string;
  User: TestSessionMembers_testSessionMembers_testSessionMembers_User;
  Group: TestSessionMembers_testSessionMembers_testSessionMembers_Group | null;
  Test: TestSessionMembers_testSessionMembers_testSessionMembers_Test | null;
  isStaff: boolean;
  isParticipant: boolean;
}

export interface TestSessionMembers_testSessionMembers_pagination {
  __typename: "Pagination";
  from: number;
  length: number;
  total: number;
}

export interface TestSessionMembers_testSessionMembers {
  __typename: "TestSessionMembersWithPagination";
  testSessionMembers: TestSessionMembers_testSessionMembers_testSessionMembers[];
  pagination: TestSessionMembers_testSessionMembers_pagination;
}

export interface TestSessionMembers {
  testSessionMembers: TestSessionMembers_testSessionMembers;
}

export interface TestSessionMembersVariables {
  id: string;
  filterData?: TestSessionMembersFilterInput | null;
  pagination: PaginationInput;
  skipTest: boolean;
  testFilterData?: TestSessionMemberTestFilterInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: TestSessionMembersQuickReport
// ====================================================

export interface TestSessionMembersQuickReport_testSessionMembersQuickReport {
  __typename: "TestSessionMemberQuickReport";
  id: string;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  testedAt: any | null;
  testResult: TestResult | null;
  secondTestResult: TestResult | null;
  insuranceCompany: string | null;
}

export interface TestSessionMembersQuickReport {
  testSessionMembersQuickReport: TestSessionMembersQuickReport_testSessionMembersQuickReport[];
}

export interface TestSessionMembersQuickReportVariables {
  id: string;
  filterData?: TestSessionMembersQuickReportInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: TestSessions
// ====================================================

export interface TestSessions_testSessions_testSessions_Location {
  __typename: "Address";
  id: string;
  street: string;
  city: string;
}

export interface TestSessions_testSessions_testSessions_Members {
  __typename: "TestSessionMember";
  isParticipant: boolean;
  isResulted: boolean | null;
}

export interface TestSessions_testSessions_testSessions_Group {
  __typename: "Group";
  id: string;
  name: string;
  japaneseGroup: boolean;
}

export interface TestSessions_testSessions_testSessions_Groups {
  __typename: "Group";
  id: string;
  name: string;
  japaneseGroup: boolean;
}

export interface TestSessions_testSessions_testSessions_Company {
  __typename: "Company";
  id: string;
  name: string;
}

export interface TestSessions_testSessions_testSessions_Invoice {
  __typename: "Invoice";
  invoiceId: string | null;
  invoiceDocNumber: string | null;
  invoiceDate: any | null;
  autoInvoice: boolean | null;
}

export interface TestSessions_testSessions_testSessions {
  __typename: "TestSession";
  id: string;
  date: any;
  name: string | null;
  type: TestType;
  category: CategoryType;
  lab: Lab | null;
  isScheduled: boolean;
  rejectedAt: any | null;
  finishedAt: any | null;
  Location: TestSessions_testSessions_testSessions_Location;
  Members: TestSessions_testSessions_testSessions_Members[];
  Group: TestSessions_testSessions_testSessions_Group | null;
  Groups: TestSessions_testSessions_testSessions_Groups[];
  Company: TestSessions_testSessions_testSessions_Company | null;
  paymentStatus: TestSessionPaymentStatus | null;
  sublocation: string;
  Invoice: TestSessions_testSessions_testSessions_Invoice | null;
}

export interface TestSessions_testSessions_pagination {
  __typename: "Pagination";
  from: number;
  total: number;
  length: number;
}

export interface TestSessions_testSessions {
  __typename: "TestSessionsWithPagination";
  testSessions: TestSessions_testSessions_testSessions[];
  pagination: TestSessions_testSessions_pagination;
}

export interface TestSessions {
  testSessions: TestSessions_testSessions;
}

export interface TestSessionsVariables {
  filterData?: TestSessionFilterInput | null;
  groupsInputData?: TestSessionGroupsInput | null;
  companyInputData?: TestSessionCompanyInput | null;
  pagination: PaginationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: TestSessionsWithoutMembers
// ====================================================

export interface TestSessionsWithoutMembers_testSessions_testSessions_Location {
  __typename: "Address";
  id: string;
  street: string;
  city: string;
}

export interface TestSessionsWithoutMembers_testSessions_testSessions_Group {
  __typename: "Group";
  id: string;
  name: string;
}

export interface TestSessionsWithoutMembers_testSessions_testSessions_Groups {
  __typename: "Group";
  id: string;
  name: string;
}

export interface TestSessionsWithoutMembers_testSessions_testSessions_Company {
  __typename: "Company";
  id: string;
  name: string;
}

export interface TestSessionsWithoutMembers_testSessions_testSessions_Invoice {
  __typename: "Invoice";
  invoiceId: string | null;
  invoiceDocNumber: string | null;
  invoiceDate: any | null;
  autoInvoice: boolean | null;
}

export interface TestSessionsWithoutMembers_testSessions_testSessions {
  __typename: "TestSession";
  id: string;
  date: any;
  name: string | null;
  type: TestType;
  lab: Lab | null;
  isScheduled: boolean;
  rejectedAt: any | null;
  finishedAt: any | null;
  Location: TestSessionsWithoutMembers_testSessions_testSessions_Location;
  Group: TestSessionsWithoutMembers_testSessions_testSessions_Group | null;
  Groups: TestSessionsWithoutMembers_testSessions_testSessions_Groups[];
  Company: TestSessionsWithoutMembers_testSessions_testSessions_Company | null;
  paymentStatus: TestSessionPaymentStatus | null;
  Invoice: TestSessionsWithoutMembers_testSessions_testSessions_Invoice | null;
}

export interface TestSessionsWithoutMembers_testSessions_pagination {
  __typename: "Pagination";
  from: number;
  total: number;
  length: number;
}

export interface TestSessionsWithoutMembers_testSessions {
  __typename: "TestSessionsWithPagination";
  testSessions: TestSessionsWithoutMembers_testSessions_testSessions[];
  pagination: TestSessionsWithoutMembers_testSessions_pagination;
}

export interface TestSessionsWithoutMembers {
  testSessions: TestSessionsWithoutMembers_testSessions;
}

export interface TestSessionsWithoutMembersVariables {
  filterData?: TestSessionFilterInput | null;
  groupsInputData?: TestSessionGroupsInput | null;
  companyInputData?: TestSessionCompanyInput | null;
  pagination: PaginationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: TestSessionWithoutMembersWithResult
// ====================================================

export interface TestSessionWithoutMembersWithResult_testSession_Invoice {
  __typename: "Invoice";
  invoiceId: string | null;
  invoiceDocNumber: string | null;
  invoiceDate: any | null;
  autoInvoice: boolean | null;
}

export interface TestSessionWithoutMembersWithResult_testSession_staffs_User {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
}

export interface TestSessionWithoutMembersWithResult_testSession_staffs {
  __typename: "TestSessionMember";
  id: string;
  User: TestSessionWithoutMembersWithResult_testSession_staffs_User;
}

export interface TestSessionWithoutMembersWithResult_testSession_RequestedByUser {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
}

export interface TestSessionWithoutMembersWithResult_testSession_Location {
  __typename: "Address";
  id: string;
  street: string;
  city: string;
  zip: string;
  country: string;
  county: string | null;
  complement: string | null;
  state: string | null;
  lat: number | null;
  lon: number | null;
}

export interface TestSessionWithoutMembersWithResult_testSession_Group {
  __typename: "Group";
  id: string;
  name: string;
  japaneseGroup: boolean;
}

export interface TestSessionWithoutMembersWithResult_testSession_Groups {
  __typename: "Group";
  id: string;
  name: string;
  japaneseGroup: boolean;
}

export interface TestSessionWithoutMembersWithResult_testSession_Company {
  __typename: "Company";
  id: string;
  name: string;
}

export interface TestSessionWithoutMembersWithResult_testSession_Tests_Members_User {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
}

export interface TestSessionWithoutMembersWithResult_testSession_Tests_Members_Group {
  __typename: "Group";
  id: string;
  name: string;
}

export interface TestSessionWithoutMembersWithResult_testSession_Tests_Members {
  __typename: "TestSessionMember";
  User: TestSessionWithoutMembersWithResult_testSession_Tests_Members_User;
  Group: TestSessionWithoutMembersWithResult_testSession_Tests_Members_Group | null;
  isParticipant: boolean;
  isStaff: boolean;
}

export interface TestSessionWithoutMembersWithResult_testSession_Tests_TestSession {
  __typename: "TestSession";
  lab: Lab | null;
}

export interface TestSessionWithoutMembersWithResult_testSession_Tests {
  __typename: "Test";
  id: string;
  createdAt: any;
  updatedAt: any;
  barcode: string | null;
  testedAt: any;
  result: TestResult | null;
  secondResult: TestResult | null;
  resultReceivedAt: any | null;
  resultProvisionedAt: any | null;
  specimenType: SpecimenTypes | null;
  Members: TestSessionWithoutMembersWithResult_testSession_Tests_Members[];
  TestSession: TestSessionWithoutMembersWithResult_testSession_Tests_TestSession;
}

export interface TestSessionWithoutMembersWithResult_testSession_Products_Product {
  __typename: "Product";
  name: string;
}

export interface TestSessionWithoutMembersWithResult_testSession_Products {
  __typename: "TestSessionProduct";
  price: number;
  quantity: number;
  totalPrice: number;
  Product: TestSessionWithoutMembersWithResult_testSession_Products_Product;
}

export interface TestSessionWithoutMembersWithResult_testSession_PretestSessionSurveys_User {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
}

export interface TestSessionWithoutMembersWithResult_testSession_PretestSessionSurveys_PretestSessionSurveyResponses {
  __typename: "PretestSessionSurveyResponse";
  id: string;
  key: string;
  value: string;
}

export interface TestSessionWithoutMembersWithResult_testSession_PretestSessionSurveys {
  __typename: "PretestSessionSurvey";
  id: string;
  User: TestSessionWithoutMembersWithResult_testSession_PretestSessionSurveys_User;
  PretestSessionSurveyResponses: TestSessionWithoutMembersWithResult_testSession_PretestSessionSurveys_PretestSessionSurveyResponses[];
}

export interface TestSessionWithoutMembersWithResult_testSession_TestValues {
  __typename: "EventTestValues";
  specimenType: string | null;
  rapidLotNumber: string | null;
  rapidCartridgeExpirationDate: any | null;
  rapidDockId: string | null;
  rapidSpecimenNumber: string | null;
  rapidExpirationDate: any | null;
}

export interface TestSessionWithoutMembersWithResult_testSession {
  __typename: "TestSession";
  id: string;
  name: string | null;
  type: TestType;
  category: CategoryType;
  date: any;
  endsAt: any | null;
  isScheduled: boolean;
  finishedAt: any | null;
  rejectedAt: any | null;
  Invoice: TestSessionWithoutMembersWithResult_testSession_Invoice | null;
  sublocation: string;
  staffs: TestSessionWithoutMembersWithResult_testSession_staffs[];
  RequestedByUser: TestSessionWithoutMembersWithResult_testSession_RequestedByUser | null;
  Location: TestSessionWithoutMembersWithResult_testSession_Location;
  Group: TestSessionWithoutMembersWithResult_testSession_Group | null;
  Groups: TestSessionWithoutMembersWithResult_testSession_Groups[];
  Company: TestSessionWithoutMembersWithResult_testSession_Company | null;
  Tests: TestSessionWithoutMembersWithResult_testSession_Tests[];
  Products: TestSessionWithoutMembersWithResult_testSession_Products[];
  PretestSessionSurveys: TestSessionWithoutMembersWithResult_testSession_PretestSessionSurveys[];
  paymentStatus: TestSessionPaymentStatus | null;
  lab: Lab | null;
  estimatedMembers: number | null;
  totalPrice: number | null;
  paymentError: string | null;
  TestValues: TestSessionWithoutMembersWithResult_testSession_TestValues | null;
}

export interface TestSessionWithoutMembersWithResult {
  testSession: TestSessionWithoutMembersWithResult_testSession | null;
}

export interface TestSessionWithoutMembersWithResultVariables {
  id: string;
  groupsInputData?: TestSessionGroupsInput | null;
  companyInputData?: TestSessionCompanyInput | null;
  skipResult: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ToggleOverwriteHIPAA
// ====================================================

export interface ToggleOverwriteHIPAA {
  toggleOverwriteAcceptHipaa: boolean;
}

export interface ToggleOverwriteHIPAAVariables {
  groupId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ToggleUserMarketing
// ====================================================

export interface ToggleUserMarketing {
  toggleUserMarketing: boolean | null;
}

export interface ToggleUserMarketingVariables {
  inputData: ToggleUserMarketingInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ToggleUserNotifications
// ====================================================

export interface ToggleUserNotifications {
  toggleUserNotification: boolean | null;
}

export interface ToggleUserNotificationsVariables {
  inputData: ToggleUserNotificationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CheckEmail
// ====================================================

export interface CheckEmail {
  checkEmail: boolean;
}

export interface CheckEmailVariables {
  email: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetClearanceStatus
// ====================================================

export interface GetClearanceStatus_getClearanceStatus_users {
  __typename: "UsersClearanceStatus";
  id: string;
  firstName: string | null;
  lastName: string | null;
  userId: string;
  clearanceId: string | null;
  name: string | null;
  groupId: string;
  testCheckedStatus: TestsStatusType | null;
  questionnaireStatus: ClearanceStatusType | null;
  vaccinationStatus: VaccineStatusType | null;
  travelStatus: ClearanceStatusType | null;
  exposureStatus: ClearanceStatusType | null;
  clearanceCheckStatus: ClearanceStatusType | null;
  dateOfClearance: any | null;
  entranceStatus: ClearanceStatusType | null;
  entranceResponsible: string | null;
  entranceObs: string | null;
}

export interface GetClearanceStatus_getClearanceStatus_pagination {
  __typename: "Pagination";
  from: number;
  length: number;
  total: number;
}

export interface GetClearanceStatus_getClearanceStatus {
  __typename: "ClearanceStatusWithPagination";
  users: GetClearanceStatus_getClearanceStatus_users[] | null;
  pagination: GetClearanceStatus_getClearanceStatus_pagination;
}

export interface GetClearanceStatus {
  getClearanceStatus: GetClearanceStatus_getClearanceStatus;
}

export interface GetClearanceStatusVariables {
  filterData: ClearanceStatusFilterInput;
  pagination: PaginationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetClearanceStatusByUser
// ====================================================

export interface GetClearanceStatusByUser_getClearanceStatusByUser {
  __typename: "UsersClearanceStatus";
  id: string;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  userId: string;
  clearanceId: string | null;
  name: string | null;
  groupId: string;
  companyName: string | null;
  testCheckedStatus: TestsStatusType | null;
  questionnaireStatus: ClearanceStatusType | null;
  vaccinationStatus: VaccineStatusType | null;
  travelStatus: ClearanceStatusType | null;
  exposureStatus: ClearanceStatusType | null;
  clearanceCheckStatus: ClearanceStatusType | null;
  dateOfClearance: any | null;
  entranceStatus: ClearanceStatusType | null;
  entranceResponsible: string | null;
  entranceObs: string | null;
}

export interface GetClearanceStatusByUser {
  getClearanceStatusByUser: GetClearanceStatusByUser_getClearanceStatusByUser | null;
}

export interface GetClearanceStatusByUserVariables {
  filterData: ClearanceStatusByUserFilterInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: DownloadClearanceStatus
// ====================================================

export interface DownloadClearanceStatus_getClearanceStatus_users_Group {
  __typename: "Group";
  name: string;
}

export interface DownloadClearanceStatus_getClearanceStatus_users {
  __typename: "UsersClearanceStatus";
  id: string;
  firstName: string | null;
  lastName: string | null;
  Group: DownloadClearanceStatus_getClearanceStatus_users_Group | null;
  userId: string;
  clearanceId: string | null;
  testCheckedStatus: TestsStatusType | null;
  questionnaireStatus: ClearanceStatusType | null;
  vaccinationStatus: VaccineStatusType | null;
  clearanceCheckStatus: ClearanceStatusType | null;
  dateOfClearance: any | null;
  entranceStatus: ClearanceStatusType | null;
  entranceResponsible: string | null;
}

export interface DownloadClearanceStatus_getClearanceStatus_pagination {
  __typename: "Pagination";
  from: number;
  length: number;
  total: number;
}

export interface DownloadClearanceStatus_getClearanceStatus {
  __typename: "ClearanceStatusWithPagination";
  users: DownloadClearanceStatus_getClearanceStatus_users[] | null;
  pagination: DownloadClearanceStatus_getClearanceStatus_pagination;
}

export interface DownloadClearanceStatus {
  getClearanceStatus: DownloadClearanceStatus_getClearanceStatus;
}

export interface DownloadClearanceStatusVariables {
  filterData: ClearanceStatusFilterInput;
  pagination: PaginationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetByEmail
// ====================================================

export interface GetByEmail_getByEmail_Address {
  __typename: "Address";
  city: string;
  country: string;
  county: string | null;
  complement: string | null;
  state: string | null;
  lat: number | null;
  lon: number | null;
  street: string;
  zip: string;
}

export interface GetByEmail_getByEmail_Groups {
  __typename: "Group";
  id: string;
  name: string;
}

export interface GetByEmail_getByEmail_ParentUser {
  __typename: "User";
  firstName: string | null;
  lastName: string | null;
}

export interface GetByEmail_getByEmail_ClearanceStatuses {
  __typename: "UsersClearanceStatus";
  id: string;
  clearanceId: string | null;
}

export interface GetByEmail_getByEmail_UserRoles {
  __typename: "UserRoles";
  id: string | null;
  userId: string | null;
  role: UserRole | null;
}

export interface GetByEmail_getByEmail_Insurance {
  __typename: "Insurance";
  id: string;
  govId: string | null;
  govIdChecked: boolean | null;
  companyId: string | null;
  insuranceCompany: string | null;
  plan: string | null;
  cardID: string | null;
  subscriberFirstName: string | null;
  subscriberMiddleName: string | null;
  subscriberLastName: string | null;
  subscriberDOB: any | null;
  subscriberGender: Gender | null;
  relationToSubscriber: string | null;
  insuranceCardFrontChecked: boolean | null;
  insuranceCardFront: string | null;
  insuranceCardFrontMessageDenied: string | null;
  insuranceCardBackChecked: boolean | null;
  insuranceCardBack: string | null;
  insuranceCardBackMessageDenied: string | null;
  govIdMessageDenied: string | null;
  SSN: string | null;
  driversLicense: string | null;
  groupNumber: string | null;
  eligibilityStatus: string | null;
  eligibilityErrorMessage: string | null;
  manualEligibilityCheckUserId: string | null;
  lastEligibilityCheck: any | null;
  isApproved: boolean | null;
}

export interface GetByEmail_getByEmail {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  gender: Gender | null;
  ethnicity: Ethnicity | null;
  race: Race | null;
  email: string | null;
  phoneNumber: string | null;
  birthDate: any | null;
  lastTestedAt: any | null;
  role: UserRole | null;
  isCompliant: boolean;
  updatedPasswordAt: any | null;
  Address: GetByEmail_getByEmail_Address | null;
  cdcCard: string | null;
  photoID: string | null;
  optInMarketing: boolean;
  Groups: GetByEmail_getByEmail_Groups[];
  parentId: string | null;
  ParentUser: GetByEmail_getByEmail_ParentUser | null;
  ClearanceStatuses: GetByEmail_getByEmail_ClearanceStatuses[] | null;
  UserRoles: GetByEmail_getByEmail_UserRoles[];
  Insurance: GetByEmail_getByEmail_Insurance | null;
}

export interface GetByEmail {
  getByEmail: GetByEmail_getByEmail | null;
}

export interface GetByEmailVariables {
  email: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetInsuranceReviewForVerification
// ====================================================

export interface GetInsuranceReviewForVerification_getInsuranceReviewForVerification_insurances_User {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  birthDate: any | null;
}

export interface GetInsuranceReviewForVerification_getInsuranceReviewForVerification_insurances {
  __typename: "Insurance";
  id: string;
  userId: string;
  User: GetInsuranceReviewForVerification_getInsuranceReviewForVerification_insurances_User;
}

export interface GetInsuranceReviewForVerification_getInsuranceReviewForVerification_pagination {
  __typename: "Pagination";
  from: number;
  length: number;
  total: number;
}

export interface GetInsuranceReviewForVerification_getInsuranceReviewForVerification {
  __typename: "InsuranceReviewWithPagination";
  insurances: GetInsuranceReviewForVerification_getInsuranceReviewForVerification_insurances[] | null;
  pagination: GetInsuranceReviewForVerification_getInsuranceReviewForVerification_pagination;
}

export interface GetInsuranceReviewForVerification {
  getInsuranceReviewForVerification: GetInsuranceReviewForVerification_getInsuranceReviewForVerification;
}

export interface GetInsuranceReviewForVerificationVariables {
  filterData?: InsuranceReviewFilterInput | null;
  pagination: PaginationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetProducts
// ====================================================

export interface GetProducts_products_products {
  __typename: "Product";
  id: string;
  name: string;
  type: ProductTypes | null;
  category: CategoryType;
}

export interface GetProducts_products {
  __typename: "ProductsWithPagination";
  products: GetProducts_products_products[];
}

export interface GetProducts {
  products: GetProducts_products;
}

export interface GetProductsVariables {
  filterData?: ProductsFilterInput | null;
  pagination: PaginationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetUserByToken
// ====================================================

export interface GetUserByToken_userByToken_Address {
  __typename: "AddressFromToken";
  state: string | null;
  country: string;
  county: string | null;
  complement: string | null;
  street: string;
  city: string;
  zip: string;
}

export interface GetUserByToken_userByToken_PendingGroupInvites_Company {
  __typename: "CompanyFromToken";
  id: string;
  name: string;
}

export interface GetUserByToken_userByToken_PendingGroupInvites_Documents {
  __typename: "GroupDocument";
  id: string;
  groupId: string;
  name: string;
  url: string;
}

export interface GetUserByToken_userByToken_PendingGroupInvites {
  __typename: "GroupFromToken";
  id: string;
  name: string;
  Company: GetUserByToken_userByToken_PendingGroupInvites_Company | null;
  Documents: GetUserByToken_userByToken_PendingGroupInvites_Documents[];
}

export interface GetUserByToken_userByToken_UserRoles {
  __typename: "UserRoles";
  id: string | null;
  userId: string | null;
  role: UserRole | null;
}

export interface GetUserByToken_userByToken {
  __typename: "UserFromToken";
  id: string;
  createdAt: any;
  updatedAt: any;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  birthDate: any | null;
  Address: GetUserByToken_userByToken_Address | null;
  gender: Gender | null;
  ethnicity: Ethnicity | null;
  race: Race | null;
  status: string | null;
  role: UserRole | null;
  phoneNumber: string | null;
  optInMarketing: boolean | null;
  PendingGroupInvites: GetUserByToken_userByToken_PendingGroupInvites[];
  UserRoles: GetUserByToken_userByToken_UserRoles[];
}

export interface GetUserByToken {
  userByToken: GetUserByToken_userByToken | null;
}

export interface GetUserByTokenVariables {
  token: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: MeCards
// ====================================================

export interface MeCards_me_User_Cards {
  __typename: "UserCreditCard";
  stripeId: string;
  last4: string;
  expiryYear: string;
  expiryMonth: string;
}

export interface MeCards_me_User {
  __typename: "User";
  id: string;
  Cards: (MeCards_me_User_Cards | null)[];
}

export interface MeCards_me {
  __typename: "AuthSession";
  userId: string;
  User: MeCards_me_User;
}

export interface MeCards {
  me: MeCards_me | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: UserDeleted
// ====================================================

export interface UserDeleted {
  userDeleted: boolean;
}

export interface UserDeletedVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: UserLogs
// ====================================================

export interface UserLogs_getUserLogs_userLogs_User {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  birthDate: any | null;
  email: string | null;
}

export interface UserLogs_getUserLogs_userLogs_Company {
  __typename: "Company";
  id: string;
  name: string;
}

export interface UserLogs_getUserLogs_userLogs_Group_Company {
  __typename: "Company";
  name: string;
}

export interface UserLogs_getUserLogs_userLogs_Group {
  __typename: "Group";
  id: string;
  name: string;
  companyId: string | null;
  Company: UserLogs_getUserLogs_userLogs_Group_Company | null;
}

export interface UserLogs_getUserLogs_userLogs {
  __typename: "UserLogs";
  id: string;
  userId: string;
  User: UserLogs_getUserLogs_userLogs_User;
  table: string;
  tableId: string;
  action: string;
  values: any;
  companyId: string | null;
  Company: UserLogs_getUserLogs_userLogs_Company | null;
  groupId: string | null;
  Group: UserLogs_getUserLogs_userLogs_Group | null;
  createdAt: any;
}

export interface UserLogs_getUserLogs_pagination {
  __typename: "Pagination";
  from: number;
  length: number;
  total: number;
}

export interface UserLogs_getUserLogs {
  __typename: "UserLogsWithPagination";
  userLogs: UserLogs_getUserLogs_userLogs[] | null;
  pagination: UserLogs_getUserLogs_pagination;
}

export interface UserLogs {
  getUserLogs: UserLogs_getUserLogs;
}

export interface UserLogsVariables {
  filterData?: UserLogsFilterInput | null;
  pagination: PaginationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SendJapaneseEmail
// ====================================================

export interface SendJapaneseEmail {
  sendJapaneseEmail: boolean;
}

export interface SendJapaneseEmailVariables {
  inputData: JapaneseEmailInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SetApproveStatus
// ====================================================

export interface SetApproveStatus {
  setApproveStatus: boolean;
}

export interface SetApproveStatusVariables {
  userID: string;
  isApproved: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: TestSessionMember
// ====================================================

export interface TestSessionMember_testSessionMember_Test_TestSession {
  __typename: "TestSession";
  lab: Lab | null;
}

export interface TestSessionMember_testSessionMember_Test {
  __typename: "Test";
  id: string;
  result: TestResult | null;
  secondResult: TestResult | null;
  testedAt: any;
  TestSession: TestSessionMember_testSessionMember_Test_TestSession;
}

export interface TestSessionMember_testSessionMember_User_Address {
  __typename: "Address";
  id: string;
  street: string;
  city: string;
  zip: string;
  state: string | null;
  country: string;
  county: string | null;
  complement: string | null;
  lat: number | null;
  lon: number | null;
}

export interface TestSessionMember_testSessionMember_User_Insurance {
  __typename: "Insurance";
  id: string;
  insuranceCompany: string | null;
  companyId: string | null;
  groupNumber: string | null;
  subscriberFirstName: string | null;
  subscriberMiddleName: string | null;
  subscriberLastName: string | null;
  subscriberGender: Gender | null;
  subscriberDOB: any | null;
  insuranceCardFrontChecked: boolean | null;
  insuranceCardFront: string | null;
  insuranceCardFrontMessageDenied: string | null;
  insuranceCardBackChecked: boolean | null;
  insuranceCardBack: string | null;
  insuranceCardBackMessageDenied: string | null;
  govIdMessageDenied: string | null;
  SSN: string | null;
  driversLicense: string | null;
  govId: string | null;
  govIdChecked: boolean | null;
  relationToSubscriber: string | null;
  cardID: string | null;
}

export interface TestSessionMember_testSessionMember_User {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  isCompliant: boolean;
  birthDate: any | null;
  phoneNumber: string | null;
  gender: Gender | null;
  ethnicity: Ethnicity | null;
  race: Race | null;
  Address: TestSessionMember_testSessionMember_User_Address | null;
  Insurance: TestSessionMember_testSessionMember_User_Insurance | null;
}

export interface TestSessionMember_testSessionMember {
  __typename: "TestSessionOneMember";
  id: string;
  isParticipant: boolean;
  isStaff: boolean;
  Test: TestSessionMember_testSessionMember_Test | null;
  User: TestSessionMember_testSessionMember_User;
}

export interface TestSessionMember {
  testSessionMember: TestSessionMember_testSessionMember | null;
}

export interface TestSessionMemberVariables {
  id: string;
  userId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: User
// ====================================================

export interface User_user_Address {
  __typename: "Address";
  city: string;
  country: string;
  county: string | null;
  complement: string | null;
  state: string | null;
  lat: number | null;
  lon: number | null;
  street: string;
  zip: string;
}

export interface User_user_Groups {
  __typename: "Group";
  id: string;
  name: string;
}

export interface User_user_ParentUser {
  __typename: "User";
  firstName: string | null;
  lastName: string | null;
}

export interface User_user_ClearanceStatuses {
  __typename: "UsersClearanceStatus";
  id: string;
  clearanceId: string | null;
}

export interface User_user_UserRoles {
  __typename: "UserRoles";
  id: string | null;
  userId: string | null;
  role: UserRole | null;
}

export interface User_user {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  gender: Gender | null;
  ethnicity: Ethnicity | null;
  race: Race | null;
  email: string | null;
  phoneNumber: string | null;
  birthDate: any | null;
  lastTestedAt: any | null;
  role: UserRole | null;
  isCompliant: boolean;
  updatedPasswordAt: any | null;
  Address: User_user_Address | null;
  cdcCard: string | null;
  photoID: string | null;
  optInMarketing: boolean;
  Groups: User_user_Groups[];
  parentId: string | null;
  ParentUser: User_user_ParentUser | null;
  ClearanceStatuses: User_user_ClearanceStatuses[] | null;
  UserRoles: User_user_UserRoles[];
}

export interface User {
  user: User_user | null;
}

export interface UserVariables {
  inputData: UserInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AllUsers
// ====================================================

export interface AllUsers_users_users {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  parentId: string | null;
  birthDate: any | null;
  lastTestedAt: any | null;
  city: string | null;
  state: string | null;
}

export interface AllUsers_users_pagination {
  __typename: "Pagination";
  from: number;
  length: number;
  total: number;
}

export interface AllUsers_users {
  __typename: "UsersWithPagination";
  users: AllUsers_users_users[];
  pagination: AllUsers_users_pagination;
}

export interface AllUsers {
  users: AllUsers_users;
}

export interface AllUsersVariables {
  filterData?: UsersFilterInput | null;
  pagination: PaginationInput;
  verification?: VerificationDataInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AllUsersLazy
// ====================================================

export interface AllUsersLazy_users_users {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  parentId: string | null;
  birthDate: any | null;
  lastTestedAt: any | null;
  city: string | null;
  state: string | null;
}

export interface AllUsersLazy_users_pagination {
  __typename: "Pagination";
  from: number;
  length: number;
  total: number;
}

export interface AllUsersLazy_users {
  __typename: "UsersWithPagination";
  users: AllUsersLazy_users_users[];
  pagination: AllUsersLazy_users_pagination;
}

export interface AllUsersLazy {
  users: AllUsersLazy_users;
}

export interface AllUsersLazyVariables {
  filterData?: UsersFilterInput | null;
  pagination: PaginationInput;
  verification?: VerificationDataInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetVaccinationByUser
// ====================================================

export interface GetVaccinationByUser_getVaccinationByUser_ResponsibleForApproval {
  __typename: "User";
  firstName: string | null;
  lastName: string | null;
}

export interface GetVaccinationByUser_getVaccinationByUser_VaccinationRecords {
  __typename: "VaccinationRecord";
  id: string;
  dateOfVaccine: any;
  manufacturer: string | null;
}

export interface GetVaccinationByUser_getVaccinationByUser {
  __typename: "Vaccination";
  id: string;
  cdcCard: string | null;
  secondCdcCard: string | null;
  observation: string | null;
  isApproved: boolean;
  dateOfApproval: any | null;
  responsibleForApproval: string | null;
  ResponsibleForApproval: GetVaccinationByUser_getVaccinationByUser_ResponsibleForApproval | null;
  VaccinationRecords: GetVaccinationByUser_getVaccinationByUser_VaccinationRecords[] | null;
}

export interface GetVaccinationByUser {
  getVaccinationByUser: GetVaccinationByUser_getVaccinationByUser | null;
}

export interface GetVaccinationByUserVariables {
  userId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetVaccinationForVerification
// ====================================================

export interface GetVaccinationForVerification_getVaccinationForVerification_vaccinations_User {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  birthDate: any | null;
}

export interface GetVaccinationForVerification_getVaccinationForVerification_vaccinations {
  __typename: "Vaccination";
  id: string;
  userId: string;
  User: GetVaccinationForVerification_getVaccinationForVerification_vaccinations_User;
}

export interface GetVaccinationForVerification_getVaccinationForVerification_pagination {
  __typename: "Pagination";
  from: number;
  length: number;
  total: number;
}

export interface GetVaccinationForVerification_getVaccinationForVerification {
  __typename: "VaccinationWithPagination";
  vaccinations: GetVaccinationForVerification_getVaccinationForVerification_vaccinations[] | null;
  pagination: GetVaccinationForVerification_getVaccinationForVerification_pagination;
}

export interface GetVaccinationForVerification {
  getVaccinationForVerification: GetVaccinationForVerification_getVaccinationForVerification;
}

export interface GetVaccinationForVerificationVariables {
  filterData?: VaccinationFilterInput | null;
  pagination: PaginationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ValidateTestLocationAddress
// ====================================================

export interface ValidateTestLocationAddress_validateTestLocation {
  __typename: "TestLocation";
  id: string;
}

export interface ValidateTestLocationAddress {
  validateTestLocation: ValidateTestLocationAddress_validateTestLocation;
}

export interface ValidateTestLocationAddressVariables {
  Address: AddressInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: TestsResultsAnalyticsClientLazy
// ====================================================

export interface TestsResultsAnalyticsClientLazy_testsResultsAnalyticsClient_tests_User {
  __typename: "AnalyticsUser";
  id: string;
  firstName: string | null;
  lastName: string | null;
  birthDate: any | null;
  email: string | null;
}

export interface TestsResultsAnalyticsClientLazy_testsResultsAnalyticsClient_tests_TestSession_Company {
  __typename: "AnalyticsCompany";
  id: string | null;
}

export interface TestsResultsAnalyticsClientLazy_testsResultsAnalyticsClient_tests_TestSession {
  __typename: "AnalyticsSession";
  date: any | null;
  name: string | null;
  lab: Lab | null;
  type: TestType | null;
  paymentStatus: TestSessionPaymentStatus | null;
  Company: TestsResultsAnalyticsClientLazy_testsResultsAnalyticsClient_tests_TestSession_Company | null;
}

export interface TestsResultsAnalyticsClientLazy_testsResultsAnalyticsClient_tests_Insurance {
  __typename: "Insurance";
  insuranceCompany: string | null;
}

export interface TestsResultsAnalyticsClientLazy_testsResultsAnalyticsClient_tests {
  __typename: "AnalyticsTest";
  id: string;
  barcode: string | null;
  testedAt: any;
  result: TestResult | null;
  secondResult: TestResult | null;
  resultProvisionedAt: any | null;
  specimenType: SpecimenTypes | null;
  User: TestsResultsAnalyticsClientLazy_testsResultsAnalyticsClient_tests_User;
  TestSession: TestsResultsAnalyticsClientLazy_testsResultsAnalyticsClient_tests_TestSession;
  Insurance: TestsResultsAnalyticsClientLazy_testsResultsAnalyticsClient_tests_Insurance | null;
  eventId: string | null;
  eventpaymentMethod: EventPaymentMethod | null;
}

export interface TestsResultsAnalyticsClientLazy_testsResultsAnalyticsClient_pagination {
  __typename: "Pagination";
  from: number;
  length: number;
  total: number;
}

export interface TestsResultsAnalyticsClientLazy_testsResultsAnalyticsClient {
  __typename: "TestsResultsAnalyticsClient";
  tests: TestsResultsAnalyticsClientLazy_testsResultsAnalyticsClient_tests[];
  pagination: TestsResultsAnalyticsClientLazy_testsResultsAnalyticsClient_pagination;
}

export interface TestsResultsAnalyticsClientLazy {
  testsResultsAnalyticsClient: TestsResultsAnalyticsClientLazy_testsResultsAnalyticsClient;
}

export interface TestsResultsAnalyticsClientLazyVariables {
  filterData?: TestsResultsAnalyticsClientInput | null;
  pagination: PaginationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetPatients
// ====================================================

export interface GetPatients_users_users_Address {
  __typename: "Address";
  country: string;
  county: string | null;
  complement: string | null;
  city: string;
  street: string;
  state: string | null;
  zip: string;
}

export interface GetPatients_users_users {
  __typename: "User";
  id: string;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  birthDate: any | null;
  gender: Gender | null;
  ethnicity: Ethnicity | null;
  Address: GetPatients_users_users_Address | null;
}

export interface GetPatients_users_pagination {
  __typename: "Pagination";
  from: number;
  total: number;
}

export interface GetPatients_users {
  __typename: "UsersWithPagination";
  users: GetPatients_users_users[];
  pagination: GetPatients_users_pagination;
}

export interface GetPatients {
  users: GetPatients_users;
}

export interface GetPatientsVariables {
  filterData?: UsersFilterInput | null;
  pagination: PaginationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ExtractInsuranceInformation
// ====================================================

export interface ExtractInsuranceInformation_extractInsuranceInformation {
  __typename: "PartialInsuranceData";
  insuranceCompany: string | null;
  plan: string | null;
  group: string | null;
  id: string | null;
  firstName: string | null;
  lastName: string | null;
  govId: string | null;
}

export interface ExtractInsuranceInformation {
  extractInsuranceInformation: ExtractInsuranceInformation_extractInsuranceInformation;
}

export interface ExtractInsuranceInformationVariables {
  insuranceUrl: string;
  documentUrl: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AddUserPretestSurveyResponses
// ====================================================

export interface AddUserPretestSurveyResponses_addPretestSessionSurvey_Members_User {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
}

export interface AddUserPretestSurveyResponses_addPretestSessionSurvey_Members {
  __typename: "TestSessionMember";
  User: AddUserPretestSurveyResponses_addPretestSessionSurvey_Members_User;
}

export interface AddUserPretestSurveyResponses_addPretestSessionSurvey {
  __typename: "TestSession";
  id: string;
  Members: AddUserPretestSurveyResponses_addPretestSessionSurvey_Members[];
}

export interface AddUserPretestSurveyResponses {
  addPretestSessionSurvey: AddUserPretestSurveyResponses_addPretestSessionSurvey | null;
}

export interface AddUserPretestSurveyResponsesVariables {
  inputData: PretestSessionSurveyInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: InviteNewPatient
// ====================================================

export interface InviteNewPatient_inviteUser {
  __typename: "InvitedUser";
  id: string;
  email: string | null;
}

export interface InviteNewPatient {
  inviteUser: InviteNewPatient_inviteUser;
}

export interface InviteNewPatientVariables {
  inputData: InviteUserInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ExtractInsuranceInformationEvent
// ====================================================

export interface ExtractInsuranceInformationEvent_extractInsuranceInformation {
  __typename: "PartialInsuranceData";
  insuranceCompany: string | null;
  plan: string | null;
  group: string | null;
  id: string | null;
  firstName: string | null;
  lastName: string | null;
  govId: string | null;
}

export interface ExtractInsuranceInformationEvent {
  extractInsuranceInformation: ExtractInsuranceInformationEvent_extractInsuranceInformation;
}

export interface ExtractInsuranceInformationEventVariables {
  insuranceUrl: string;
  documentUrl: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AppUsageAnalyticsLazy
// ====================================================

export interface AppUsageAnalyticsLazy_appUsageAnalytics_surveys_SurveyResponses {
  __typename: "SurveyResponse";
  id: string;
  key: string;
  value: string;
}

export interface AppUsageAnalyticsLazy_appUsageAnalytics_surveys_User_Address {
  __typename: "Address";
  city: string;
  country: string;
  county: string | null;
  complement: string | null;
  state: string | null;
  lat: number | null;
  lon: number | null;
  street: string;
  zip: string;
}

export interface AppUsageAnalyticsLazy_appUsageAnalytics_surveys_User_Groups {
  __typename: "Group";
  id: string;
  name: string;
}

export interface AppUsageAnalyticsLazy_appUsageAnalytics_surveys_User_Companies {
  __typename: "Company";
  id: string;
  name: string;
}

export interface AppUsageAnalyticsLazy_appUsageAnalytics_surveys_User {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  birthDate: any | null;
  email: string | null;
  phoneNumber: string | null;
  gender: Gender | null;
  ethnicity: Ethnicity | null;
  race: Race | null;
  Address: AppUsageAnalyticsLazy_appUsageAnalytics_surveys_User_Address | null;
  Groups: AppUsageAnalyticsLazy_appUsageAnalytics_surveys_User_Groups[];
  Companies: AppUsageAnalyticsLazy_appUsageAnalytics_surveys_User_Companies[];
}

export interface AppUsageAnalyticsLazy_appUsageAnalytics_surveys {
  __typename: "Survey";
  id: string;
  createdAt: any;
  status: SurveyStatus | null;
  statusReason: string[] | null;
  SurveyResponses: AppUsageAnalyticsLazy_appUsageAnalytics_surveys_SurveyResponses[];
  User: AppUsageAnalyticsLazy_appUsageAnalytics_surveys_User;
}

export interface AppUsageAnalyticsLazy_appUsageAnalytics_pagination {
  __typename: "Pagination";
  from: number;
  length: number;
  total: number;
}

export interface AppUsageAnalyticsLazy_appUsageAnalytics {
  __typename: "AppUsageAnalytics";
  surveys: AppUsageAnalyticsLazy_appUsageAnalytics_surveys[];
  pagination: AppUsageAnalyticsLazy_appUsageAnalytics_pagination;
}

export interface AppUsageAnalyticsLazy {
  appUsageAnalytics: AppUsageAnalyticsLazy_appUsageAnalytics;
}

export interface AppUsageAnalyticsLazyVariables {
  filterData?: AppUsageAnalyticsInput | null;
  pagination: PaginationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: InsuranceTestsResultsAnalyticsLazy
// ====================================================

export interface InsuranceTestsResultsAnalyticsLazy_insuranceTestsResultsAnalytics_tests_User {
  __typename: "AnalyticsUser";
  id: string;
  firstName: string | null;
  lastName: string | null;
  birthDate: any | null;
  email: string | null;
}

export interface InsuranceTestsResultsAnalyticsLazy_insuranceTestsResultsAnalytics_tests_TestSession_Company {
  __typename: "AnalyticsCompany";
  id: string | null;
  name: string | null;
}

export interface InsuranceTestsResultsAnalyticsLazy_insuranceTestsResultsAnalytics_tests_TestSession {
  __typename: "AnalyticsSession";
  id: string;
  date: any | null;
  name: string | null;
  lab: Lab | null;
  type: TestType | null;
  paymentStatus: TestSessionPaymentStatus | null;
  finishedAt: any | null;
  invoiceId: string | null;
  invoiceDocNumber: string | null;
  Company: InsuranceTestsResultsAnalyticsLazy_insuranceTestsResultsAnalytics_tests_TestSession_Company | null;
  zip: string | null;
}

export interface InsuranceTestsResultsAnalyticsLazy_insuranceTestsResultsAnalytics_tests_Insurance {
  __typename: "Insurance";
  id: string;
  insuranceCompany: string | null;
  eligibilityStatus: string | null;
  eligibilityErrorMessage: string | null;
}

export interface InsuranceTestsResultsAnalyticsLazy_insuranceTestsResultsAnalytics_tests_Group {
  __typename: "Group";
  name: string;
}

export interface InsuranceTestsResultsAnalyticsLazy_insuranceTestsResultsAnalytics_tests_Company {
  __typename: "Company";
  name: string;
}

export interface InsuranceTestsResultsAnalyticsLazy_insuranceTestsResultsAnalytics_tests {
  __typename: "InsuranceAnalyticsTest";
  id: string;
  barcode: string | null;
  testedAt: any;
  result: TestResult | null;
  secondResult: TestResult | null;
  resultProvisionedAt: any | null;
  specimenType: SpecimenTypes | null;
  insuranceStatusWhenAdded: string | null;
  insuranceUpdatedAt: any | null;
  User: InsuranceTestsResultsAnalyticsLazy_insuranceTestsResultsAnalytics_tests_User;
  TestSession: InsuranceTestsResultsAnalyticsLazy_insuranceTestsResultsAnalytics_tests_TestSession;
  eventId: string | null;
  eventpaymentMethod: EventPaymentMethod | null;
  insuranceStatus: string | null;
  Insurance: InsuranceTestsResultsAnalyticsLazy_insuranceTestsResultsAnalytics_tests_Insurance | null;
  Group: InsuranceTestsResultsAnalyticsLazy_insuranceTestsResultsAnalytics_tests_Group | null;
  Company: InsuranceTestsResultsAnalyticsLazy_insuranceTestsResultsAnalytics_tests_Company | null;
}

export interface InsuranceTestsResultsAnalyticsLazy_insuranceTestsResultsAnalytics_pagination {
  __typename: "Pagination";
  from: number;
  length: number;
  total: number;
}

export interface InsuranceTestsResultsAnalyticsLazy_insuranceTestsResultsAnalytics {
  __typename: "InsuranceTestsResultsAnalytics";
  tests: InsuranceTestsResultsAnalyticsLazy_insuranceTestsResultsAnalytics_tests[];
  pagination: InsuranceTestsResultsAnalyticsLazy_insuranceTestsResultsAnalytics_pagination;
}

export interface InsuranceTestsResultsAnalyticsLazy {
  insuranceTestsResultsAnalytics: InsuranceTestsResultsAnalyticsLazy_insuranceTestsResultsAnalytics;
}

export interface InsuranceTestsResultsAnalyticsLazyVariables {
  filterData?: InsuranceTestsResultsAnalyticsInput | null;
  pagination: PaginationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: MarketingAnalyticsLazy
// ====================================================

export interface MarketingAnalyticsLazy_marketingAnalytics_users_Address {
  __typename: "Address";
  city: string;
  state: string | null;
}

export interface MarketingAnalyticsLazy_marketingAnalytics_users {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  birthDate: any | null;
  email: string | null;
  gender: Gender | null;
  ethnicity: Ethnicity | null;
  race: Race | null;
  Address: MarketingAnalyticsLazy_marketingAnalytics_users_Address | null;
}

export interface MarketingAnalyticsLazy_marketingAnalytics_pagination {
  __typename: "Pagination";
  from: number;
  length: number;
  total: number;
}

export interface MarketingAnalyticsLazy_marketingAnalytics {
  __typename: "MarketingAnalytics";
  users: MarketingAnalyticsLazy_marketingAnalytics_users[];
  pagination: MarketingAnalyticsLazy_marketingAnalytics_pagination;
}

export interface MarketingAnalyticsLazy {
  marketingAnalytics: MarketingAnalyticsLazy_marketingAnalytics;
}

export interface MarketingAnalyticsLazyVariables {
  filterData?: MarketingAnalyticsInput | null;
  pagination: PaginationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: TestsResultsAnalyticsLazy
// ====================================================

export interface TestsResultsAnalyticsLazy_testsResultsAnalytics_tests_User {
  __typename: "AnalyticsUser";
  id: string;
  firstName: string | null;
  lastName: string | null;
  birthDate: any | null;
  email: string | null;
}

export interface TestsResultsAnalyticsLazy_testsResultsAnalytics_tests_TestSession_Company {
  __typename: "AnalyticsCompany";
  id: string | null;
  name: string | null;
}

export interface TestsResultsAnalyticsLazy_testsResultsAnalytics_tests_TestSession {
  __typename: "AnalyticsSession";
  id: string;
  date: any | null;
  name: string | null;
  lab: Lab | null;
  type: TestType | null;
  paymentStatus: TestSessionPaymentStatus | null;
  finishedAt: any | null;
  invoiceId: string | null;
  invoiceDocNumber: string | null;
  Company: TestsResultsAnalyticsLazy_testsResultsAnalytics_tests_TestSession_Company | null;
  zip: string | null;
}

export interface TestsResultsAnalyticsLazy_testsResultsAnalytics_tests_Insurance {
  __typename: "Insurance";
  id: string;
  insuranceCompany: string | null;
}

export interface TestsResultsAnalyticsLazy_testsResultsAnalytics_tests_Group {
  __typename: "Group";
  name: string;
}

export interface TestsResultsAnalyticsLazy_testsResultsAnalytics_tests_Company {
  __typename: "Company";
  name: string;
}

export interface TestsResultsAnalyticsLazy_testsResultsAnalytics_tests {
  __typename: "AnalyticsTest";
  id: string;
  barcode: string | null;
  testedAt: any;
  result: TestResult | null;
  secondResult: TestResult | null;
  resultProvisionedAt: any | null;
  specimenType: SpecimenTypes | null;
  User: TestsResultsAnalyticsLazy_testsResultsAnalytics_tests_User;
  TestSession: TestsResultsAnalyticsLazy_testsResultsAnalytics_tests_TestSession;
  eventId: string | null;
  eventpaymentMethod: EventPaymentMethod | null;
  Insurance: TestsResultsAnalyticsLazy_testsResultsAnalytics_tests_Insurance | null;
  Group: TestsResultsAnalyticsLazy_testsResultsAnalytics_tests_Group | null;
  Company: TestsResultsAnalyticsLazy_testsResultsAnalytics_tests_Company | null;
}

export interface TestsResultsAnalyticsLazy_testsResultsAnalytics_pagination {
  __typename: "Pagination";
  from: number;
  length: number;
  total: number;
}

export interface TestsResultsAnalyticsLazy_testsResultsAnalytics {
  __typename: "TestsResultsAnalytics";
  tests: TestsResultsAnalyticsLazy_testsResultsAnalytics_tests[];
  pagination: TestsResultsAnalyticsLazy_testsResultsAnalytics_pagination;
}

export interface TestsResultsAnalyticsLazy {
  testsResultsAnalytics: TestsResultsAnalyticsLazy_testsResultsAnalytics;
}

export interface TestsResultsAnalyticsLazyVariables {
  filterData?: TestsResultsAnalyticsInput | null;
  pagination: PaginationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetCompanies
// ====================================================

export interface GetCompanies_companies_companies_Address {
  __typename: "Address";
  street: string;
  city: string;
}

export interface GetCompanies_companies_companies_Groups {
  __typename: "Group";
  id: string;
  name: string;
}

export interface GetCompanies_companies_companies_Members_pagination {
  __typename: "Pagination";
  total: number;
}

export interface GetCompanies_companies_companies_Members {
  __typename: "CompanyMembersWithPagination";
  pagination: GetCompanies_companies_companies_Members_pagination;
}

export interface GetCompanies_companies_companies {
  __typename: "Company";
  id: string;
  name: string;
  Address: GetCompanies_companies_companies_Address;
  Groups: GetCompanies_companies_companies_Groups[];
  Members: GetCompanies_companies_companies_Members;
}

export interface GetCompanies_companies_pagination {
  __typename: "Pagination";
  from: number;
  length: number;
  total: number;
}

export interface GetCompanies_companies {
  __typename: "CompaniesWithPagination";
  companies: GetCompanies_companies_companies[];
  pagination: GetCompanies_companies_pagination;
}

export interface GetCompanies {
  companies: GetCompanies_companies;
}

export interface GetCompaniesVariables {
  filterData?: CompaniesFilterInput | null;
  pagination: PaginationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: EditCompany
// ====================================================

export interface EditCompany_editCompany {
  __typename: "Company";
  id: string;
  name: string;
  eventClearance: boolean;
}

export interface EditCompany {
  editCompany: EditCompany_editCompany | null;
}

export interface EditCompanyVariables {
  inputData: EditCompanyInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateCompany
// ====================================================

export interface CreateCompany_createCompany {
  __typename: "Company";
  id: string;
  name: string;
}

export interface CreateCompany {
  createCompany: CreateCompany_createCompany | null;
}

export interface CreateCompanyVariables {
  inputData: CreateCompanyInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: EditUserDetails
// ====================================================

export interface EditUserDetails_editUser_Address {
  __typename: "Address";
  city: string;
  country: string;
  county: string | null;
  complement: string | null;
  street: string;
  state: string | null;
  zip: string;
}

export interface EditUserDetails_editUser {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  gender: Gender | null;
  email: string | null;
  phoneNumber: string | null;
  birthDate: any | null;
  role: UserRole | null;
  Address: EditUserDetails_editUser_Address | null;
}

export interface EditUserDetails {
  editUser: EditUserDetails_editUser | null;
}

export interface EditUserDetailsVariables {
  inputData: EditUserInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ChangeUserRole
// ====================================================

export interface ChangeUserRole_changeUserRole {
  __typename: "User";
  id: string;
  email: string | null;
  role: UserRole | null;
}

export interface ChangeUserRole {
  changeUserRole: ChangeUserRole_changeUserRole | null;
}

export interface ChangeUserRoleVariables {
  inputData: ChangeUserRoleInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetTest
// ====================================================

export interface GetTest_test_Members_User {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  birthDate: any | null;
}

export interface GetTest_test_Members {
  __typename: "TestSessionMember";
  User: GetTest_test_Members_User;
  isParticipant: boolean;
  isStaff: boolean;
}

export interface GetTest_test_TestSession_Groups {
  __typename: "Group";
  id: string;
  name: string;
}

export interface GetTest_test_TestSession_Company {
  __typename: "Company";
  id: string;
  name: string;
}

export interface GetTest_test_TestSession_Location {
  __typename: "Address";
  id: string;
  street: string;
  city: string;
  zip: string;
  country: string;
  county: string | null;
  complement: string | null;
}

export interface GetTest_test_TestSession {
  __typename: "TestSession";
  id: string;
  Groups: GetTest_test_TestSession_Groups[];
  Company: GetTest_test_TestSession_Company | null;
  Location: GetTest_test_TestSession_Location;
  type: TestType;
  finishedAt: any | null;
}

export interface GetTest_test {
  __typename: "Test";
  id: string;
  testedAt: any;
  barcode: string | null;
  result: TestResult | null;
  secondResult: TestResult | null;
  rapidDockId: string | null;
  rapidLotNumber: string | null;
  rapidSpecimenNumber: string | null;
  rapidExpirationDate: any | null;
  rapidCartridgeExpirationDate: any | null;
  resultReceivedAt: any | null;
  resultProvisionedAt: any | null;
  specimenType: SpecimenTypes | null;
  Members: GetTest_test_Members[];
  TestSession: GetTest_test_TestSession;
}

export interface GetTest {
  test: GetTest_test | null;
}

export interface GetTestVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: EditTestResults
// ====================================================

export interface EditTestResults_editTestResults {
  __typename: "Test";
  id: string;
  result: TestResult | null;
  resultReceivedAt: any | null;
  testedAt: any;
  specimenType: SpecimenTypes | null;
}

export interface EditTestResults {
  editTestResults: EditTestResults_editTestResults[];
}

export interface EditTestResultsVariables {
  inputData: TestResultsInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ProvisionTestResult
// ====================================================

export interface ProvisionTestResult_provisionTestResult {
  __typename: "Test";
  id: string;
}

export interface ProvisionTestResult {
  provisionTestResult: ProvisionTestResult_provisionTestResult | null;
}

export interface ProvisionTestResultVariables {
  inputData: ProvisionTestResultInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetLastInsuranceTestDate
// ====================================================

export interface GetLastInsuranceTestDate_getLastInsuranceTestDate {
  __typename: "LastInsuranceTestDate";
  testDate: any | null;
  testId: string | null;
}

export interface GetLastInsuranceTestDate {
  getLastInsuranceTestDate: GetLastInsuranceTestDate_getLastInsuranceTestDate | null;
}

export interface GetLastInsuranceTestDateVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RequestTestSession
// ====================================================

export interface RequestTestSession_requestTestSession_Location {
  __typename: "Address";
  id: string;
  street: string;
  city: string;
  country: string;
  county: string | null;
  complement: string | null;
  state: string | null;
  zip: string;
}

export interface RequestTestSession_requestTestSession {
  __typename: "TestSession";
  id: string;
  date: any;
  type: TestType;
  Location: RequestTestSession_requestTestSession_Location;
}

export interface RequestTestSession {
  requestTestSession: RequestTestSession_requestTestSession | null;
}

export interface RequestTestSessionVariables {
  inputData: RequestTestSessionInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: TestSessionsLazy
// ====================================================

export interface TestSessionsLazy_testSessions_testSessions_Location {
  __typename: "Address";
  id: string;
  street: string;
  city: string;
}

export interface TestSessionsLazy_testSessions_testSessions_Members {
  __typename: "TestSessionMember";
  isParticipant: boolean;
  isResulted: boolean | null;
}

export interface TestSessionsLazy_testSessions_testSessions_Groups {
  __typename: "Group";
  id: string;
  name: string;
}

export interface TestSessionsLazy_testSessions_testSessions_Company {
  __typename: "Company";
  id: string;
  name: string;
}

export interface TestSessionsLazy_testSessions_testSessions_Invoice {
  __typename: "Invoice";
  invoiceId: string | null;
  invoiceDocNumber: string | null;
  invoiceDate: any | null;
  autoInvoice: boolean | null;
}

export interface TestSessionsLazy_testSessions_testSessions {
  __typename: "TestSession";
  id: string;
  date: any;
  name: string | null;
  lab: Lab | null;
  type: TestType;
  isScheduled: boolean;
  rejectedAt: any | null;
  finishedAt: any | null;
  Location: TestSessionsLazy_testSessions_testSessions_Location;
  Members: TestSessionsLazy_testSessions_testSessions_Members[];
  Groups: TestSessionsLazy_testSessions_testSessions_Groups[];
  Company: TestSessionsLazy_testSessions_testSessions_Company | null;
  paymentStatus: TestSessionPaymentStatus | null;
  Invoice: TestSessionsLazy_testSessions_testSessions_Invoice | null;
  sublocation: string;
}

export interface TestSessionsLazy_testSessions_pagination {
  __typename: "Pagination";
  from: number;
  total: number;
  length: number;
}

export interface TestSessionsLazy_testSessions {
  __typename: "TestSessionsWithPagination";
  testSessions: TestSessionsLazy_testSessions_testSessions[];
  pagination: TestSessionsLazy_testSessions_pagination;
}

export interface TestSessionsLazy {
  testSessions: TestSessionsLazy_testSessions;
}

export interface TestSessionsLazyVariables {
  filterData?: TestSessionFilterInput | null;
  groupsInputData?: TestSessionGroupsInput | null;
  companyInputData?: TestSessionCompanyInput | null;
  pagination: PaginationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: EditUser
// ====================================================

export interface EditUser_editUser_Address {
  __typename: "Address";
  city: string;
  country: string;
  county: string | null;
  complement: string | null;
  state: string | null;
  lat: number | null;
  lon: number | null;
  street: string;
  zip: string;
}

export interface EditUser_editUser {
  __typename: "User";
  id: string;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  phoneNumber: string | null;
  birthDate: any | null;
  gender: Gender | null;
  Address: EditUser_editUser_Address | null;
}

export interface EditUser {
  editUser: EditUser_editUser | null;
}

export interface EditUserVariables {
  inputData: EditUserInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Users
// ====================================================

export interface Users_users_users {
  __typename: "User";
  id: string;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  birthDate: any | null;
  lastTestedAt: any | null;
  isCompliant: boolean;
  cdcCard: string | null;
  photoID: string | null;
  role: UserRole | null;
  city: string | null;
  state: string | null;
}

export interface Users_users_pagination {
  __typename: "Pagination";
  from: number;
  length: number;
  total: number;
}

export interface Users_users {
  __typename: "UsersWithPagination";
  users: Users_users_users[];
  pagination: Users_users_pagination;
}

export interface Users {
  users: Users_users;
}

export interface UsersVariables {
  filterData?: UsersFilterInput | null;
  pagination: PaginationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: EditUserProfile
// ====================================================

export interface EditUserProfile_editUser_Address {
  __typename: "Address";
  city: string;
  country: string;
  county: string | null;
  complement: string | null;
  state: string | null;
  lat: number | null;
  lon: number | null;
  street: string;
  zip: string;
}

export interface EditUserProfile_editUser {
  __typename: "User";
  id: string;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  phoneNumber: string | null;
  birthDate: any | null;
  gender: Gender | null;
  Address: EditUserProfile_editUser_Address | null;
}

export interface EditUserProfile {
  editUser: EditUserProfile_editUser | null;
}

export interface EditUserProfileVariables {
  inputData: EditUserInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateNewGroup
// ====================================================

export interface CreateNewGroup_createGroup {
  __typename: "Group";
  id: string;
  name: string;
}

export interface CreateNewGroup {
  createGroup: CreateNewGroup_createGroup | null;
}

export interface CreateNewGroupVariables {
  inputData: EditGroupInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: RequestTestResultPdfUrl
// ====================================================

export interface RequestTestResultPdfUrl {
  requestTestResultPdfUrl: string | null;
}

export interface RequestTestResultPdfUrlVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetPDFTest
// ====================================================

export interface GetPDFTest_test_ParticipantMembers_User_Address {
  __typename: "Address";
  street: string;
  city: string;
  zip: string;
  country: string;
  state: string | null;
}

export interface GetPDFTest_test_ParticipantMembers_User {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  gender: Gender | null;
  birthDate: any | null;
  phoneNumber: string | null;
  email: string | null;
  Address: GetPDFTest_test_ParticipantMembers_User_Address | null;
}

export interface GetPDFTest_test_ParticipantMembers {
  __typename: "TestSessionMember";
  User: GetPDFTest_test_ParticipantMembers_User;
}

export interface GetPDFTest_test_TestSession {
  __typename: "TestSession";
  id: string;
  lab: Lab | null;
  type: TestType;
}

export interface GetPDFTest_test {
  __typename: "Test";
  id: string;
  testedAt: any;
  barcode: string | null;
  result: TestResult | null;
  secondResult: TestResult | null;
  resultReceivedAt: any | null;
  resultProvisionedAt: any | null;
  specimenType: SpecimenTypes | null;
  ParticipantMembers: GetPDFTest_test_ParticipantMembers[];
  TestSession: GetPDFTest_test_TestSession;
}

export interface GetPDFTest {
  test: GetPDFTest_test | null;
}

export interface GetPDFTestVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getSample
// ====================================================

export interface getSample_eventSample_EventAttendee_Address {
  __typename: "Address";
  id: string;
  street: string;
  city: string;
  zip: string;
  country: string;
  state: string | null;
}

export interface getSample_eventSample_EventAttendee {
  __typename: "EventAttendee";
  firstName: string;
  lastName: string;
  dateOfBirth: any;
  gender: Gender;
  email: string | null;
  phone: string | null;
  Address: getSample_eventSample_EventAttendee_Address | null;
  userId: string | null;
}

export interface getSample_eventSample {
  __typename: "EventSample";
  id: string;
  eventAttendeeId: string;
  EventAttendee: getSample_eventSample_EventAttendee;
  barcode: string | null;
  result: TestResult | null;
  secondResult: TestResult | null;
  testedAt: any | null;
  resultProvisionedAt: any | null;
  sampleStatus: SampleStatus | null;
  rapidLotNumber: string | null;
  rapidCartridgeExpirationDate: any | null;
  rapidDockId: string | null;
  rapidSpecimenNumber: string | null;
  rapidExpirationDate: any | null;
  specimenType: string | null;
  testedById: string;
}

export interface getSample {
  eventSample: getSample_eventSample | null;
}

export interface getSampleVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: HistoryTests
// ====================================================

export interface HistoryTests_tests_tests_TestSession {
  __typename: "TestSession";
  id: string;
  type: TestType;
}

export interface HistoryTests_tests_tests_Members_User {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  parentId: string | null;
}

export interface HistoryTests_tests_tests_Members {
  __typename: "TestSessionMember";
  User: HistoryTests_tests_tests_Members_User;
  isParticipant: boolean;
  isStaff: boolean;
}

export interface HistoryTests_tests_tests {
  __typename: "Test";
  id: string;
  result: TestResult | null;
  secondResult: TestResult | null;
  testedAt: any;
  barcode: string | null;
  TestSession: HistoryTests_tests_tests_TestSession;
  Members: HistoryTests_tests_tests_Members[];
}

export interface HistoryTests_tests {
  __typename: "TestsWithPagination";
  tests: HistoryTests_tests_tests[];
}

export interface HistoryTests {
  tests: HistoryTests_tests;
}

export interface HistoryTestsVariables {
  filterData: TestFilterInput;
  pagination: PaginationInput;
  skipResult: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FullUser
// ====================================================

export interface FullUser_user_Insurance {
  __typename: "Insurance";
  insuranceCompany: string | null;
  plan: string | null;
  govIdChecked: boolean | null;
  insuranceCardBackChecked: boolean | null;
  insuranceCardFrontChecked: boolean | null;
}

export interface FullUser_user_Address {
  __typename: "Address";
  city: string;
  country: string;
  county: string | null;
  complement: string | null;
  state: string | null;
  lat: number | null;
  lon: number | null;
  street: string;
  zip: string;
}

export interface FullUser_user {
  __typename: "User";
  id: string;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  phoneNumber: string | null;
  birthDate: any | null;
  gender: Gender | null;
  ethnicity: Ethnicity | null;
  race: Race | null;
  Insurance: FullUser_user_Insurance | null;
  Address: FullUser_user_Address | null;
}

export interface FullUser {
  user: FullUser_user | null;
}

export interface FullUserVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: PendingGroupInvitesFragment
// ====================================================

export interface PendingGroupInvitesFragment_PendingGroupInvites_Company {
  __typename: "Company";
  id: string;
  name: string;
}

export interface PendingGroupInvitesFragment_PendingGroupInvites_Documents {
  __typename: "GroupDocument";
  id: string;
  groupId: string;
  name: string;
  url: string;
}

export interface PendingGroupInvitesFragment_PendingGroupInvites {
  __typename: "Group";
  id: string;
  name: string;
  Company: PendingGroupInvitesFragment_PendingGroupInvites_Company | null;
  Documents: PendingGroupInvitesFragment_PendingGroupInvites_Documents[];
}

export interface PendingGroupInvitesFragment {
  __typename: "User";
  PendingGroupInvites: PendingGroupInvitesFragment_PendingGroupInvites[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: SubpersonsPendingGroupInvitesFragment
// ====================================================

export interface SubpersonsPendingGroupInvitesFragment_SubpersonsPendingGroupInvites_GroupUsers_User {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  parentId: string | null;
}

export interface SubpersonsPendingGroupInvitesFragment_SubpersonsPendingGroupInvites_GroupUsers {
  __typename: "GroupMember";
  User: SubpersonsPendingGroupInvitesFragment_SubpersonsPendingGroupInvites_GroupUsers_User;
}

export interface SubpersonsPendingGroupInvitesFragment_SubpersonsPendingGroupInvites_Company {
  __typename: "Company";
  id: string;
  name: string;
}

export interface SubpersonsPendingGroupInvitesFragment_SubpersonsPendingGroupInvites_Documents {
  __typename: "GroupDocument";
  id: string;
  groupId: string;
  name: string;
  url: string;
}

export interface SubpersonsPendingGroupInvitesFragment_SubpersonsPendingGroupInvites {
  __typename: "Group";
  id: string;
  name: string;
  GroupUsers: (SubpersonsPendingGroupInvitesFragment_SubpersonsPendingGroupInvites_GroupUsers | null)[] | null;
  Company: SubpersonsPendingGroupInvitesFragment_SubpersonsPendingGroupInvites_Company | null;
  Documents: SubpersonsPendingGroupInvitesFragment_SubpersonsPendingGroupInvites_Documents[];
}

export interface SubpersonsPendingGroupInvitesFragment {
  __typename: "User";
  SubpersonsPendingGroupInvites: SubpersonsPendingGroupInvitesFragment_SubpersonsPendingGroupInvites[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum CategoryType {
  Covid19 = "Covid19",
  Influenza = "Influenza",
  RSV = "RSV",
  Strep = "Strep",
}

export enum ClearanceStatusType {
  Cleared = "Cleared",
  NotCleared = "NotCleared",
  NotSubmitted = "NotSubmitted",
  Warning = "Warning",
}

export enum CompanyMemberRole {
  Employee = "Employee",
  Manager = "Manager",
  Owner = "Owner",
}

export enum Ethnicity {
  DeclineToState = "DeclineToState",
  Hispanic = "Hispanic",
  NotHispanic = "NotHispanic",
}

export enum EventFrequency {
  Once = "Once",
  Weekly = "Weekly",
}

export enum EventPaymentMethod {
  CreditCard = "CreditCard",
  Insurance = "Insurance",
  Invoice = "Invoice",
}

export enum EventStatus {
  Active = "Active",
  All = "All",
  Finished = "Finished",
  Inactive = "Inactive",
  Waiting = "Waiting",
}

export enum Gender {
  DeclineToState = "DeclineToState",
  Female = "Female",
  Male = "Male",
  Other = "Other",
}

export enum GroupMemberRole {
  Coordinator = "Coordinator",
  Manager = "Manager",
  Member = "Member",
}

export enum Lab {
  AustinLab = "AustinLab",
  Bako = "Bako",
  ChicagoLab = "ChicagoLab",
  DendiAtl = "DendiAtl",
  DendiLax = "DendiLax",
  MiamiLab = "MiamiLab",
  ModmdAtl = "ModmdAtl",
  ModmdLab = "ModmdLab",
  ModmdLabFairfax = "ModmdLabFairfax",
  NYLab = "NYLab",
  Pathline = "Pathline",
  Phamatech = "Phamatech",
  SunriseLab = "SunriseLab",
}

export enum PaymentMethod {
  CreditCard = "CreditCard",
  Insurance = "Insurance",
  Invoice = "Invoice",
}

export enum ProductTypes {
  AcceavaStrep = "AcceavaStrep",
  Antigen = "Antigen",
  AntigenCareStart = "AntigenCareStart",
  BioSignFlu = "BioSignFlu",
  Covid19 = "Covid19",
  IndicaidAntigen = "IndicaidAntigen",
  Lucira = "Lucira",
  Rapid = "Rapid",
  SiennaAntigen = "SiennaAntigen",
  SofiaInfluenza = "SofiaInfluenza",
  SofiaRsv = "SofiaRsv",
  SofiaStrep = "SofiaStrep",
}

export enum Race {
  AmericanIndian = "AmericanIndian",
  Asian = "Asian",
  Black = "Black",
  DeclineToState = "DeclineToState",
  Hawaiian = "Hawaiian",
  Other = "Other",
  White = "White",
}

export enum SampleStatus {
  Collected = "Collected",
  Finished = "Finished",
  Running = "Running",
}

export enum SortDirectionEnum {
  ASC = "ASC",
  DESC = "DESC",
}

export enum SpecimenTypes {
  anteriorNasal = "anteriorNasal",
  midNasal = "midNasal",
  nasal = "nasal",
  nasopharyngeal = "nasopharyngeal",
  oral = "oral",
  oropharyngeal = "oropharyngeal",
  saliva = "saliva",
}

export enum SubLocation {
  Concierge = "Concierge",
  Lab = "Lab",
  OnSite = "OnSite",
  SelfSwab = "SelfSwab",
}

export enum SurveyStatus {
  Cleared = "Cleared",
  Flagged = "Flagged",
  NotCleared = "NotCleared",
}

export enum SurveyType {
  PretestSessionSurvey = "PretestSessionSurvey",
  Questionnaire = "Questionnaire",
}

export enum TestResult {
  Inconclusive = "Inconclusive",
  Invalid = "Invalid",
  Negative = "Negative",
  Positive = "Positive",
  Unsatisfactory = "Unsatisfactory",
}

export enum TestSessionPaymentStatus {
  Cancelled = "Cancelled",
  Failed = "Failed",
  Insurance = "Insurance",
  Invoiced = "Invoiced",
  OnHold = "OnHold",
  Paid = "Paid",
  Pending = "Pending",
}

export enum TestSessionStatus {
  Finished = "Finished",
  FinishedWithResults = "FinishedWithResults",
  PendingResults = "PendingResults",
  Rejected = "Rejected",
  Requested = "Requested",
  Scheduled = "Scheduled",
}

export enum TestType {
  AcceavaStrep = "AcceavaStrep",
  Antigen = "Antigen",
  AntigenCareStart = "AntigenCareStart",
  BioSignFlu = "BioSignFlu",
  Covid19 = "Covid19",
  IndicaidAntigen = "IndicaidAntigen",
  Lucira = "Lucira",
  RapidCovid19 = "RapidCovid19",
  SiennaAntigen = "SiennaAntigen",
  SofiaInfluenza = "SofiaInfluenza",
  SofiaRsv = "SofiaRsv",
  SofiaStrep = "SofiaStrep",
}

export enum TestsStatusType {
  Cleared = "Cleared",
  NotCleared = "NotCleared",
  NotSubmitted = "NotSubmitted",
  NotTested = "NotTested",
  PendingResult = "PendingResult",
  ReTestNeeded = "ReTestNeeded",
  Warning = "Warning",
}

export enum TypeFilter {
  Event = "Event",
  TestSession = "TestSession",
}

export enum UserNotificationType {
  AccountCreated = "AccountCreated",
  AccountInvitation = "AccountInvitation",
  EventReservation = "EventReservation",
  EventResult = "EventResult",
  FollowUpResults = "FollowUpResults",
  GroupInvitation = "GroupInvitation",
  JapaneseTestResult = "JapaneseTestResult",
  PositiveTestResult = "PositiveTestResult",
  ResendEmailQuestionnaire = "ResendEmailQuestionnaire",
  ResetPassword = "ResetPassword",
  ResultAvailable = "ResultAvailable",
  SendEmailInsurance = "SendEmailInsurance",
  TestSessionChange = "TestSessionChange",
  TestSessionInvitation = "TestSessionInvitation",
  TestSessionManagerResultsAvailable = "TestSessionManagerResultsAvailable",
  TestSessionRequestReceived = "TestSessionRequestReceived",
  TestSessionSchedulerNewBooking = "TestSessionSchedulerNewBooking",
  TestSessionStatusChanged = "TestSessionStatusChanged",
  UpcomingTesting = "UpcomingTesting",
  VaccineRecordStatusChanges = "VaccineRecordStatusChanges",
}

export enum UserRole {
  ADMIN = "ADMIN",
  LIAISON = "LIAISON",
  NURSE = "NURSE",
  PATIENT = "PATIENT",
  RESULTS = "RESULTS",
  SCHEDULER = "SCHEDULER",
  SUPER_ADMIN = "SUPER_ADMIN",
}

export enum UserTypeForFilter {
  CompanyOwner = "CompanyOwner",
  Patient = "Patient",
  Staff = "Staff",
}

export enum VaccineStatusType {
  Cleared = "Cleared",
  NotCleared = "NotCleared",
  NotSubmitted = "NotSubmitted",
  NotUploaded = "NotUploaded",
  PendingReview = "PendingReview",
  Warning = "Warning",
}

export enum WeekDays {
  Friday = "Friday",
  Monday = "Monday",
  Saturday = "Saturday",
  Sunday = "Sunday",
  Thursday = "Thursday",
  Tuesday = "Tuesday",
  Wednesday = "Wednesday",
}

export interface AddClearanceSetupInput {
  companyId: string;
  isQuestionnaireOn: boolean;
  isTravelRelevant?: boolean | null;
  isExposureOn?: boolean | null;
  isSymptomOn?: boolean | null;
  isTemperatureOn?: boolean | null;
  isVaccinationOn: boolean;
  isTestOn: boolean;
  amountOfDaysPositive?: number | null;
  amountOfDaysNegative?: number | null;
  amountOfDaysSinceLastPositive?: number | null;
}

export interface AddCompanyGroupMembersInput {
  groupId: string;
  companyId: string;
  groupMemberRole?: GroupMemberRole | null;
  companyMemberRole?: CompanyMemberRole | null;
  Members: AddCompanyGroupMembersUserInput[];
}

export interface AddCompanyGroupMembersUserInput {
  User: InviteUserInput;
  role: GroupMemberRole;
}

export interface AddCompanyMemberInput {
  userId: string;
  companyId: string;
  role: CompanyMemberRole;
}

export interface AddEventAttendeeInput {
  eventId: string;
  date?: any | null;
  timeSlot: string;
  firstName: string;
  lastName: string;
  dateOfBirth: any;
  gender: Gender;
  ethnicity: Ethnicity;
  race: Race;
  email?: string | null;
  phone?: string | null;
  Address: AddressInput;
  userId?: string | null;
  paymentId?: string | null;
  cardID?: string | null;
  insuranceCompany?: string | null;
  isApproved?: boolean | null;
}

export interface AddInsuranceCompanyInput {
  name: string;
  pVerifyId: string;
  isApproved: boolean;
  daysBetweenTests: number;
}

export interface AddInsuranceInput {
  userId: string;
  govId?: string | null;
  govIdChecked?: boolean | null;
  govIdMessageDenied?: string | null;
  insuranceCardFrontChecked?: boolean | null;
  insuranceCardFront?: string | null;
  insuranceCardFrontMessageDenied?: string | null;
  insuranceCardBackChecked?: boolean | null;
  insuranceCardBack?: string | null;
  insuranceCardBackMessageDenied?: string | null;
  SSN?: string | null;
  driversLicense?: string | null;
  companyId?: string | null;
  insuranceCompany?: string | null;
  plan?: string | null;
  cardID?: string | null;
  groupNumber?: string | null;
  subscriberFirstName?: string | null;
  subscriberMiddleName?: string | null;
  subscriberLastName?: string | null;
  subscriberDOB?: any | null;
  subscriberGender?: Gender | null;
  relationToSubscriber?: string | null;
  isApproved?: boolean | null;
  rejectReason?: string | null;
}

export interface AddInsuranceNewUserInput {
  userId?: string | null;
  govId?: string | null;
  govIdChecked?: boolean | null;
  govIdMessageDenied?: string | null;
  insuranceCardFrontChecked?: boolean | null;
  insuranceCardFront?: string | null;
  insuranceCardFrontMessageDenied?: string | null;
  insuranceCardBackChecked?: boolean | null;
  insuranceCardBack?: string | null;
  insuranceCardBackMessageDenied?: string | null;
  SSN?: string | null;
  driversLicense?: string | null;
  companyId?: string | null;
  insuranceCompany?: string | null;
  plan?: string | null;
  cardID?: string | null;
  groupNumber?: string | null;
  subscriber?: string | null;
  subscriberFirstName?: string | null;
  subscriberMiddleName?: string | null;
  subscriberLastName?: string | null;
  subscriberDOB?: any | null;
  subscriberGender?: Gender | null;
  relationToSubscriber?: string | null;
  isApproved?: boolean | null;
}

export interface AddTestLocationInput {
  name: string;
  coordinatesBounds: CoordinateInput[];
  state: string;
  isDefaultPrice: boolean;
}

export interface AddVaccinationInput {
  userId: string;
  cdcCard: string;
  secondCdcCard?: string | null;
  govId: string;
}

export interface AddVaccinationRecordInput {
  dateOfVaccine: any;
  manufacturer: string;
  vaccinationId: string;
  userId: string;
}

export interface AddressInput {
  id?: string | null;
  street: string;
  city: string;
  zip: string;
  country: string;
  complement?: string | null;
  state?: string | null;
  county?: string | null;
  lat?: number | null;
  lon?: number | null;
}

export interface AllEventAttendeesInput {
  id?: string | null;
  emailOrNameLike?: string | null;
  hasResult?: boolean | null;
  result?: TestResult | null;
  location?: string | null;
  testedBetween?: (any | null)[] | null;
  barcode?: string | null;
  type?: TestType | null;
  companyOrGroupLike?: string | null;
  paymentMethod?: PaymentMethod | null;
  qboInvoiceStatus?: string | null;
  insuranceCompanyLike?: string | null;
}

export interface AppUsageAnalyticsInput {
  answeredBetween?: (any | null)[] | null;
  companyIds?: string[] | null;
  groupIds?: string[] | null;
  status?: SurveyStatus | null;
}

export interface ApproveClearanceStatusInput {
  clearanceId: string;
  entranceResponsible: string;
  groupId: string;
}

export interface ApproveGroupClearanceStatusInput {
  clearancesIds: (string | null)[];
  entranceResponsible: string;
  groupId: string;
}

export interface ApprovePaymentInput {
  id: string;
  field: string;
}

export interface ApproveTestSessionInput {
  id: string;
}

export interface ApproveVaccinationInput {
  userId: string;
  isApproved: boolean;
  responsibleForApproval: string;
}

export interface ChangePasswordInput {
  oldPassword: string;
  newPassword: string;
}

export interface ChangeUserRoleInput {
  id: string;
  roles: UserRole[];
}

export interface CheckOldPasswordInput {
  oldPassword: string;
}

export interface CheckUserEligibilityEventInput {
  attendeeId: string;
}

export interface CheckUserEligibilityInput {
  userId: string;
}

export interface ClearanceStatusByUserFilterInput {
  userId: string;
  groupId: string;
}

export interface ClearanceStatusFilterInput {
  createdAt?: any | null;
  nameOrClearanceIdLike?: string | null;
  groupIds: string[];
}

export interface ClearanceStatusInput {
  userId: string;
  groupId: string;
  createdAt?: any | null;
}

export interface CompaniesFilterInput {
  id?: string | null;
  nameOrGroupNameLike?: string | null;
  address?: string | null;
  User?: UsersFilterInput | null;
  memberRole?: CompanyMemberRole | null;
  memberRoles?: CompanyMemberRole[] | null;
}

export interface CompanyMemberInput {
  userId: string;
  role?: CompanyMemberRole | null;
}

export interface CompanyPriceInput {
  productId: string;
  companyId: string;
  groupId?: string | null;
  price: number;
  minQuantity?: number | null;
  sublocation: SubLocation;
}

export interface CompanyTestSessionFilterInput {
  id?: string | null;
  nameOrGroupNameLike?: string | null;
}

export interface ConfirmInsuranceEligibilityEventInput {
  eventAttendeeId: string;
  status: string;
}

export interface ConfirmInsuranceEligibilityInput {
  userId: string;
  status: string;
}

export interface CoordinateInput {
  lat: number;
  lon: number;
}

export interface CreateCompanyInput {
  name: string;
  Address: AddressInput;
  Members: CompanyMemberInput[];
  eventClearance?: boolean | null;
  insuranceOnly?: boolean | null;
  pcrOnly?: boolean | null;
  automaticallyApproved?: boolean | null;
}

export interface CustomerRequestTestSessionInput {
  testType: TestType;
  category: CategoryType;
  endsAt?: any | null;
  gmt: number;
  date: any;
  Address: AddressInput;
  Members: CustomerRequestTestSessionMemberInput[];
  lab: Lab;
  Products: CustomerRequestTestSessionProductInput[];
  coupon?: string | null;
  totalTestTypes: number;
  isInsurance?: boolean | null;
  insuranceFormNewUser?: AddInsuranceNewUserInput | null;
  testValues?: TestValuesInput | null;
}

export interface CustomerRequestTestSessionMemberInput {
  User: CustomerRequestTestSessionMemberUserInput;
  isMain: boolean;
  symptoms: string[];
  alreadyTested?: boolean | null;
  knownExposure?: boolean | null;
}

export interface CustomerRequestTestSessionMemberUserInput {
  id?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  phoneNumber?: string | null;
  birthDate?: any | null;
  gender?: Gender | null;
  ethnicity?: Ethnicity | null;
  race?: Race | null;
  optInMarketing?: boolean | null;
  Address?: AddressInput | null;
}

export interface CustomerRequestTestSessionProductInput {
  priceId: string;
  quantity: number;
}

export interface DefaultPriceInput {
  productId: string;
  locationId: string;
  price: number;
  minQuantity?: number | null;
  sublocation: SubLocation;
}

export interface DeleteVaccinationRecordInput {
  id: string;
  userId: string;
}

export interface DenyClearanceStatusInput {
  clearanceId: string;
  entranceResponsible: string;
  groupId: string;
}

export interface DenyGroupClearanceStatusInput {
  clearancesIds: (string | null)[];
  entranceResponsible: string;
  groupId: string;
}

export interface DenyPaymentInput {
  id: string;
  field: string;
  denyMessage: string;
}

export interface DenyVaccinationInput {
  userId: string;
  observation?: string | null;
}

export interface EditCompanyInput {
  id: string;
  name: string;
  Address?: AddressInput | null;
  eventClearance: boolean;
  insuranceOnly?: boolean | null;
  pcrOnly?: boolean | null;
  automaticallyApproved?: boolean | null;
}

export interface EditCompanyMemberInput {
  userId: string;
  companyId: string;
  role?: CompanyMemberRole | null;
}

export interface EditCompanyPriceInput {
  id: string;
  productId?: string | null;
  companyId?: string | null;
  groupId?: string | null;
  price?: number | null;
  minQuantity?: number | null;
  sublocation: SubLocation;
}

export interface EditDefaultPriceInput {
  id: string;
  productId: string;
  locationId: string;
  price?: number | null;
  minQuantity?: number | null;
  sublocation: SubLocation;
}

export interface EditEventAttendeeInput {
  id: string;
  eventId?: string | null;
  date?: any | null;
  timeSlot?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  dateOfBirth?: any | null;
  gender?: Gender | null;
  ethnicity?: Ethnicity | null;
  race?: Race | null;
  email?: string | null;
  phone?: string | null;
  Address?: AddressInput | null;
  userId?: string | null;
  paymentId?: string | null;
  isApproved?: boolean | null;
}

export interface EditEventInput {
  id: string;
  lab?: Lab | null;
  name?: string | null;
  description?: string | null;
  isDescriptionNotificated: boolean;
  testType?: TestType | null;
  testValues?: TestValuesInput | null;
  Address?: AddressInput | null;
  isAddressNotificated: boolean;
  frequency?: EventFrequency | null;
  daysArray?: WeekDays[] | null;
  finishedAt?: any | null;
  paymentMethod?: PaymentMethod | null;
  isActive?: boolean | null;
  eventDays?: EventDayInput[] | null;
  price?: number | null;
  groupId?: string | null;
  companyId?: string | null;
  sublocation?: SubLocation | null;
}

export interface EditEventPaymentInput {
  id: string;
  paymentError?: string | null;
}

export interface EditGroupInput {
  id?: string | null;
  name: string;
  companyId?: string | null;
}

export interface EditGroupMemberInput {
  userId: string;
  groupId: string;
  role?: GroupMemberRole | null;
}

export interface EditInsuranceCompanyInput {
  id: string;
  name?: string | null;
  pVerifyId?: string | null;
  isApproved?: boolean | null;
  daysBetweenTests?: number | null;
}

export interface EditResultEventSampleInput {
  eventSampleId: string;
  result: TestResult;
  secondResult?: TestResult | null;
}

export interface EditSurveyInput {
  status: SurveyStatus;
  statusReason?: string[] | null;
}

export interface EditSurveyResponseInput {
  id: string;
  value: string;
}

export interface EditSurveyResponsesInput {
  SurveyResponses: EditSurveyResponseInput[];
}

export interface EditTestLocationInput {
  id: string;
  name?: string | null;
  coordinatesBounds?: CoordinateInput[] | null;
  state?: string | null;
  deleted?: boolean | null;
  isDefaultPrice?: boolean | null;
}

export interface EditUserInput {
  id?: string | null;
  password?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  birthDate?: string | null;
  phoneNumber?: string | null;
  gender?: Gender | null;
  ethnicity?: Ethnicity | null;
  race?: Race | null;
  Address?: AddressInput | null;
  cdcCard?: string | null;
  photoID?: string | null;
  optInMarketing?: boolean | null;
  updatedPasswordAt?: any | null;
}

export interface EditVaccinationInput {
  userId: string;
  cdcCard?: string | null;
  secondCdcCard?: string | null;
  govId: string;
}

export interface EditVaccinationRecordInput {
  id: string;
  dateOfVaccine?: any | null;
  manufacturer?: string | null;
  userId: string;
}

export interface EventAttendeesInput {
  id?: string | null;
  emailOrNameLike?: string | null;
  barcode?: string | null;
  eventDayId?: string | null;
}

export interface EventDayInput {
  startTime: any;
  timeRange: number;
  amountOfTests?: number | null;
  id?: string | null;
}

export interface EventFilterInput {
  id?: string | null;
  name?: string | null;
  date?: any | null;
  companyId?: string | null;
  groupId?: string | null;
  companyOrGroup?: string | null;
  dateFrom?: any | null;
  dateTo?: any | null;
  status?: EventStatus | null;
  location?: string | null;
}

export interface EventInput {
  lab: Lab;
  name: string;
  description?: string | null;
  isDescriptionNotificated: boolean;
  testType: TestType;
  testValues?: TestValuesInput | null;
  Address?: AddressInput | null;
  isAddressNotificated: boolean;
  frequency: EventFrequency;
  daysArray?: WeekDays[] | null;
  paymentMethod?: PaymentMethod | null;
  isActive: boolean;
  eventDays?: EventDayInput[] | null;
  slug?: string | null;
  price?: number | null;
  groupId?: string | null;
  companyId?: string | null;
  sublocation?: SubLocation | null;
}

export interface EventPaymentInput {
  email?: string | null;
  fullName: string;
  coupon?: string | null;
  eventId: string;
}

export interface EventSampleInput {
  eventAttendeeId: string;
  barcode?: string | null;
  result?: TestResult | null;
  secondResult?: TestResult | null;
  resultProvisionedAt?: any | null;
  sampleStatus?: SampleStatus | null;
  rapidLotNumber?: string | null;
  rapidCartridgeExpirationDate?: any | null;
  rapidDockId?: string | null;
  rapidSpecimenNumber?: string | null;
  rapidExpirationDate?: any | null;
  specimenType?: SpecimenTypes | null;
  testedById: string;
  base64Pdf?: string | null;
}

export interface EventSamplesInput {
  hasResult?: boolean | null;
  emailOrNameLike?: string | null;
  result?: TestResult | null;
  secondResult?: TestResult | null;
  location?: string | null;
  testedBetween?: (any | null)[] | null;
  barcode?: string | null;
  type?: TestType | null;
}

export interface ExportTestSessionInput {
  id: string;
  base64Pdf: string[];
}

export interface FileInput {
  base64: string;
  fileName: string;
}

export interface FinishTestSessionInput {
  id: string;
}

export interface GetCompanyPriceInput {
  companyId?: string | null;
  groupId?: string | null;
}

export interface GetDefaultPriceInput {
  productId?: string | null;
  locationId?: string | null;
}

export interface GetLogFilesInput {
  date: any;
  lab?: Lab | null;
  gmt: number;
}

export interface GroupTestSessionFilterInput {
  id?: string | null;
  nameLike?: string | null;
}

export interface GroupsFilterInput {
  id?: string | null;
  nameLike?: string | null;
  companyIds?: string[] | null;
  memberUserIds?: string[] | null;
  memberUserGroupRoles?: GroupMemberRole[] | null;
}

export interface InsuranceByUserInput {
  userId: string;
}

export interface InsuranceCompanyFilterInput {
  isApproved?: string | null;
  name?: string | null;
}

export interface InsuranceErrorFilterInput {
  checkedBetween?: (any | null)[] | null;
}

export interface InsuranceInput {
  id: string;
  govId?: string | null;
  insuranceCardBack?: string | null;
  insuranceCardFront?: string | null;
  insuranceCompany?: string | null;
  plan?: string | null;
  cardID?: string | null;
  groupNumber?: string | null;
  subscriberFirstName?: string | null;
  subscriberMiddleName?: string | null;
  subscriberLastName?: string | null;
  subscriberDOB?: any | null;
  subscriberGender?: Gender | null;
  relationToSubscriber?: string | null;
  SSN?: string | null;
  driversLicense?: string | null;
  isApproved?: boolean | null;
}

export interface InsuranceReviewFilterInput {
  emailOrNameLike?: string | null;
}

export interface InsuranceTestsResultsAnalyticsInput {
  testedBetween?: (any | null)[] | null;
  companyIds?: string[] | null;
  groupIds?: string[] | null;
  lab?: Lab | null;
  includeDenied?: boolean | null;
}

export interface InviteUserInput {
  id?: string | null;
  email: string;
  firstName?: string | null;
  lastName?: string | null;
  birthDate?: string | null;
  phoneNumber?: string | null;
  gender?: Gender | null;
  ethnicity?: Ethnicity | null;
  race?: Race | null;
  Address?: AddressInput | null;
  cdcCard?: string | null;
  photoID?: string | null;
  insurance?: AddInsuranceNewUserInput | null;
  attendeeId?: string | null;
  eventPaymentMethod?: PaymentMethod | null;
  roles: UserRole[];
  password?: string | null;
  recaptchaToken?: string | null;
  groupId?: string | null;
  parentId?: string | null;
}

export interface InvoiceDetailsInput {
  dateFrom?: any | null;
  dateTo?: any | null;
}

export interface InvoicesInput {
  invoicedBetween?: (any | null)[] | null;
  typeFilter?: TypeFilter | null;
}

export interface JapaneseEmailInput {
  email: string;
  files: FileInput[];
}

export interface LogInInput {
  email: string;
  password: string;
}

export interface MarketingAnalyticsInput {
  user?: MarketingUserFilterInput | null;
}

export interface MarketingUserFilterInput {
  emailOrNameLike?: string | null;
}

export interface PaginationInput {
  from: number;
  length: number;
  sortBy?: string | null;
  direction?: SortDirectionEnum | null;
}

export interface PaymentInput {
  email?: string | null;
  eventId: string;
  price?: number | null;
  govId?: string | null;
  govIdChecked?: boolean | null;
  insuranceCardBack?: string | null;
  insuranceCardBackChecked?: boolean | null;
  insuranceCardFront?: string | null;
  insuranceCardFrontChecked?: boolean | null;
  govIdMessageDenied?: string | null;
  insuranceCardBackMessageDenied?: string | null;
  insuranceCardFrontMessageDenied?: string | null;
  isApproved?: boolean | null;
  insuranceCompany?: string | null;
  plan?: string | null;
  SSN?: string | null;
  driversLicense?: string | null;
  cardID?: string | null;
  groupNumber?: string | null;
  subscriberFirstName?: string | null;
  subscriberMiddleName?: string | null;
  subscriberLastName?: string | null;
  subscriberDOB?: any | null;
  subscriberGender?: Gender | null;
  relationToSubscriber?: string | null;
}

export interface PretestSessionSurveyInput {
  userId: string;
  testSessionId: string;
  PretestSessionSurveyResponses: PretestSessionSurveyResponseInput[];
}

export interface PretestSessionSurveyResponseInput {
  key: string;
  value: string;
}

export interface PricesFilterInput {
  locationId?: string | null;
}

export interface ProductsFilterInput {
  locationId?: string | null;
  sublocation?: SubLocation | null;
}

export interface ProvisionTestResultInput {
  id?: string | null;
}

export interface RejectTestSessionInput {
  id: string;
}

export interface RemoveCompanyMemberInput {
  userId: string;
  companyId: string;
}

export interface RemoveGroupMemberInput {
  groupId: string;
  userId: string;
}

export interface RemoveTestSessionMemberInput {
  id: string;
}

export interface RequestGroupTestSessionInput {
  date?: any | null;
  endsAt?: any | null;
  type?: TestType | null;
  members?: TestSessionMemberInput[] | null;
  excludedMembersIds?: string[] | null;
  location: AddressInput;
  groupId: string;
  name?: string | null;
  estimatedMembers?: number | null;
  isAllSelected?: boolean | null;
  isInsurance?: boolean | null;
  gmt: number;
  sublocation: SubLocation;
}

export interface RequestPasswordInput {
  email: string;
}

export interface RequestTestSessionInput {
  date?: any | null;
  endsAt?: any | null;
  type?: TestType | null;
  members: TestSessionMemberInput[];
  location: AddressInput;
  groupId?: string | null;
  companyId?: string | null;
  name?: string | null;
  estimatedMembers?: number | null;
  onlyInsurance?: boolean | null;
  sublocation: SubLocation;
  testValues?: TestValuesInput | null;
}

export interface ResendEmailQuestionnaireInput {
  userId: string;
  groupId: string;
  createdAt?: any | null;
}

export interface ResetPasswordInput {
  token: string;
  newPassword: string;
}

export interface RunGroupClearanceInput {
  groupId: string;
  createdAt?: any | null;
  usersIds?: string[] | null;
}

export interface SendEmailInsuranceInput {
  userId: string;
}

export interface SetAntigenTypeTestSessionInput {
  testSessionId: string;
  type?: TestType | null;
}

export interface SetJapaneseGroupInput {
  groupId: string;
  value: boolean;
}

export interface SetTestSessionLabInput {
  testSessionId: string;
  lab?: Lab | null;
}

export interface SubpersonInput {
  id?: string | null;
  userId?: string | null;
  birthDate?: any | null;
  firstName?: string | null;
  lastName?: string | null;
  phoneNumber?: string | null;
  ethnicity?: Ethnicity | null;
  gender?: Gender | null;
  race?: Race | null;
  Address?: AddressInput | null;
}

export interface SurveyInput {
  userId: string;
  SurveyResponses: SurveyResponseInput[];
  groupId?: string | null;
}

export interface SurveyResponseInput {
  key: string;
  value: string;
}

export interface SurveysFilterInput {
  dateBetween?: (any | null)[] | null;
  userIds?: string[] | null;
  testSessionIds?: string[] | null;
  types?: SurveyType[] | null;
}

export interface TestFilterInput {
  hasResult?: boolean | null;
  isProvisioned?: boolean | null;
  companyOrGroupNameLike?: string | null;
  user?: TestUserFilterInput | null;
  userIds?: string[] | null;
  result?: TestResult | null;
  secondResult?: TestResult | null;
  rapidDockId?: string | null;
  rapidLotNumber?: string | null;
  rapidSpecimenNumber?: string | null;
  rapidExpirationDate?: any | null;
  rapidCartridgeExpirationDate?: any | null;
  location?: string | null;
  barcode?: string | null;
  testedBetween?: (any | null)[] | null;
  resultProvisionedBetween?: (any | null)[] | null;
  testSessionId?: string | null;
  companyIds?: string[] | null;
  groupIds?: string[] | null;
  lab?: Lab | null;
  type?: TestType | null;
}

export interface TestInput {
  testSessionId: string;
  barcode?: string | null;
  result?: TestResult | null;
  secondResult?: TestResult | null;
  rapidDockId?: string | null;
  rapidLotNumber?: string | null;
  rapidSpecimenNumber?: string | null;
  rapidExpirationDate?: any | null;
  rapidCartridgeExpirationDate?: any | null;
  resultReceivedAt?: any | null;
  memberIds: string[];
  specimenType?: SpecimenTypes | null;
}

export interface TestResultsInput {
  batchTestSessionId?: string | null;
  result?: TestResult | null;
  secondResult?: TestResult | null;
  rapidDockId?: string | null;
  rapidLotNumber?: string | null;
  rapidSpecimenNumber?: string | null;
  rapidExpirationDate?: any | null;
  rapidCartridgeExpirationDate?: any | null;
  testIds?: string[] | null;
  resultReceivedAt?: any | null;
  testedAt?: any | null;
  specimenType?: SpecimenTypes | null;
}

export interface TestSessionCompanyInput {
  memberUserIds?: string[] | null;
}

export interface TestSessionDetailsInput {
  id: string;
  date: any;
  endsAt?: any | null;
  type: TestType;
  isScheduled: boolean;
  Location: AddressInput;
  name?: string | null;
  testValues?: TestValuesInput | null;
}

export interface TestSessionFilterInput {
  dateBetween?: (any | null)[] | null;
  type?: TestType | null;
  location?: string | null;
  user?: UserTestSessionFilterInput | null;
  companyOrGroupNameLike?: string | null;
  company?: CompanyTestSessionFilterInput | null;
  group?: GroupTestSessionFilterInput | null;
  groupIds?: string[] | null;
  companyIds?: string[] | null;
  lab?: Lab | null;
  statuses?: TestSessionStatus[] | null;
  paymentStatus?: TestSessionPaymentStatus | null;
  invoicedBetween?: (any | null)[] | null;
}

export interface TestSessionGroupsInput {
  groupIds?: string[] | null;
  memberUserIds?: string[] | null;
}

export interface TestSessionInvoiceDateInput {
  id: string;
}

export interface TestSessionMemberInput {
  userId: string;
  isParticipant: boolean;
  isStaff: boolean;
}

export interface TestSessionMemberTestFilterInput {
  onlyPermitted?: boolean | null;
}

export interface TestSessionMembersFilterInput {
  userIds?: string[] | null;
  groupIds?: string[] | null;
  staffOnly?: boolean | null;
  participantOnly?: boolean | null;
  hasTest?: boolean | null;
  emailOrNameLike?: string | null;
  numOfResulted?: boolean | null;
}

export interface TestSessionMembersFromGroupInput {
  groupId?: string | null;
  includedIds: string[];
  excludedIds: string[];
  isAllSelected: boolean;
}

export interface TestSessionMembersInput {
  userIds: string[];
  groupId?: string | null;
  isParticipant: boolean;
  isStaff: boolean;
}

export interface TestSessionMembersQuickReportInput {
  groupIds?: string[] | null;
}

export interface TestSessionTestsInput {
  userIds?: string[] | null;
  groupIds?: string[] | null;
  companyIds?: string[] | null;
  onlyPermitted?: boolean | null;
  staffOnly?: boolean | null;
  participantOnly?: boolean | null;
}

export interface TestUserFilterInput {
  id?: string | null;
  emailOrNameLike?: string | null;
  isParticipant?: boolean | null;
  isStaff?: boolean | null;
}

export interface TestValuesInput {
  specimenType?: string | null;
  rapidLotNumber?: string | null;
  rapidCartridgeExpirationDate?: any | null;
  rapidDockId?: string | null;
  rapidSpecimenNumber?: string | null;
  rapidExpirationDate?: any | null;
}

export interface TestsCountAnalyticsInput {
  testedBetween: (any | null)[];
  state?: string | null;
  resultProvisionedBetween?: (any | null)[] | null;
  typeFilter?: TypeFilter | null;
}

export interface TestsResultsAnalyticsClientInput {
  testedBetween?: (any | null)[] | null;
  type?: TestType | null;
  groupIds?: string[] | null;
  companyIds?: string[] | null;
  result?: TestResult | null;
  hasResult?: boolean | null;
  typeFilter?: string | null;
  paymentStatus?: string | null;
  insuranceCompany?: string | null;
  patientName?: string | null;
}

export interface TestsResultsAnalyticsInput {
  testedBetween?: (any | null)[] | null;
  companyIds?: string[] | null;
  groupIds?: string[] | null;
  lab?: Lab | null;
  hasResult?: boolean | null;
  result?: TestResult | null;
  location?: string | null;
  type?: TestType | null;
  barcode?: string | null;
  user?: TestUserFilterInput | null;
  paymentStatus?: TestSessionPaymentStatus | null;
  paymentMethod?: string | null;
  typeFilter?: TypeFilter | null;
  insuranceCompany?: string | null;
}

export interface ToggleUserMarketingInput {
  userId: string;
  marketing: boolean;
}

export interface ToggleUserNotificationInput {
  notifications: UserNotificationInput[];
}

export interface UserInput {
  id: string;
  groupId?: string | null;
}

export interface UserLogsFilterInput {
  emailOrNameLike?: string | null;
  table?: string | null;
  idLike?: string | null;
  companyGroupLike?: string | null;
  dateFrom?: any | null;
  dateTo?: any | null;
}

export interface UserNotificationInput {
  type: UserNotificationType;
  isSubscribed: boolean;
}

export interface UserTestSessionFilterInput {
  id?: string | null;
  isParticipant?: boolean | null;
  isStaff?: boolean | null;
  emailOrNameLike?: string | null;
}

export interface UsersFilterInput {
  id?: string | null;
  type?: UserTypeForFilter | null;
  emailOrNameLike?: string | null;
  parentEmailOrNameLike?: string | null;
  onlySubpersons?: boolean | null;
  role?: UserRole | null;
  birthDateBetween?: any[] | null;
  Company?: CompaniesFilterInput | null;
  Group?: GroupsFilterInput | null;
  groupIds?: string[] | null;
  isDeleted?: boolean | null;
  testDateBetween?: any[] | null;
  showSubpersons?: boolean | null;
  includeCity?: boolean | null;
}

export interface VaccinationFilterInput {
  emailOrNameLike?: string | null;
}

export interface VerificationDataInput {
  companyIds?: string[] | null;
  groupIds?: string[] | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
