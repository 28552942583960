import React from 'react'
import { useMutation } from '@apollo/client'
import { EditTestResults, EditTestResultsVariables, SpecimenTypes } from '@modmd/data'
import Button from 'components/Button'
import Card from 'components/Card'
import { Dialog } from 'components/Dialog'
import { SelectInput } from 'components/SelectInput'
import { pxToRem } from 'theme'
import { Box } from 'components/Layout'
import { EDIT_TEST_RESULTS, GET_TEST } from './operations'

interface Props {
  isOpen: boolean
  testId: string
  specimenType?: SpecimenTypes | null
  onClose: () => void
}

export enum SpecimenList {
  midNasal = 'Mid-Nasal Swab',
  nasopharyngeal = 'Nasopharyngeal Swab',
  oral = 'Oral Swab',
  nasal = 'Nasal Swab',
  anteriorNasal = 'Anterior Nasal Swab',
  saliva = 'Saliva',
  oropharyngeal = 'Oropharyngeal Swab',
}

export const SPECIMEN_TYPES = [
  {
    value: SpecimenTypes.midNasal,
    label: 'Mid-Nasal Swab',
  },
  {
    value: SpecimenTypes.nasopharyngeal,
    label: 'Nasopharyngeal Swab',
  },
  {
    value: SpecimenTypes.oral,
    label: 'Oral Swab',
  },
  {
    value: SpecimenTypes.nasal,
    label: 'Nasal Swab',
  },
  {
    value: SpecimenTypes.anteriorNasal,
    label: 'Anterior Nasal Swab',
  },
  {
    value: SpecimenTypes.saliva,
    label: 'Saliva',
  },
  {
    value: SpecimenTypes.oropharyngeal,
    label: 'Oropharyngeal Swab',
  },
]

export const SpecimenType: React.VFC<Props> = ({ isOpen, testId, specimenType, onClose }) => {
  const [newSpecimenType, setSpecimenType] = React.useState(specimenType)
  const [editTestResults, editTestResultsData] = useMutation<
    EditTestResults,
    EditTestResultsVariables
  >(EDIT_TEST_RESULTS, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GET_TEST,
        variables: {
          id: testId,
        },
      },
    ],
  })
  const handleSave = async () => {
    try {
      await editTestResults({
        variables: {
          inputData: {
            testIds: [testId],
            specimenType: newSpecimenType,
          },
        },
      })
      onClose()
    } catch {
      // ignore error
    }
  }
  return (
    <Dialog isOpen={isOpen} onDismiss={onClose} maxWidth={pxToRem(500)}>
      <Card.Title onDismiss={onClose}>Change specimen type</Card.Title>
      <Card.Content>
        <Box height={pxToRem(250)}>
          <SelectInput
            label="Specimen Type"
            value={newSpecimenType || ''}
            options={SPECIMEN_TYPES}
            onChange={(event) => {
              setSpecimenType(event.target.value as SpecimenTypes)
            }}
          />
        </Box>
      </Card.Content>
      <Card.Actions>
        <Button onClick={onClose} colorVariant="danger" appearance="ghost">
          Cancel
        </Button>
        <Button isFetching={editTestResultsData.loading} onClick={handleSave}>
          Save
        </Button>
      </Card.Actions>
    </Dialog>
  )
}
