import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import styled from 'styled-components/macro'
import {
  TestSessionMembers_testSessionMembers_testSessionMembers,
  TestSessionMember_testSessionMember,
  TestSessionWithoutMembersWithResult_testSession_Tests,
  TestSessionWithoutMembersWithResult_testSession_staffs,
  TestInput,
  TestSessionWithoutMembersWithResult_testSession_PretestSessionSurveys,
  getFormattedDateAndTime,
  TestResult,
  TestType,
  SpecimenTypes,
  useLazyUser,
  useAuth,
  useAddTestSessionMembers,
  UserPermissions,
  useLazyInsuranceByUser,
  useConfirmInsuranceEligibility,
  useCheckInsuranceEligibility,
  CheckInsuranceEligibility,
  UserRole,
  TestValuesInput,
  GetLastInsuranceTestDate,
  GetLastInsuranceTestDateVariables,
  useListInsuranceCompanies,
} from '@modmd/data'
import Button from 'components/Button'
import Card from 'components/Card'
import { Dialog } from 'components/Dialog'
import { Box } from 'components/Layout'
import { SelectInput } from 'components/SelectInput'
import { TestTubeInput } from 'components/TestTubeInput'
import { Text } from 'components/Typography'
import Icon from 'components/Icon'
import { DetailsList } from 'components/DetailsList'
import { ROUTES } from 'internal-portal/constants/routes'
import { schema as testTubeCodeSchema } from 'internal-portal/utils/validateTestTubeCode'
import { RadioTestResult } from 'sharedComponents/RadioTestResult'
import { EditPersonal } from 'sharedComponents/AboutUser/EditPersonal'
import { FormInsurance } from 'client-portal/routes/RequestTestSession/FormInsurance'
import { useToaster } from 'utils/useToaster'
import { isInfluenza } from 'utils/helpers'
import { PretestSurvey } from '../components/PretestSurvey'
import TestFields from './TestFields'
import { useLazyQuery } from '@apollo/client'
import { GET_LAST_INSURANCE_TEST_DATE } from './operations'
import { Skeleton } from 'components/Skeleton'

const AlertMessage = styled.div`
  background-color: #dd2222;
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 8px 12px;
  flex-direction: row;
  gap: 12px;
  color: white;

  & > svg {
    width: 32px;
    height: 32px;
  }
`

const SkeletonRect = styled(Skeleton.Rect)`
  border-radius: 4px;
`

type TestInputWithoutSessionId = Omit<TestInput, 'testSessionId'>

interface UserTestProps {
  type: TestType | undefined
  Members: TestSessionMembers_testSessionMembers_testSessionMembers[]
  staffs: TestSessionWithoutMembersWithResult_testSession_staffs[]
  Tests: TestSessionWithoutMembersWithResult_testSession_Tests[]
  userId: number | null
  userData:
    | TestSessionMembers_testSessionMembers_testSessionMembers
    | TestSessionMember_testSessionMember
    | null
  onTestRemove: (testId: string) => void
  onTestSubmit: (inputData: TestInputWithoutSessionId) => void
  onDismiss: () => void
  PretestSessionSurveys: TestSessionWithoutMembersWithResult_testSession_PretestSessionSurveys[]
  isRemoveTestLoading?: boolean
  isAddTestLoading?: boolean
  isEditMode?: boolean
  isInsuredTestSession?: boolean
  isFinishedTestSession?: boolean
  defaultSessionValues?: TestValuesInput | null | undefined
  isTestSessionLoading?: boolean
}

interface Params {
  testSessionId: string
}

interface StaffMembers extends TestSessionWithoutMembersWithResult_testSession_staffs {
  isNotStaff?: boolean
}

const isCareStartOrSienna = (type: TestType | undefined): boolean =>
  type === TestType.AntigenCareStart ||
  type === TestType.SiennaAntigen ||
  type === TestType.IndicaidAntigen ||
  type === TestType.AcceavaStrep

const isSofia = (type: TestType | undefined): boolean =>
  type === TestType.SofiaStrep || type === TestType.SofiaRsv

const requiredFieldsTest = (type: TestType | undefined): boolean =>
  type === TestType.RapidCovid19 || type === TestType.Antigen

const isLucira = (type: TestType | undefined): boolean => type === TestType.Lucira

const isCovid = (type: TestType | undefined): boolean =>
  type === TestType.Covid19 || type === TestType.RapidCovid19 || type === TestType.IndicaidAntigen

const UserTest = ({
  type,
  Members,
  Tests,
  userId,
  userData,
  PretestSessionSurveys,
  onTestRemove,
  onTestSubmit,
  onDismiss,
  isRemoveTestLoading,
  isAddTestLoading,
  isEditMode = false,
  staffs,
  isInsuredTestSession,
  isFinishedTestSession,
  defaultSessionValues,
  isTestSessionLoading,
}: UserTestProps) => {
  const [dialogEditPersonal, setDialogEditPersonal] = useState(false)
  const [dialogConfirmEligibilityOpen, setDialogConfirmEligibilityOpen] = useState(false)
  const [dialogEditInsuranceOpen, setDialogEditInsuranceOpen] = useState(false)
  const [selectedEligibilityStatus, setSelectEligibilityStatus] = useState('')
  const [getUser, { data: userDataInfo, loading: loadingUser }] = useLazyUser({})
  const { setToastMessage } = useToaster()
  const [
    getUserInsurance,
    { data: userInsuranceData, loading: loadingUserInsurance, refetch: refetchInsurance },
  ] = useLazyInsuranceByUser({})

  const {
    data: insuranceCompanies,
    loading: insuranceCompaniesLoading,
  } = useListInsuranceCompanies()

  const [
    getLastInsuranceTestDate,
    { data: getLastInsuranceTestDateData, loading: getLastInsuranceTestDateLoading },
  ] = useLazyQuery<GetLastInsuranceTestDate, GetLastInsuranceTestDateVariables>(
    GET_LAST_INSURANCE_TEST_DATE
  )

  useEffect(() => {
    if (userId) {
      void getUserInsurance({
        variables: {
          inputData: {
            userId: String(userId),
          },
        },
      })
      void getLastInsuranceTestDate({
        variables: {
          id: String(userId),
        },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId])

  const validationSchema = Yup.object({
    type: Yup.string().nullable(),
    testTubeBarCode: Yup.string().when('type', {
      is: () => type === TestType.Covid19,
      then: testTubeCodeSchema.required('This field is required'),
      otherwise: Yup.string().nullable(),
    }),
    result: Yup.string().when('type', {
      is: () =>
        requiredFieldsTest(type) ||
        isCareStartOrSienna(type) ||
        isLucira(type) ||
        isInfluenza(type),
      then: Yup.string().typeError('This field is required').required('This field is required'),
      otherwise: Yup.string().nullable(),
    }),
    secondResult: Yup.string().when('type', {
      is: () => isInfluenza(type),
      then: Yup.string().typeError('This field is required').required('This field is required'),
      otherwise: Yup.string().nullable(),
    }),
    rapidDockId: Yup.string().when('type', {
      is: () => requiredFieldsTest(type) || (isInfluenza(type) && type === TestType.SofiaInfluenza),
      then: Yup.string().typeError('This field is required').required('This field is required'),
      otherwise: Yup.string().nullable(),
    }),
    rapidLotNumber: Yup.string().when('type', {
      is: () =>
        requiredFieldsTest(type) ||
        isLucira(type) ||
        (isInfluenza(type) && type === TestType.SofiaInfluenza),
      then: Yup.string().typeError('This field is required').required('This field is required'),
      otherwise: Yup.string().nullable(),
    }),
    rapidSpecimenNumber: Yup.string().when('type', {
      is: () =>
        requiredFieldsTest(type) ||
        isCareStartOrSienna(type) ||
        isLucira(type) ||
        isInfluenza(type),
      then: Yup.string().typeError('This field is required').required('This field is required'),
      otherwise: Yup.string().nullable(),
    }),
    rapidExpirationDate: Yup.mixed().when('type', {
      is: () =>
        requiredFieldsTest(type) ||
        isLucira(type) ||
        (isInfluenza(type) && type === TestType.SofiaInfluenza),
      then: Yup.mixed().required('This field is required'),
      otherwise: Yup.mixed().nullable(),
    }),
    rapidCartridgeExpirationDate: Yup.mixed().when('type', {
      is: () =>
        requiredFieldsTest(type) ||
        isCareStartOrSienna(type) ||
        isLucira(type) ||
        isInfluenza(type),
      then: Yup.mixed().required('This field is required'),
      otherwise: Yup.mixed().nullable(),
    }),
    staffMemberId: Yup.string().required('This field is required'),
    specimenType: Yup.string().required('This field is required'),
  })

  const CardTitle = styled(Card.Title)`
    padding: 4px 1rem;
  `

  const params = useParams<Params>()
  const auth = useAuth()
  const { addTestSessionMembersWithInput } = useAddTestSessionMembers({
    testSessionId: params.testSessionId,
    memberType: 'staff',
  })

  const isSchedulerOrAdmin = auth.hasRoles([UserRole.SCHEDULER, UserRole.ADMIN])

  const isSuperAdmin = auth.hasRoles([UserRole.SUPER_ADMIN])

  const member =
    userData ??
    (Members?.find(({ User, isParticipant }) => isParticipant && Number(User.id) === userId) ||
      ({} as TestSessionMembers_testSessionMembers_testSessionMembers))

  const patient = member?.User
  const staffMembers = React.useMemo(
    () =>
      staffs.some((staff) => staff.User.id === auth.data.User.id)
        ? staffs
        : [
            ...staffs,
            {
              id: auth.data.User.id,
              User: {
                firstName: auth.data.User.firstName,
                lastName: auth.data.User.lastName,
                id: auth.data.User.id,
              },
              isNotStaff: true,
            },
          ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(staffs), auth]
  ) as StaffMembers[]

  const hasStaffInLogged = React.useMemo(
    () =>
      staffMembers.find(
        ({ User }) => auth.data.User.id && Number(User.id) === Number(auth.data.User.id)
      )?.id || staffMembers[0]?.id,
    [auth.data, staffMembers]
  )

  const { touched, errors, resetForm, getFieldProps, setFieldValue, handleSubmit } = useFormik({
    enableReinitialize: true,
    initialValues: {
      testTubeBarCode: '',
      staffMemberId: hasStaffInLogged || '',
      result: null,
      secondResult: null,
      rapidDockId: defaultSessionValues?.rapidDockId ?? null,
      rapidLotNumber: defaultSessionValues?.rapidLotNumber ?? null,
      rapidSpecimenNumber: defaultSessionValues?.rapidSpecimenNumber || null,
      rapidExpirationDate: null,
      rapidCartridgeExpirationDate: null,
      specimenType: (defaultSessionValues?.specimenType as SpecimenTypes) ?? SpecimenTypes.midNasal,
    },
    validationSchema,
    onSubmit: async ({
      testTubeBarCode,
      staffMemberId,
      result,
      secondResult,
      rapidDockId,
      rapidLotNumber,
      rapidSpecimenNumber,
      rapidExpirationDate,
      rapidCartridgeExpirationDate,
      specimenType,
    }) => {
      const staffMemberSelect = staffMembers.find((staffMember) => staffMember.id === staffMemberId)

      let staffId = staffMemberId
      if (staffMemberSelect?.isNotStaff) {
        try {
          await addTestSessionMembersWithInput([staffMemberId]).then(({ data }) => {
            const getStaffMember = data?.addTestSessionMembers?.Members?.find(
              ({ User, isParticipant }) => User.id === staffMemberId && !isParticipant
            )
            if (getStaffMember?.id) {
              staffId = getStaffMember?.id
            }
          })
        } catch (error) {}
      }
      let testData: TestInputWithoutSessionId = {
        memberIds: [member?.id, staffId],
        specimenType,
      }
      if (type === TestType.Covid19) {
        testData = {
          ...testData,
          barcode: testTubeBarCode,
        }
      } else if (requiredFieldsTest(type)) {
        testData = {
          ...testData,
          barcode: `R${Date.now()}`,
          result,
          rapidDockId,
          rapidLotNumber,
          rapidSpecimenNumber,
          rapidExpirationDate,
          rapidCartridgeExpirationDate,
        }
      } else if (isCareStartOrSienna(type)) {
        testData = {
          ...testData,
          barcode: `R${Date.now()}`,
          result,
          rapidSpecimenNumber,
          rapidCartridgeExpirationDate,
        }
      } else if (isSofia(type)) {
        testData = {
          ...testData,
          barcode: `R${Date.now()}`,
          rapidDockId,
          rapidLotNumber,
          rapidSpecimenNumber,
          rapidExpirationDate,
          rapidCartridgeExpirationDate,
          result,
        }
      } else if (isLucira(type)) {
        testData = {
          ...testData,
          barcode: `R${Date.now()}`,
          rapidLotNumber,
          rapidSpecimenNumber,
          rapidExpirationDate,
          rapidCartridgeExpirationDate,
          result,
        }
      } else if (isInfluenza(type)) {
        testData = {
          ...testData,
          barcode: `R${Date.now()}`,
          rapidDockId,
          rapidLotNumber,
          rapidSpecimenNumber,
          rapidExpirationDate,
          rapidCartridgeExpirationDate,
          result,
          secondResult,
        }
      }

      onTestSubmit(testData)
    },
  })

  React.useEffect(() => {
    if (hasStaffInLogged) {
      void setFieldValue('staffMemberId', hasStaffInLogged)
    }
  }, [hasStaffInLogged, setFieldValue])

  React.useEffect(() => {
    if (defaultSessionValues?.rapidExpirationDate) {
      void setFieldValue('rapidExpirationDate', new Date(defaultSessionValues?.rapidExpirationDate))
    }
    if (defaultSessionValues?.rapidCartridgeExpirationDate) {
      void setFieldValue(
        'rapidCartridgeExpirationDate',
        new Date(defaultSessionValues?.rapidCartridgeExpirationDate)
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultSessionValues, patient])

  const patientTest = React.useMemo(
    () =>
      (Tests.find(({ Members }) =>
        Members.some(({ User, isParticipant }) => isParticipant && Number(User.id) === userId)
      ) as TestSessionWithoutMembersWithResult_testSession_Tests) || undefined,
    [Tests, userId]
  )

  const getPatientNameOrEmail = () =>
    patient?.firstName || patient?.lastName
      ? `${patient?.firstName || ''} ${patient?.lastName || ''}`
      : patient?.email

  const handleDismiss = React.useCallback(() => {
    if (patientTest || window.confirm('Are you sure you want to discard all changes?')) {
      resetForm()
      onDismiss()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onDismiss, JSON.stringify(patientTest), resetForm])
  const handleTestRemove = React.useCallback(() => {
    if (window.confirm('Are you sure you want to delete the test?')) {
      onTestRemove(patientTest.id)
    }
  }, [patientTest, onTestRemove])

  const handleDialogCloseEditPersonal = () => {
    setDialogEditPersonal(false)
  }

  const handleCloseDialogConfirmEligibility = () => {
    setDialogConfirmEligibilityOpen(false)
  }

  const handleCloseDialogEditInsurance = () => {
    setDialogEditInsuranceOpen(false)
  }

  const handleOnChange = (id: string, value: string | Date) => {
    void setFieldValue(id, value)
  }

  const [
    editInsuranceEligibility,
    { loading: editInsuranceEligibilityLoading },
  ] = useConfirmInsuranceEligibility({
    onCompleted: async () => {
      setToastMessage('Insurance successfully changed', 'success')
      if (refetchInsurance) await refetchInsurance()
      handleCloseDialogConfirmEligibility()
    },
  })

  const [
    checkInsuranceEligibility,
    { loading: checkInsuranceEligibilityLoading },
  ] = useCheckInsuranceEligibility({
    onCompleted: async (data: CheckInsuranceEligibility) => {
      if (data.checkInsuranceEligibility) {
        setToastMessage('Insurance eligibility successfully checked', 'success')
        if (refetchInsurance) await refetchInsurance()

        if (data.checkInsuranceEligibility.eligibilityStatus === 'Active')
          handleCloseDialogConfirmEligibility()
        else if (data.checkInsuranceEligibility.eligibilityStatus === 'Inactive') {
          handleCloseDialogConfirmEligibility()
          onDismiss()
        }
      } else setToastMessage('Insurance eligibility check failed', 'danger')
    },
  })

  const handleConfirmInsuranceEligibility = async () => {
    await editInsuranceEligibility({
      variables: {
        inputData: {
          userId: String(userId),
          status: selectedEligibilityStatus,
        },
      },
    })
  }

  const handleCheckInsuranceEligibility = async () => {
    try {
      await checkInsuranceEligibility({
        variables: {
          inputData: {
            userId: String(userId),
          },
        },
      })
    } catch {
      // do nothing
    }
  }

  const canBeTested = React.useMemo(() => {
    if (
      patient &&
      (!patient.firstName ||
        !patient.lastName ||
        !patient.birthDate ||
        !patient.Address ||
        !patient.phoneNumber ||
        !patient.gender ||
        !patient.ethnicity ||
        !patient.race)
    )
      return false
    return true
  }, [patient])

  const barcodeCheck = React.useMemo(() => {
    if (isEditMode && isFinishedTestSession && isSuperAdmin) {
      return { canAddBarCode: true, barcodeCheckLoading: false, activeDaysBetweenTests: 7 }
    }

    if (!isInsuredTestSession) {
      return { canAddBarCode: true, barcodeCheckLoading: false, activeDaysBetweenTests: 7 }
    }

    if (
      !getLastInsuranceTestDateData?.getLastInsuranceTestDate?.testDate &&
      !getLastInsuranceTestDateLoading
    ) {
      return { canAddBarCode: true, barcodeCheckLoading: false, activeDaysBetweenTests: 7 }
    }

    if (insuranceCompaniesLoading) {
      return { canAddBarCode: false, barcodeCheckLoading: true, activeDaysBetweenTests: 7 }
    }

    if (
      getLastInsuranceTestDateData?.getLastInsuranceTestDate?.testDate &&
      !getLastInsuranceTestDateLoading
    ) {
      const insuranceCompanyActive = insuranceCompanies?.listInsuranceCompanies?.find(
        (el) => el.name === userInsuranceData?.getInsuranceByUser?.insuranceCompany
      )

      const activeDaysBetweenTests = insuranceCompanyActive?.daysBetweenTests || 7

      const date = new Date(getLastInsuranceTestDateData.getLastInsuranceTestDate?.testDate)
      const today = new Date()

      const daysSinceLastInsuranceTest = Math.floor(
        (today.getTime() - date.getTime()) / (1000 * 60 * 60 * 24)
      )

      return {
        canAddBarCode:
          daysSinceLastInsuranceTest >= activeDaysBetweenTests ||
          patientTest?.id == getLastInsuranceTestDateData?.getLastInsuranceTestDate?.testId ||
          !isCovid(type),
        barcodeCheckLoading: false,
        activeDaysBetweenTests,
      }
    }

    return {
      canAddBarCode: false,
      barcodeCheckLoading: getLastInsuranceTestDateLoading,
      activeDaysBetweenTests: 7,
    }
  }, [
    isEditMode,
    isFinishedTestSession,
    isSuperAdmin,
    getLastInsuranceTestDateData,
    getLastInsuranceTestDateLoading,
    isInsuredTestSession,
    patientTest,
    insuranceCompaniesLoading,
    insuranceCompanies,
    userInsuranceData,
    type,
  ])

  const insuranceApproved = () => {
    if (userInsuranceData) {
      if (
        userInsuranceData?.getInsuranceByUser?.govIdChecked &&
        userInsuranceData?.getInsuranceByUser?.insuranceCardFrontChecked &&
        userInsuranceData?.getInsuranceByUser?.insuranceCardBackChecked &&
        userInsuranceData?.getInsuranceByUser?.eligibilityStatus === 'Active'
      )
        return true
      return false
    }
    return false
  }

  const eligibilityValid = () => {
    if (!isInsuredTestSession) return true

    return userInsuranceData?.getInsuranceByUser?.eligibilityStatus === 'Active'
  }

  return (
    <>
      <Dialog maxWidth="500px" isOpen={!!patient} onDismiss={handleDismiss}>
        <form onSubmit={handleSubmit}>
          <Card>
            <CardTitle onDismiss={handleDismiss}>
              {patient && (
                <Button
                  ml="1rem"
                  to={`/${ROUTES.USERS}/${patient.id}`}
                  appearance="link"
                  size="large"
                >
                  {getPatientNameOrEmail()}
                </Button>
              )}
            </CardTitle>
            {patient && (
              <>
                <Card.Content display="grid" gridGap="1rem">
                  <Box display="flex" justifyContent="space-between">
                    <DetailsList
                      list={[
                        {
                          label: 'Address',
                          value: `${patient.Address?.street || ''}, ${
                            patient.Address?.complement ? `${patient.Address?.complement}, ` : ''
                          }${patient.Address?.city || ''}`,
                        },
                        { label: 'Email', value: patient.email },
                        {
                          label: 'Birth date',
                          value: patient.birthDate
                            ? getFormattedDateAndTime(patient.birthDate)
                            : 'N/A',
                        },
                      ]}
                      hasError={
                        !canBeTested
                          ? 'Please, fill out all user information before testing.'
                          : undefined
                      }
                    />
                    <Box display="flex" flexDirection="column">
                      {(auth.hasPermission([UserPermissions.EDIT_ALL_USERS_INFORMATION]) ||
                        (auth.hasPermission([
                          UserPermissions.SEE_EDIT_USERS_INFORMATION_ASSIGNED_SESSION,
                        ]) &&
                          staffs.some((el) => el.User.id === auth.data.User.id))) && (
                        <Button
                          size="small"
                          onClick={() => {
                            void getUser({
                              variables: {
                                inputData: {
                                  id: patient.id,
                                },
                              },
                            })
                            setDialogEditPersonal(true)
                          }}
                          isFetching={loadingUser}
                        >
                          Edit
                        </Button>
                      )}
                      {userInsuranceData && isInsuredTestSession && !insuranceApproved() && (
                        <Button
                          size="small"
                          onClick={() => {
                            setDialogEditInsuranceOpen(true)
                          }}
                          isFetching={loadingUserInsurance}
                          mt="1rem"
                        >
                          Edit Insurance
                        </Button>
                      )}
                      {userInsuranceData &&
                        isInsuredTestSession &&
                        userInsuranceData?.getInsuranceByUser?.eligibilityStatus !== 'Active' && (
                          <Button
                            size="small"
                            onClick={() => {
                              setDialogConfirmEligibilityOpen(true)
                            }}
                            isFetching={loadingUserInsurance}
                            mt="1rem"
                          >
                            Confirm Eligibility
                          </Button>
                        )}
                    </Box>
                  </Box>
                  {!isSofia(type) && !isInfluenza(type) && (
                    <PretestSurvey
                      PretestSessionSurvey={PretestSessionSurveys.find(
                        ({ User }) => User.id === patient?.id
                      )}
                      testSessionId={params.testSessionId}
                      userId={userId}
                      isEditMode={isEditMode}
                    />
                  )}
                  {!barcodeCheck.barcodeCheckLoading && !loadingUserInsurance && patient ? (
                    barcodeCheck.canAddBarCode && eligibilityValid() ? (
                      patientTest ? (
                        <Box>
                          <Text fontSize="l">Pretest survey</Text>
                          <DetailsList
                            list={[
                              {
                                label: 'Test tube',
                                value: patientTest.barcode,
                              },
                              {
                                label: 'Performed by',
                                value: patientTest.Members?.filter(({ isStaff }) => isStaff)
                                  .map(
                                    ({ User }) => `${User.firstName || ''} ${User.lastName || ''}`
                                  )
                                  .join(', '),
                              },
                              {
                                label: 'Tested at',
                                value: getFormattedDateAndTime(patientTest.testedAt, true),
                              },
                              {
                                label: 'Specimen Type',
                                value: patientTest.specimenType || Object.values(SpecimenTypes)[0],
                              },
                            ]}
                          />
                          <Box
                            display="grid"
                            gridAutoFlow="column"
                            gridGap="0.5rem"
                            alignItems="center"
                            mt="1rem"
                          >
                            {isEditMode && (
                              <Button
                                isFetching={isRemoveTestLoading}
                                onClick={handleTestRemove}
                                colorVariant="danger"
                                size="small"
                              >
                                Remove & repeat test
                              </Button>
                            )}
                            {!isSchedulerOrAdmin && (
                              <Button to={`/${ROUTES.TESTS}/${patientTest.id}`} appearance="link">
                                Test details
                              </Button>
                            )}
                          </Box>
                        </Box>
                      ) : (
                        <Box>
                          {requiredFieldsTest(type) ||
                          isCareStartOrSienna(type) ||
                          isSofia(type) ||
                          isLucira(type) ||
                          isInfluenza(type) ? (
                            <>
                              <RadioTestResult
                                name={[
                                  TestResult.Positive,
                                  TestResult.Negative,
                                  TestResult.Invalid,
                                ]}
                                errorMessage={
                                  touched.result && errors.result ? errors.result : undefined
                                }
                                onChange={setFieldValue}
                                field="result"
                                label={isInfluenza(type) ? 'Influenza A Result' : 'Result'}
                              />
                              {isInfluenza(type) && (
                                <RadioTestResult
                                  name={[
                                    TestResult.Positive,
                                    TestResult.Negative,
                                    TestResult.Invalid,
                                  ]}
                                  errorMessage={
                                    touched.secondResult && errors.secondResult
                                      ? errors.secondResult
                                      : undefined
                                  }
                                  onChange={setFieldValue}
                                  field="secondResult"
                                  label="Influenza B Result"
                                />
                              )}
                            </>
                          ) : (
                            <TestTubeInput
                              errorMessage={
                                touched.testTubeBarCode && errors.testTubeBarCode
                                  ? errors.testTubeBarCode
                                  : undefined
                              }
                              {...getFieldProps('testTubeBarCode')}
                              onChange={(value) => {
                                void setFieldValue('testTubeBarCode', value)
                              }}
                            />
                          )}
                          <TestFields
                            type={type}
                            onChange={handleOnChange}
                            getFieldProps={getFieldProps}
                            touched={touched}
                            errors={errors}
                            defaultValues={defaultSessionValues || undefined}
                          />
                          <SelectInput
                            label="Staff"
                            options={staffMembers.map(({ id, User }) => ({
                              value: id,
                              label: `${User.firstName || ''} ${User.lastName || ''}`,
                            }))}
                            {...getFieldProps('staffMemberId')}
                            errorMessage={
                              touched.staffMemberId && errors.staffMemberId
                                ? errors.staffMemberId
                                : undefined
                            }
                          />
                        </Box>
                      )
                    ) : !eligibilityValid() ? (
                      <AlertMessage>
                        <Icon.Warning />
                        <div>
                          Please confirm the insurance eligibility before testing the patient.
                        </div>
                      </AlertMessage>
                    ) : (
                      <AlertMessage>
                        <Icon.Warning />
                        <div>
                          This user has already been tested in the last{' '}
                          {barcodeCheck.activeDaysBetweenTests} days in an insurance session and
                          can't be tested at the moment.
                        </div>
                      </AlertMessage>
                    )
                  ) : (
                    <SkeletonRect height={58} />
                  )}
                </Card.Content>
                <Card.Actions gridTemplateColumns="repeat(auto-fit, minmax(1rem, 1fr))">
                  {patientTest ? (
                    <Button onClick={handleDismiss} colorVariant="secondary">
                      Close
                    </Button>
                  ) : (
                    <>
                      <Button onClick={handleDismiss} colorVariant="secondary">
                        Cancel
                      </Button>
                      <Button
                        type="submit"
                        disabled={!canBeTested || !barcodeCheck.canAddBarCode}
                        isFetching={isAddTestLoading || isTestSessionLoading}
                        colorVariant="success"
                      >
                        Submit
                      </Button>
                    </>
                  )}
                </Card.Actions>
              </>
            )}
            {!patient && userId && <h2>Patient not found</h2>}
          </Card>
        </form>
      </Dialog>
      <Dialog
        maxWidth="500px"
        isOpen={dialogEditPersonal && !!userDataInfo}
        onDismiss={handleDialogCloseEditPersonal}
      >
        <Card>
          <Card.Title onDismiss={handleDialogCloseEditPersonal}>
            Edit personal information
          </Card.Title>
          <Card.Content>
            <EditPersonal
              onDismiss={handleDialogCloseEditPersonal}
              data={userDataInfo && userDataInfo.user}
              notverifyUserInvitePending
            />
          </Card.Content>
        </Card>
      </Dialog>
      <Dialog
        maxWidth="500px"
        isOpen={dialogEditInsuranceOpen && !!userInsuranceData}
        onDismiss={handleCloseDialogEditInsurance}
      >
        <Card>
          <Card.Content>
            <FormInsurance
              userId={String(userId)}
              onClose={handleCloseDialogEditInsurance}
              isDialog
              insurances={userInsuranceData?.getInsuranceByUser}
              refetchInsurance={() => (refetchInsurance ? refetchInsurance() : undefined)}
              isInsurance={
                userInsuranceData?.getInsuranceByUser?.insuranceCompany
                  ? !!userInsuranceData?.getInsuranceByUser?.govId
                  : false
              }
              isEdit
            />
          </Card.Content>
        </Card>
      </Dialog>
      <Dialog
        maxWidth="500px"
        isOpen={dialogConfirmEligibilityOpen && !!userInsuranceData}
        onDismiss={handleCloseDialogConfirmEligibility}
      >
        <Card>
          <Card.Title onDismiss={handleCloseDialogConfirmEligibility}>
            Confirm Eligibility
          </Card.Title>
          <Card.Content>
            <Box display="flex" flexDirection="column">
              <Button
                isFetching={checkInsuranceEligibilityLoading}
                colorVariant="primary"
                onClick={handleCheckInsuranceEligibility}
              >
                Run Insurance Eligibility Check
              </Button>
              {auth.hasRoles([UserRole.SUPER_ADMIN]) && (
                <>
                  <Text textAlign="center" m="1rem" fontSize="l">
                    or
                  </Text>
                  <SelectInput
                    label="Set Eligibility status manually"
                    options={[
                      { value: '', label: 'Select an option' },
                      {
                        value: 'Active',
                        label: 'Active',
                      },
                      {
                        value: 'Inactive',
                        label: 'Inactive',
                      },
                      {
                        value: 'Pending',
                        label: 'Pending',
                      },
                    ]}
                    value={selectedEligibilityStatus}
                    onChange={(e) => setSelectEligibilityStatus(e.target.value)}
                  />
                </>
              )}
            </Box>
          </Card.Content>
          {auth.hasRoles([UserRole.SUPER_ADMIN]) && (
            <Card.Actions>
              <Button
                mr="0.25rem"
                colorVariant="menu"
                onClick={handleCloseDialogConfirmEligibility}
              >
                Cancel
              </Button>
              <Button
                isFetching={editInsuranceEligibilityLoading}
                colorVariant="primary"
                onClick={handleConfirmInsuranceEligibility}
                disabled={!selectedEligibilityStatus}
              >
                Submit data
              </Button>
            </Card.Actions>
          )}
        </Card>
      </Dialog>
    </>
  )
}

export default UserTest
