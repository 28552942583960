import { gql } from '@apollo/client'

export const REQUEST_TEST_RESULT_PDF_URL = gql`
  query RequestTestResultPdfUrl($id: ID!) {
    requestTestResultPdfUrl(id: $id)
  }
`
export const GET_PDF_TEST = gql`
  query GetPDFTest($id: ID!) {
    test(id: $id) {
      id
      testedAt
      barcode
      result
      secondResult
      resultReceivedAt
      resultProvisionedAt
      specimenType
      ParticipantMembers: Members(inputData: { participantOnly: true }) {
        User {
          id
          firstName
          lastName
          gender
          birthDate
          phoneNumber
          email
          Address {
            street
            city
            zip
            country
            state
          }
        }
      }
      TestSession {
        id
        lab
        type
      }
    }
  }
`

export const GET_EVENTSAMPLE = gql`
  query getSample($id: ID!) {
    eventSample(id: $id) {
      id
      eventAttendeeId
      EventAttendee {
        firstName
        lastName
        dateOfBirth
        gender
        email
        phone
        Address {
          id
          street
          city
          zip
          country
          state
        }
        userId
      }
      barcode
      result
      secondResult
      testedAt
      resultProvisionedAt
      sampleStatus
      rapidLotNumber
      rapidCartridgeExpirationDate
      rapidDockId
      rapidSpecimenNumber
      rapidExpirationDate
      specimenType
      testedById
    }
  }
`
