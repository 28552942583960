import React from 'react'
import { isNull } from 'lodash'
import { useToaster } from 'utils/useToaster'
import {
  TestSessionDetailWithResult_testSession,
  TestInput,
  useAuth,
  UserRole,
  useEditTestSession,
  getFormattedDateAndTime,
  getResultLabelColor,
  useFuzzySearch,
  SortByType,
  useTestSessionWithoutMembers,
  TestSessionMembers_testSessionMembers_testSessionMembers,
  TestSessionWithoutMembersWithResult_testSession_staffs,
  TestSessionWithoutMembersWithResult_testSession_Tests,
  TestSessionMember_testSessionMember,
  useRemoveTestSessionMember,
  useAddTest,
  useRemoveTest,
  TestSessionPaymentStatus,
  useSendEmailInsurance,
  useTestSessionMembers,
  useStateWithDebounce,
  UserPermissions,
  SortDirectionEnum,
  useDeleteMembers,
  useListInsuranceCompanies,
  TestType,
} from '@modmd/data'
import styled from 'styled-components/macro'
import { mix } from 'polished'
import { RouteComponentProps } from 'react-router-dom'
import { Text } from 'components/Typography'
import { COLOR, DEVICE, fontSizes, pxToRem } from 'theme'
import { AddNewTestSessionMembers } from 'sharedComponents/AddNewTestSessionMembers'
import { Loading } from 'components/Loading'
import { DownloadTestResultPdfButton } from 'sharedComponents/DownloadTestResultPdfButton'
import Label from 'components/Label'
import DashboardPage from 'internal-portal/sharedComponents/DashboardPage'
import Card from 'components/Card'
import { Box } from 'components/Layout'
import Button from 'components/Button'
import { ConfirmationDialog, Dialog } from 'components/Dialog'
import { Spinner } from 'components/Spinner'
import Datalist from 'components/Datalist'
import TextInput from 'components/TextInput'
import Icon from 'components/Icon'
import Pagination from 'components/Pagination'
import { Checkbox } from 'components/Checkbox'
import { useIsMinDevice } from 'utils/hooks/useMedia'
import { FormInsurance } from 'client-portal/routes/RequestTestSession/FormInsurance'
import { useSearchState } from 'internal-portal/utils/useSearchState'
import { ROUTES } from 'internal-portal/constants/routes'
import { isInfluenza } from 'utils/helpers'
import SessionInfo from './SessionInfo'
import UserTest from './UserTest'
import { AddPatientScanCode } from './AddPatientScanCode'
import { Header } from './Header'
import { ConfirmInsurance } from '../components/ConfirmInsurance'

interface RouterProps {
  testSessionId: string
}

enum MemberType {
  staff = 'staff',
  participant = 'participant',
}

type TestSessionDetailsProps = RouteComponentProps<RouterProps>

const Layout = styled.main`
  display: grid;
  grid-gap: 1rem;
`

export enum UrlSearchQuery {
  EMAIL_OR_NAME_LIKE = 'emailOrNameLike',
  PAGE = 'page',
  ORDER_BY = 'orderBy',
  DIRECTION = 'direction',
}

const sortByFormat = (sorter: string | undefined) => {
  switch (sorter) {
    case 'birthDate':
      return 'User.birthDate'
    case 'email':
      return 'User.email'
    case 'lastName':
      return 'User.lastName'
    case 'firstName':
      return 'User.firstName'
    case 'testedAt':
      return 'UserTestSessionTest:Test.testedAt'
    default:
      return ''
  }
}

const PERPAGE = 10

export const TestSessionDetails: React.FC<TestSessionDetailsProps> = ({ match, history }) => {
  const { data: userAuth, hasRoles, hasPermission } = useAuth()
  const canSeeResults = hasRoles([
    UserRole.SUPER_ADMIN,
    UserRole.RESULTS,
    UserRole.LIAISON,
    UserRole.NURSE,
  ])
  const isSuperAdmin = hasRoles([UserRole.SUPER_ADMIN])
  const { setToastMessage } = useToaster()
  const [rowId, setRowId] = React.useState<string | null>(null)
  const { testSessionId } = match.params
  const [openTestForUserId, setOpenTestForUserId] = React.useState<number | null>(null)
  const [userData, setUserData] = React.useState<
    | TestSessionMembers_testSessionMembers_testSessionMembers
    | TestSessionMember_testSessionMember
    | null
  >(null)
  const [insuranceUserId, setInsuranceUserId] = React.useState<number | null>(null)
  const [isEditMode, setIsEditMode] = React.useState(false)
  const [fetching, setFetching] = React.useState(false)
  const [selected, setSelected] = React.useState<string[]>([])
  const [allSelected, setAllSelected] = React.useState<boolean>(false)
  const [memberSelected, setMemberSelected] = React.useState<{
    member: keyof typeof MemberType
    name: string
    id: string
  } | null>(null)
  const [confirmDeleteMember, setConfirmDeleteMember] = React.useState(false)

  const isDesktop = useIsMinDevice(DEVICE.DESKTOP)
  const isTablet = useIsMinDevice(DEVICE.TABLET)
  const isMobile = !useIsMinDevice(DEVICE.TABLET)

  const searchState = useSearchState<{
    [UrlSearchQuery.EMAIL_OR_NAME_LIKE]?: string
    [UrlSearchQuery.PAGE]?: string
    [UrlSearchQuery.ORDER_BY]?: string
    [UrlSearchQuery.DIRECTION]?: SortDirectionEnum
  }>()

  const [emailOrNameLike, setEmailOrNameLike, debouncedSearchQuery] = useStateWithDebounce(
    searchState.data.emailOrNameLike,
    (emailOrNameLike) => {
      searchState.setSearchState(
        { emailOrNameLike, page: emailOrNameLike ? 1 : searchState.data.page },
        false,
        true
      )
    },
    1000
  )

  const {
    data: testSessionData,
    loading: isTestSessionLoading,
    refetch: refetchTestSessionData,
  } = useTestSessionWithoutMembers({
    testSessionId,
    skipResult: !canSeeResults,
    onCompleted: (data) => {
      if (!data.testSession) {
        setToastMessage('TestSession not found.', 'danger')
        history.replace(`/${ROUTES.SESSIONS}`)
      }
    },
  })

  const staffsInTested = React.useMemo(
    () =>
      testSessionData?.testSession?.Tests?.map((test) =>
        test.Members.find((testMember) => testMember.isStaff && !testMember.isParticipant)
      ) || [],
    [testSessionData]
  )

  const {
    data: sessionMembers,
    loading: sessionMembersLoading,
    refetch: refetchSessionMembers,
  } = useTestSessionMembers({
    testSessionId,
    participantOnly: true,
    staffOnly: false,
    page: Number(searchState.data.page ?? 1),
    pageLength: PERPAGE,
    emailOrNameLike: debouncedSearchQuery,
    sortBy: sortByFormat(searchState.data.orderBy),
    direction: searchState.data.direction ?? undefined,
  })

  const testSessionMembers = React.useMemo<
    TestSessionMembers_testSessionMembers_testSessionMembers[]
  >(() => sessionMembers?.testSessionMembers?.testSessionMembers || [], [
    sessionMembers?.testSessionMembers,
  ])

  const [editTestSession, { loading: isEditTestSessionLoading }] = useEditTestSession({
    testSessionId,
    skipResult: !canSeeResults,
  })
  const [
    removeTestSessionMember,
    { loading: isLoadingRemoveTestSessionMember },
  ] = useRemoveTestSessionMember()
  const [sendEmailInsurance] = useSendEmailInsurance()

  const [addTest, { loading: isAddTestLoading }] = useAddTest()
  const [removeTest, { loading: isRemoveTestLoading }] = useRemoveTest()
  const [fillModal, setFillModal] = React.useState(false)
  const [addInfoModal, setAddInfoModal] = React.useState(false)
  const [confirmInsuranceModal, setConfirmInsuranceModal] = React.useState(false)
  const [confirmDeletePerson, setConfirmDeletePerson] = React.useState(false)
  const [deleteMember, { loading: isLoading }] = useDeleteMembers({
    onCompleted: () => {
      searchState.setSearchState({ page: 1 }, false)
      setSelected([])
      setAllSelected(false)
      void refetchSessionMembers()
      setConfirmDeletePerson(false)
    },
  })

  const [patientModal, setPatientModal] = React.useState({
    firstName: '',
    lastName: '',
    userTestSessionId: '',
    id: '',
  })
  const allMembers = React.useMemo(
    () => testSessionMembers || ([] as TestSessionMembers_testSessionMembers_testSessionMembers[]),
    [testSessionMembers]
  )
  const staffs = React.useMemo<TestSessionWithoutMembersWithResult_testSession_staffs[]>(
    () => testSessionData?.testSession?.staffs || [],
    [testSessionData]
  )

  const MemberCount = React.useMemo(
    () => Number(sessionMembers?.testSessionMembers?.pagination.total) || 0,
    [sessionMembers?.testSessionMembers]
  )

  const patients = React.useMemo(() => allMembers.filter(({ isParticipant }) => isParticipant), [
    allMembers,
  ])

  const [
    fuzzyStaff,
    staffSearchQuery,
    setStaffSearchQuery,
  ] = useFuzzySearch<TestSessionWithoutMembersWithResult_testSession_staffs>({
    data: staffs,
    searchKeys: ['User.firstName', 'User.lastName', 'User.email'],
  })
  const refetchSessionQuery = React.useCallback(async () => {
    try {
      setFetching(true)
      await refetchTestSessionData()
      await refetchSessionMembers()
    } catch {
      //
    }
    setFetching(false)
  }, [refetchTestSessionData, refetchSessionMembers])
  const handleDetailsChange = React.useCallback(
    async (details: Partial<TestSessionDetailWithResult_testSession>) => {
      if (testSessionData?.testSession) {
        const { id, type, date, endsAt, isScheduled, Location, name, TestValues } = {
          ...testSessionData.testSession,
          ...details,
          Location: {
            ...testSessionData.testSession.Location,
            ...details.Location,
          },
        }
        const {
          specimenType,
          rapidLotNumber,
          rapidCartridgeExpirationDate,
          rapidDockId,
          rapidSpecimenNumber,
          rapidExpirationDate,
        } = TestValues ?? {}

        const inputData = {
          id,
          date,
          type,
          isScheduled,
          ...(name ? { name } : {}),
          ...(endsAt ? { endsAt } : {}),
          Location: {
            id: Location.id,
            street: Location.street,
            city: Location.city,
            zip: Location.zip,
            state: Location.state,
            country: Location.country,
            county: Location.county,
            complement: Location.complement,
            lat: Location.lat,
            lon: Location.lon,
          },
          testValues: {
            specimenType,
            rapidLotNumber,
            rapidCartridgeExpirationDate,
            rapidDockId,
            rapidSpecimenNumber,
            rapidExpirationDate,
          },
        }
        try {
          setFetching(true)
          await editTestSession({ variables: { inputData } })
          void refetchTestSessionData()
          setToastMessage('Changes successfully saved', 'success')
        } catch (e) {
          // ignore error
        }
        setFetching(false)
      }
    },
    [editTestSession, setToastMessage, testSessionData, refetchTestSessionData]
  )
  const handleTestRemove = React.useCallback(
    async (testId: string) => {
      try {
        await removeTest({
          variables: {
            id: testId,
          },
        })
        void refetchTestSessionData()
        setOpenTestForUserId(null)
        setUserData(null)
        setToastMessage('Test successfully removed', 'success')
      } catch (e) {
        // ignore error
      }
    },
    [removeTest, setToastMessage, refetchTestSessionData]
  )
  const handleTestSubmit = React.useCallback(
    async ({
      barcode,
      memberIds,
      result,
      secondResult,
      rapidDockId,
      rapidLotNumber,
      rapidSpecimenNumber,
      rapidExpirationDate,
      rapidCartridgeExpirationDate,
      specimenType,
    }: Omit<TestInput, 'testSessionId'>) => {
      try {
        await addTest({
          variables: {
            inputData: {
              testSessionId,
              barcode,
              memberIds,
              result,
              secondResult,
              rapidDockId,
              rapidLotNumber,
              rapidSpecimenNumber,
              rapidExpirationDate,
              rapidCartridgeExpirationDate,
              specimenType,
            },
          },
        })
        setToastMessage('Test successfully saved', 'success')
      } catch (e) {
        // ignore error
      }
      void refetchTestSessionData()
    },
    [addTest, testSessionId, setToastMessage, refetchTestSessionData]
  )
  const testedUserIds = React.useMemo(
    () =>
      !debouncedSearchQuery
        ? testSessionData?.testSession?.Tests?.map(({ Members }) =>
            Members.find(({ isParticipant }) => isParticipant)
          ).map((Member) => Member?.User.id) || []
        : testSessionData?.testSession?.Tests?.filter(({ Members }) =>
            Members.some(({ User }) => allMembers.some((data) => User.id === data.User.id))
          )
            .map(({ Members }) => Members.find(({ isParticipant }) => isParticipant))
            .map((Member) => Member?.User.id) || [],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(testSessionData?.testSession?.Tests), debouncedSearchQuery]
  )

  const userTestMap: Record<
    string,
    TestSessionWithoutMembersWithResult_testSession_Tests | undefined
  > = React.useMemo(
    () =>
      testSessionMembers?.reduce((acc, Member) => {
        const { User, isParticipant } = Member
        if (isParticipant) {
          const test = testedUserIds.includes(User.id)
            ? testSessionData?.testSession?.Tests.find(({ Members }) =>
                Members.some(({ isParticipant, User: { id } }) => isParticipant && id === User.id)
              ) || null
            : null

          return {
            ...acc,
            [User.id]: test,
          }
        }

        return acc
      }, {}) || {},
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      // eslint-disable-next-line react-hooks/exhaustive-deps
      JSON.stringify(testSessionMembers),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      JSON.stringify(testSessionData?.testSession?.Tests),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      JSON.stringify(testedUserIds),
    ]
  )

  const isFinished = !!testSessionData?.testSession?.finishedAt
  React.useEffect(() => {
    setIsEditMode(!isFinished)
  }, [isFinished])

  const openDialogDeleteMember = ({
    id,
    name,
    member,
  }: {
    member: keyof typeof MemberType
    name: string
    id: string
  }) => {
    setRowId(id)
    setMemberSelected({
      id,
      name,
      member,
    })
    setConfirmDeleteMember(true)
  }

  const handleRemoveTestSessionMember = React.useCallback(async () => {
    try {
      await removeTestSessionMember({
        variables: {
          inputData: {
            id: memberSelected!.id,
          },
        },
      })
      setToastMessage('Member successfully deleted!', 'success')
      setFillModal(false)
      setConfirmDeleteMember(false)
      setMemberSelected(null)
      await refetchSessionQuery()
    } catch {
      // ignore
    }
    setRowId(null)
  }, [memberSelected, refetchSessionQuery, removeTestSessionMember, setToastMessage])

  const handleSendEmailInsurance = async () => {
    try {
      await sendEmailInsurance({
        variables: { inputData: { userId: patientModal.id || String(insuranceUserId) } },
      })
      setFillModal(false)
      setToastMessage(`E-mail has been sent`, 'success')
    } catch (error) {
      // ignore
    }
  }

  const { data: insuranceCompanies } = useListInsuranceCompanies()

  const activeInsuranceCompanies = React.useMemo(
    () =>
      insuranceCompanies
        ? insuranceCompanies.listInsuranceCompanies
            .filter((insComp) => insComp.isApproved)
            .map((insComp) => insComp.name) ?? []
        : [],
    [insuranceCompanies]
  )

  const patientsData = React.useMemo(
    () =>
      patients.map(({ id, User }) => {
        let test: TestSessionWithoutMembersWithResult_testSession_Tests | undefined | null = null
        test = testSessionData?.testSession?.Tests.find(({ Members }) =>
          Members.some(
            (Member) => Member.isParticipant && !Member.isStaff && Member.User.id === User.id
          )
        )

        return {
          ...User,
          firstName: User.firstName,
          lastName: User.lastName,
          userTestSessionId: id,
          testedAt: test ? getFormattedDateAndTime(new Date(test.testedAt), true) : null,
          testedBy: test
            ? test.Members.filter(({ isStaff, isParticipant }) => isStaff && !isParticipant)
                .map(({ User }) => `${User.firstName || ''} ${User.lastName || ''}`)
                .join(', ')
            : null,
          barcode: test ? test.barcode : null,
          result: test?.result,
          secondResult: test?.secondResult,
          testId: test?.id,
          isSubperson: !User.email,
          isApproved: User.Insurance?.isApproved,
          govId: User.Insurance?.govId,
          govIdChecked: User.Insurance?.govIdChecked,
          govIdMessageDenied: User.Insurance?.govIdMessageDenied,
          insuranceCardFront: User.Insurance?.insuranceCardFront,
          insuranceCardFrontChecked: User.Insurance?.insuranceCardFrontChecked,
          insuranceCardFrontMessageDenied: User.Insurance?.insuranceCardFrontMessageDenied,
          insuranceCardBack: User.Insurance?.insuranceCardBack,
          insuranceCardBackChecked: User.Insurance?.insuranceCardBackChecked,
          insuranceCardBackMessageDenied: User.Insurance?.insuranceCardBackMessageDenied,
          insuranceCompany: User.Insurance?.insuranceCompany,
          relationToSubscriber: User.Insurance?.relationToSubscriber,
          SSN: User.Insurance?.SSN,
          driversLicense: User.Insurance?.driversLicense,
          eligibilityStatus: User.Insurance?.eligibilityStatus,
          eligibilityErrorMessage: User.Insurance?.eligibilityErrorMessage,
          manualEligibilityCheckUserId: User.Insurance?.manualEligibilityCheckUserId,
          rejectReason: User.Insurance?.rejectReason,
        }
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [patients, testSessionData?.testSession?.Tests, userTestMap]
  )

  const getInsuranceStatus = (
    insuranceCompany: string,
    isApproved: boolean,
    govId: string,
    govIdChecked: boolean,
    insuranceCardFront: string,
    insuranceCardFrontChecked: boolean,
    insuranceCardBack: string,
    insuranceCardBackChecked: boolean,
    eligibilityStatus: string,
    eligibilityErrorMessage: string,
    hasManualEligibilityChecked: boolean,
    rejectReason: string
  ) => {
    if (eligibilityStatus === 'Inactive') {
      return (
        <Label indicator="red">Not Insured {!!rejectReason ? `(${rejectReason})` : null}</Label>
      )
    }

    if (!isNull(isApproved) && !isApproved) {
      const hasManualCheckedNotInsured =
        govIdChecked && insuranceCardFrontChecked && insuranceCardBackChecked
      if (
        eligibilityStatus === 'Active' &&
        !hasManualEligibilityChecked &&
        !hasManualCheckedNotInsured
      )
        return <Label indicator="green">Insured (Pending Document Reupload)</Label>
      return (
        <Label indicator="red">
          Not Insured
          {govIdChecked && insuranceCardFrontChecked && insuranceCardBackChecked
            ? ' (Not Insured by Admin)'
            : `${rejectReason ? ` (${rejectReason})` : ''}`}
        </Label>
      )
    }

    if (insuranceCompany && !activeInsuranceCompanies.includes(insuranceCompany))
      return <Label indicator="red">Not Insured (Not approved insurance company)</Label>

    if (isApproved)
      return (
        <Label indicator="green">
          Insured{' '}
          {(eligibilityErrorMessage || isNull(eligibilityStatus)) &&
            eligibilityStatus !== 'Active' &&
            `(Pending Insurance Verification)`}
        </Label>
      )

    if (
      isNull(isApproved) &&
      !govId &&
      !insuranceCardFront &&
      !insuranceCardBack &&
      !eligibilityStatus &&
      !eligibilityErrorMessage
    )
      return <Label indicator="grayDark">Not filled</Label>
    return <Label indicator="yellow">Under Review</Label>
  }

  const checkInsurance = React.useCallback(
    (data: TestSessionMember_testSessionMember) => {
      if (testSessionData?.testSession?.paymentStatus === TestSessionPaymentStatus.Insurance) {
        if (
          !data.User?.Insurance?.govId &&
          !data.User?.Insurance?.insuranceCardFront &&
          !data.User?.Insurance?.insuranceCardBack
        ) {
          setPatientModal({
            firstName: data.User?.firstName ?? '',
            lastName: data.User?.lastName ?? '',
            userTestSessionId: testSessionId ?? '',
            id: data.User?.id,
          })
          setFillModal(true)
          setInsuranceUserId(Number(data.User?.id))
        } else if (
          data.User?.Insurance?.govId &&
          !data.User?.Insurance?.insuranceCardFront &&
          !data.User?.Insurance?.insuranceCardBack
        ) {
          if (data.User?.Insurance?.govIdChecked && (isEditMode || data.Test?.testedAt)) {
            setOpenTestForUserId(Number(data.User?.id))
            setUserData(data)
          } else {
            setInsuranceUserId(Number(data.User.id))
            setConfirmInsuranceModal(true)
          }
        } else if (
          data.User?.Insurance?.govId &&
          data.User?.Insurance?.insuranceCardBack &&
          data.User?.Insurance?.insuranceCardFront
        ) {
          if (
            data.User?.Insurance?.govIdChecked &&
            data.User?.Insurance?.insuranceCardFrontChecked &&
            data.User?.Insurance?.insuranceCardBackChecked
          ) {
            if (isEditMode || data.Test?.testedAt) {
              setOpenTestForUserId(Number(data.User.id))
              setUserData(data)
            }
          } else {
            setInsuranceUserId(Number(data.User.id))
            setConfirmInsuranceModal(true)
          }
        }
      } else if (isEditMode || data.Test?.testedAt) {
        setOpenTestForUserId(Number(data.User?.id))
        setUserData(data)
      }
    },
    [isEditMode, testSessionData?.testSession?.paymentStatus, testSessionId]
  )

  const onCheckSelected = (checked: boolean, id: string) => {
    setSelected((prev) => (checked ? [...prev, id] : prev.filter((pv) => pv !== id)))
  }

  const patientNoTests = React.useMemo(() => patientsData.filter(({ testId }) => !testId), [
    patientsData,
  ])

  const onCheckSelectedAll = (checked: boolean, ids: string[]) => {
    if (!checked) {
      setAllSelected(false)
    }
    setSelected((prev) =>
      checked
        ? [...prev, ...ids].reduce((acc, curr) => {
            if (acc.some((a) => a === curr)) return acc
            return [...acc, curr]
          }, [] as string[])
        : []
    )
  }

  const isRejected = React.useMemo(() => !!testSessionData?.testSession?.rejectedAt, [
    testSessionData,
  ])

  const onDeleteMembers = async () => {
    const payload = {
      ids: allSelected ? [] : selected,
      testSessionId,
      removeAll: allSelected,
    }

    await deleteMember({
      variables: payload,
    })
  }

  return (
    <DashboardPage
      Header={
        <>
          {testSessionData?.testSession && (
            <Loading isLoading={isTestSessionLoading || fetching} isEmpty={false}>
              <Header
                session={testSessionData.testSession}
                isEditMode={isEditMode}
                setIsEditMode={setIsEditMode}
                onChange={handleDetailsChange}
                isEditLoading={isEditTestSessionLoading}
                refetch={refetchSessionQuery}
                totalParticipants={MemberCount}
                totalTested={testedUserIds.length}
                isSuperAdminOrResultsOrLiaison={canSeeResults}
              />
            </Loading>
          )}
        </>
      }
    >
      <Layout>
        <Dialog
          isOpen={addInfoModal}
          variant={isMobile ? 'fullscreen' : 'center'}
          maxWidth={pxToRem(700)}
          onDismiss={() => setAddInfoModal(false)}
        >
          <Box display="flex" flexDirection="column" padding="2rem">
            <FormInsurance
              userId={String(insuranceUserId)}
              onClose={() => setAddInfoModal(false)}
              isDialog
              refetchInsurance={refetchSessionQuery}
            />
          </Box>
        </Dialog>
        <Dialog
          maxWidth={pxToRem(1000)}
          isOpen={confirmInsuranceModal}
          onDismiss={() => {
            setConfirmInsuranceModal(false)
          }}
        >
          <ConfirmInsurance
            setConfirmInsuranceModal={setConfirmInsuranceModal}
            insuranceUserId={insuranceUserId}
            runEligibilibyEnable
          />
        </Dialog>
        <Dialog
          maxWidth={pxToRem(500)}
          isOpen={fillModal}
          onDismiss={() => {
            setFillModal(false)
          }}
        >
          <Box flexDirection="column" width="100%">
            <Card.Title
              onDismiss={() => {
                setFillModal(false)
              }}
            >
              No Insurance information
            </Card.Title>
            <Card.Content>
              <Text>
                This user have not filled out his insurance information, how you want to proceed?
              </Text>
            </Card.Content>
            <Box flexDirection="column" p="16px">
              <Button onClick={handleSendEmailInsurance} width="100%" mb="16px">
                Send Email Prompt
              </Button>
              {hasPermission([UserPermissions.FILL_OUT_INSURANCE_INFO]) && (
                <Button
                  mb="16px"
                  width="100%"
                  onClick={() => {
                    setFillModal(false)
                    setAddInfoModal(true)
                  }}
                >
                  Fill out Insurance Info
                </Button>
              )}
              {(hasPermission([UserPermissions.REMOVE_PERSON_TEST_SESSION]) ||
                (hasPermission([UserPermissions.REMOVE_PATIENT_TEST_SESSION]) &&
                  staffs.some((el) => el.User.id === userAuth.User.id))) && (
                <Button
                  onClick={(e) => {
                    e.stopPropagation()
                    openDialogDeleteMember({
                      member: 'participant',
                      name: `${patientModal.firstName || ''} ${patientModal.lastName || ''}`,
                      id: patientModal.userTestSessionId,
                    })
                  }}
                  width="100%"
                >
                  Remove from session
                </Button>
              )}
            </Box>
          </Box>
        </Dialog>
        <Box
          display="grid"
          gridGap="1rem"
          gridTemplateColumns={isTablet ? 'repeat(2, 1fr)' : undefined}
        >
          <>
            {testSessionData?.testSession && (
              <Loading isLoading={isTestSessionLoading || fetching} isEmpty={false}>
                <SessionInfo
                  session={testSessionData.testSession}
                  onChange={handleDetailsChange}
                  isEditMode={isEditMode}
                  isEditLoading={isEditTestSessionLoading}
                  testSessionName={testSessionData.testSession.name || 'Unnamed'}
                  isEditing={isEditTestSessionLoading}
                  refetchTestSessionData={refetchSessionQuery}
                  isSuperAdminOrResultsOrLiaison={canSeeResults}
                />
              </Loading>
            )}
          </>

          <Box>
            <Card>
              <Datalist
                minWidth="0"
                isLoading={isTestSessionLoading || fetching}
                data={fuzzyStaff.map(({ id, User }) => ({ ...User, userTestSessionId: id }))}
                shouldRenderTableHeader={isDesktop}
                skeletonProps={{
                  columns: 2,
                }}
                header={
                  <Datalist.Title
                    rightContent={
                      <>
                        <TextInput
                          placeholder="Search by name"
                          rightIcon={<Icon.Search color={COLOR.grayDark} size="L" />}
                          hasBottomContent={false}
                          hasTopContent={false}
                          value={staffSearchQuery}
                          onChange={(event) => setStaffSearchQuery(event.target.value)}
                        />
                        {isEditMode && hasPermission([UserPermissions.ADD_STAFF_TEST_SESSION]) && (
                          <AddNewTestSessionMembers
                            buttonLabel="Add new staff"
                            testSessionId={testSessionId}
                            addMemberType="staff"
                            onAdd={refetchSessionQuery}
                          />
                        )}
                      </>
                    }
                  >
                    Staff
                  </Datalist.Title>
                }
                renderTableHeader={() => (
                  <Datalist.Row gridTemplateColumns="repeat(2, 1fr)">
                    <Datalist.HeaderCell label="Name" />
                  </Datalist.Row>
                )}
                renderTableRow={({ id, firstName, lastName, userTestSessionId }) => (
                  <Datalist.Row gridTemplateColumns="1fr auto">
                    <Datalist.Cell label="Name">
                      <Button appearance="link" to={`/users/${id}`}>
                        {firstName && lastName && `${firstName} ${lastName}`}
                      </Button>
                    </Datalist.Cell>
                    <Datalist.Cell>
                      {isEditMode &&
                        !staffsInTested.some((staffInTest) => staffInTest?.User.id === id) &&
                        hasPermission([
                          UserPermissions.REMOVE_STAFF_TEST_SESSION,
                          UserPermissions.REMOVE_PERSON_TEST_SESSION,
                        ]) && (
                          <Button
                            isFetching={isLoadingRemoveTestSessionMember && rowId === id}
                            disabled={isLoadingRemoveTestSessionMember}
                            size="small"
                            onClick={() => {
                              openDialogDeleteMember({
                                member: 'staff',
                                name: `${firstName || ''} ${lastName || ''}`,
                                id: userTestSessionId,
                              })
                            }}
                          >
                            Remove
                          </Button>
                        )}
                    </Datalist.Cell>
                  </Datalist.Row>
                )}
              />
            </Card>
          </Box>
        </Box>
        <Box>
          <Card flex="3" width="100%" minWidth="280px" p="0">
            <Datalist
              isLoading={isTestSessionLoading || fetching || sessionMembersLoading}
              data={patientsData}
              skeletonProps={{
                columns: 7,
              }}
              header={
                <Datalist.Title
                  rightContent={
                    <>
                      <TextInput
                        placeholder="Search by name"
                        rightIcon={<Icon.Search color={COLOR.grayDark} size="L" />}
                        hasTopContent={false}
                        hasBottomContent={false}
                        value={emailOrNameLike}
                        onChange={(event) => {
                          setEmailOrNameLike(event.target.value)
                        }}
                      />
                      {isEditMode &&
                        hasPermission([UserPermissions.ADD_PERSON_OR_GROUP_TEST_SESSION]) && (
                          <>
                            <AddPatientScanCode
                              companyId={testSessionData?.testSession?.Company?.id}
                              testSessionId={testSessionId}
                              onOpenUser={(data) => {
                                void checkInsurance(data)
                              }}
                              refetch={refetchSessionQuery}
                              groupId={testSessionData?.testSession?.Group?.id}
                              disabledButton={isRejected}
                            />
                            <AddNewTestSessionMembers
                              companyId={testSessionData?.testSession?.Company?.id}
                              buttonLabel="Add patient"
                              testSessionId={testSessionId}
                              addMemberType="participant"
                              onAdd={refetchSessionQuery}
                              groupId={testSessionData?.testSession?.Group?.id}
                              multipleGroups={!!testSessionData?.testSession?.Groups?.length}
                              disabledButton={isRejected}
                            />
                          </>
                        )}
                    </>
                  }
                >
                  <Box display="flex" alignItems="center">
                    <Box display="flex" alignItems="center" mr="3rem">
                      <span>Patients</span>{' '}
                      {!!MemberCount && (
                        <Text fontSize={fontSizes.s}>
                          ({testedUserIds.length} / {MemberCount})
                        </Text>
                      )}
                    </Box>
                    {!!selected.length && hasPermission([UserPermissions.SEE_BULK_DELETE]) && (
                      <Button
                        appearance="ghost"
                        size="small"
                        mr="1rem"
                        onClick={() => setConfirmDeletePerson(true)}
                        isFetching={isLoading}
                      >
                        Delete Selected Patients
                      </Button>
                    )}

                    {!!selected.length &&
                      !allSelected &&
                      MemberCount !== selected.length &&
                      hasPermission([UserPermissions.SEE_BULK_DELETE]) && (
                        <Button
                          appearance="ghost"
                          size="small"
                          onClick={() => setAllSelected(true)}
                        >
                          Select all {MemberCount - testedUserIds.length} patients
                        </Button>
                      )}
                  </Box>
                </Datalist.Title>
              }
              sorter={{
                direction: searchState.data.direction,
                sortBy: searchState.data.orderBy,
              }}
              onSortChange={(orderBy, direction) => {
                searchState.setSearchState({ direction, orderBy }, false)
              }}
              shouldRenderTableHeader={isDesktop}
              showActionIndicators
              renderTableHeader={() => (
                <Datalist.Row
                  gridTemplateColumns={
                    testSessionData?.testSession?.paymentStatus === 'Insurance'
                      ? `0.2fr repeat(${canSeeResults ? 8 : 7}, 1fr) 2rem`
                      : `0.2fr repeat(${canSeeResults ? 6 : 5}, 1fr) 2rem`
                  }
                >
                  <Datalist.HeaderCell label="">
                    {!!patientNoTests.length && hasPermission([UserPermissions.SEE_BULK_DELETE]) && (
                      <Checkbox
                        label=""
                        checked={selected.length >= patientNoTests.length || allSelected}
                        onChange={(e) =>
                          onCheckSelectedAll(
                            e.target.checked,
                            patientNoTests.map((patient) => patient.userTestSessionId)
                          )
                        }
                      />
                    )}
                  </Datalist.HeaderCell>
                  <Datalist.HeaderCell sortKey={SortByType.BIRTH_DATE} label="Birth date" />
                  <Datalist.HeaderCell sortKey={SortByType.FIRST_NAME} label="First name" />
                  <Datalist.HeaderCell sortKey={SortByType.LAST_NAME} label="Last name" />
                  {testSessionData?.testSession?.paymentStatus === 'Insurance' ? (
                    <Datalist.HeaderCell label="Insurance" />
                  ) : null}
                  {testSessionData?.testSession?.paymentStatus === 'Insurance' ? (
                    <Datalist.HeaderCell label="Insurance Company" />
                  ) : null}
                  <Datalist.HeaderCell sortKey={SortByType.EMAIL} label="Email" />
                  <Datalist.HeaderCell sortKey={SortByType.TESTED_AT} label="Tested" />
                  {canSeeResults ? (
                    <Datalist.HeaderCell sortKey={SortByType.RESULT} label="Result" />
                  ) : null}
                </Datalist.Row>
              )}
              renderTableRow={({
                id,
                birthDate,
                firstName,
                lastName,
                govId,
                govIdChecked,
                insuranceCardFront,
                insuranceCardFrontChecked,
                insuranceCardBack,
                insuranceCardBackChecked,
                insuranceCompany,
                email,
                barcode,
                result,
                secondResult,
                testId,
                testedAt,
                isCompliant,
                userTestSessionId,
                isSubperson,
                eligibilityStatus,
                eligibilityErrorMessage,
                Insurance,
                isApproved,
                manualEligibilityCheckUserId,
                rejectReason,
              }) => (
                <Datalist.Row
                  backgroundColor={!isCompliant ? mix(0.9, COLOR.white, COLOR.danger) : undefined}
                  gridTemplateColumns={{
                    _: 'repeat(2, 1fr)',
                    TABLET: 'repeat(3, 1fr)',
                    DESKTOP:
                      testSessionData?.testSession?.paymentStatus === 'Insurance'
                        ? `0.2fr repeat(${canSeeResults ? 8 : 7}, 1fr) 2rem`
                        : `0.2fr repeat(${canSeeResults ? 6 : 5}, 1fr) 2rem`,
                  }}
                  onClick={() => {
                    if (isRejected) return
                    if (
                      testSessionData?.testSession?.paymentStatus ===
                        TestSessionPaymentStatus.Insurance &&
                      !isFinished
                    ) {
                      if (!Insurance) {
                        setPatientModal({
                          firstName: firstName ?? '',
                          lastName: lastName ?? '',
                          userTestSessionId: userTestSessionId ?? '',
                          id,
                        })
                        setFillModal(true)
                        setInsuranceUserId(Number(id))
                      } else if (
                        insuranceCompany &&
                        !activeInsuranceCompanies.includes(insuranceCompany)
                      ) {
                        setFillModal(true)
                        setInsuranceUserId(Number(id))
                      } else if (
                        isApproved &&
                        eligibilityStatus !== 'Inactive' &&
                        ((isEditMode &&
                          testSessionData?.testSession?.type == TestType.Covid19 &&
                          isSuperAdmin) ||
                          testedAt)
                      ) {
                        setOpenTestForUserId(Number(id))
                      } else if (Insurance && isNull(isApproved)) {
                        setInsuranceUserId(Number(id))
                        setConfirmInsuranceModal(true)
                      } else if (isApproved && eligibilityStatus !== 'Inactive' && !isFinished) {
                        setOpenTestForUserId(Number(id))
                      } else {
                        setFillModal(true)
                        setInsuranceUserId(Number(id))
                      }
                    } else if (
                      (isEditMode &&
                        testSessionData?.testSession?.type == TestType.Covid19 &&
                        isSuperAdmin) ||
                      testedAt ||
                      !isFinished
                    ) {
                      setOpenTestForUserId(Number(id))
                    }
                  }}
                >
                  <Datalist.Cell label="" onClick={(e) => e.stopPropagation()}>
                    {!testId && hasPermission([UserPermissions.SEE_BULK_DELETE]) && (
                      <Checkbox
                        label=""
                        onClick={(e) => e.stopPropagation()}
                        onChange={(e) => {
                          e.stopPropagation()
                          onCheckSelected(e.target.checked, userTestSessionId)
                        }}
                        checked={!!selected.find((s) => s === userTestSessionId) || allSelected}
                      />
                    )}
                  </Datalist.Cell>
                  <Datalist.Cell label="Birth date">
                    {birthDate && getFormattedDateAndTime(birthDate)}
                  </Datalist.Cell>
                  <Datalist.Cell label="First name">{firstName}</Datalist.Cell>
                  <Datalist.Cell label="Last name">{lastName}</Datalist.Cell>
                  {testSessionData?.testSession?.paymentStatus === 'Insurance' ? (
                    <Datalist.Cell label="Status">
                      {getInsuranceStatus(
                        insuranceCompany as string,
                        isApproved as boolean,
                        govId as string,
                        govIdChecked as boolean,
                        insuranceCardFront as string,
                        insuranceCardFrontChecked as boolean,
                        insuranceCardBack as string,
                        insuranceCardBackChecked as boolean,
                        eligibilityStatus as string,
                        eligibilityErrorMessage as string,
                        !!manualEligibilityCheckUserId,
                        rejectReason as string
                      )}
                    </Datalist.Cell>
                  ) : null}
                  {testSessionData?.testSession?.paymentStatus === 'Insurance' ? (
                    <Datalist.Cell label="Insurance Company">{insuranceCompany}</Datalist.Cell>
                  ) : null}
                  <Datalist.Cell label="Email" useEllipsis>
                    <Box display="flex" flexDirection="column">
                      {!isCompliant && <Label indicator="red">Not verified</Label>}
                      {isSubperson && (
                        <>
                          <Label indicator="yellow">Subperson</Label>
                        </>
                      )}
                      <span>{email}</span>
                    </Box>
                  </Datalist.Cell>
                  <Datalist.Cell label="Tested">
                    {testedAt ? (
                      <Label indicator="green">{barcode || ''}</Label>
                    ) : (
                      <Label indicator="red">Not tested yet</Label>
                    )}
                  </Datalist.Cell>
                  {canSeeResults ? (
                    <Datalist.Cell label="Result">
                      {!testedAt && <Label indicator="yellow">Not tested yet</Label>}
                      {testedAt && !result && <Label indicator="blue">Pending result</Label>}
                      {testedAt && result && (
                        <Box>
                          <Label indicator={getResultLabelColor(result)}>
                            {isInfluenza(testSessionData?.testSession?.type) && `A: `}
                            {result}
                          </Label>
                          {isInfluenza(testSessionData?.testSession?.type) && secondResult && (
                            <Label indicator={getResultLabelColor(secondResult)}>
                              {`B: ${secondResult}`}
                            </Label>
                          )}
                          {testId && (
                            <DownloadTestResultPdfButton
                              testId={testId}
                              appearance="ghost"
                              size="small"
                            />
                          )}
                        </Box>
                      )}
                    </Datalist.Cell>
                  ) : null}
                  {(hasPermission([UserPermissions.REMOVE_PERSON_TEST_SESSION]) ||
                    (hasPermission([UserPermissions.REMOVE_PATIENT_TEST_SESSION]) &&
                      staffs.some((el) => el.User.id === userAuth.User.id))) &&
                  !testId ? (
                    <Datalist.Cell>
                      <Button
                        disabled={isLoadingRemoveTestSessionMember}
                        onClick={(e) => {
                          e.stopPropagation()
                          openDialogDeleteMember({
                            member: 'participant',
                            name: `${firstName || ''} ${lastName || ''}`,
                            id: userTestSessionId,
                          })
                        }}
                        appearance="ghost"
                        colorVariant="danger"
                        size="small"
                      >
                        {rowId === id && isLoadingRemoveTestSessionMember ? (
                          <Spinner color={COLOR.danger} size="small" />
                        ) : (
                          <Icon.Trash />
                        )}
                      </Button>
                    </Datalist.Cell>
                  ) : null}
                </Datalist.Row>
              )}
            />
            {!!testSessionMembers.length && Math.ceil(MemberCount / PERPAGE) > 1 && (
              <Box
                display="flex"
                width="100%"
                justifyContent="center"
                alignItems="center"
                marginTop="20px"
              >
                <Pagination
                  firstPage={1}
                  lastPage={Math.ceil(MemberCount / PERPAGE)}
                  currentPage={Number(searchState.data.page ?? 1)}
                  onPageChange={(page) => {
                    searchState.setSearchState({ page }, false)
                  }}
                />
              </Box>
            )}
          </Card>
        </Box>
      </Layout>
      <UserTest
        type={testSessionData?.testSession?.type}
        isEditMode={
          isEditMode &&
          (!isFinished || (testSessionData?.testSession?.type == TestType.Covid19 && isSuperAdmin))
        }
        Members={testSessionMembers}
        staffs={staffs}
        Tests={testSessionData?.testSession?.Tests || []}
        PretestSessionSurveys={testSessionData?.testSession?.PretestSessionSurveys || []}
        userId={openTestForUserId}
        userData={userData}
        isAddTestLoading={isAddTestLoading}
        isRemoveTestLoading={isRemoveTestLoading}
        isTestSessionLoading={isTestSessionLoading}
        onTestRemove={handleTestRemove}
        onTestSubmit={handleTestSubmit}
        onDismiss={() => {
          setOpenTestForUserId(null)
          setUserData(null)
        }}
        isInsuredTestSession={
          testSessionData?.testSession?.paymentStatus === TestSessionPaymentStatus.Insurance
        }
        isFinishedTestSession={!!testSessionData?.testSession?.finishedAt}
        defaultSessionValues={testSessionData?.testSession?.TestValues}
      />
      <ConfirmationDialog
        title="Deleting Members from Session"
        isOpen={confirmDeletePerson}
        onDismiss={() => setConfirmDeletePerson(false)}
        actions={
          <>
            <Button onClick={() => setConfirmDeletePerson(false)} colorVariant="secondary">
              Cancel
            </Button>
            <Button onClick={onDeleteMembers} isFetching={isLoading}>
              Delete All
            </Button>
          </>
        }
      >
        <Box display="flex" flexDirection="column">
          <Text>Are you sure you want to remove these session members?</Text>
          <Text>This action will also remove already added test for the participants!</Text>
        </Box>
      </ConfirmationDialog>

      <ConfirmationDialog
        title="Deleting Member from Session"
        isOpen={confirmDeleteMember}
        onDismiss={() => setConfirmDeleteMember(false)}
        actions={
          <>
            <Button onClick={() => setConfirmDeleteMember(false)} colorVariant="secondary">
              Cancel
            </Button>
            <Button
              onClick={handleRemoveTestSessionMember}
              isFetching={isLoadingRemoveTestSessionMember}
              disabled={isLoadingRemoveTestSessionMember}
            >
              Delete All
            </Button>
          </>
        }
      >
        <Box display="flex" flexDirection="column">
          <Text>
            Are you sure you want to remove {memberSelected?.name || 'member'} from{' '}
            {memberSelected?.member === 'staff' ? MemberType.staff : MemberType.participant}
          </Text>
          {memberSelected?.member === 'participant' && (
            <Text>This action will also remove already added test for this participant!</Text>
          )}
        </Box>
      </ConfirmationDialog>
    </DashboardPage>
  )
}
