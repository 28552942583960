import { gql } from '@apollo/client'

export const TESTS_RESULTS_ANALYTICS = gql`
  query TestsResultsAnalyticsLazy(
    $filterData: TestsResultsAnalyticsInput
    $pagination: PaginationInput!
  ) {
    testsResultsAnalytics(filterData: $filterData, pagination: $pagination) {
      tests {
        id
        barcode
        testedAt
        result
        secondResult
        resultProvisionedAt
        specimenType
        User {
          id
          firstName
          lastName
          birthDate
          email
        }
        TestSession {
          id
          date
          name
          lab
          type
          paymentStatus
          finishedAt
          invoiceId
          invoiceDocNumber
          Company {
            id
            name
          }
          zip
        }
        eventId
        eventpaymentMethod
        Insurance {
          id
          insuranceCompany
        }
        Group {
          name
        }
        Company {
          name
        }
      }
      pagination {
        from
        length
        total
      }
    }
  }
`
