import { UserTypeForFilter } from '@modmd/data'

export const ROUTES = {
  DASHBOARD: 'dashboard',
  USERS: 'users',
  USERS_ALL: 'users',
  USERS_PATIENT: `users/${UserTypeForFilter.Patient.toLowerCase()}`,
  USERS_STAFF: `users/${UserTypeForFilter.Staff.toLowerCase()}`,
  USERS_COMPANY_OWNER: `users/${UserTypeForFilter.CompanyOwner.toLowerCase()}`,
  USERS_SUBPERSONS: `users/subperson`,
  COMPANIES: 'companies',
  RESET_PASSWORD: 'reset-password',
  SESSIONS: 'sessions',
  SIGN_UP: 'sign-up',
  USER_PROFILE: 'user-profile',
  TESTS: 'tests',
  REQUEST_PASSWORD_RESET: 'request-password-reset',
  REQUEST_TEST_SESSION: 'request-test-session',
  DRIVE_THROUGH_LOCATIONS: 'drive-through-locations',
  VACCINE_RECORD_VERIFICATION: 'vaccine-record-verification',
  INSURANCE_REVIEW_VERIFICATION: 'insurance-review-verification',
  ANALYITCS: 'analytics',
  ANALYTICS_TESTS_RESULTS: 'analytics-tests-results',
  ANALYTICS_INSURANCE_TESTS: 'analytics-insurance-tests',
  ANALYTICS_APP_USAGE: 'analytics-app-usage',
  ANALYTICS_MARKETING: 'analytics-marketing',
  EVENTS: 'events',
  EVENT_ATTENDEES: 'event-attendees',
  REPORTS_LOGS: 'reports-logs',
  FINANCES: 'finances',
  INSURANCE_COMPANIES: 'insurance-companies',
  INSURANCE_ERROR_LOGS: 'insurance-error-logs',
  FINANCES_PRICES: 'finances/prices',
  USER_LOGS: 'user-logs',
  FINANCES_LOCATIONS: 'finances/locations',
}
